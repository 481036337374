define('golf-digest-panelists/helpers/is-eq', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEq = isEq;
  function isEq(params /*, hash*/) {
    var a = params[0];
    var b = params[1];

    return _npmUnderscore.default.isEqual(a, b);
  }

  exports.default = Ember.Helper.helper(isEq);
});