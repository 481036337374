define('golf-digest-panelists/helpers/is-nonzero', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isNonzero = isNonzero;
  function isNonzero(params /*, hash*/) {
    var value = params[0];

    return value !== 0;
  }

  exports.default = Ember.Helper.helper(isNonzero);
});