define('golf-digest-panelists/surveys/survey/courses/controller', ['exports', 'golf-digest-panelists/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: ['page', 'perPage'],
    totalPages: Ember.computed.alias('content.totalPages'),
    page: 1,
    perPage: 25,
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    surveyCoursesExportURL: _environment.default.jsonAdapterHost + '/api/v2/export-courses-of-survey/',
    actions: {
      pageClicked: function pageClicked(page) {
        this.setProperties({ page: page });
      },
      done: function done() {
        this.transitionToRoute('surveys.survey', this.get('session.data.surveyId'));
      },
      deleteCourse: function deleteCourse(course) {
        var controller = this;
        var store = this.get('store');
        var model = this.get('model');
        var flash = this.get('flashMessages');
        var surveyId = this.get('session.data.surveyId');
        var courseId = course.id;
        return store.queryRecord('candidate', { surveyId: surveyId, courseId: courseId }).then(function (candidate) {
          candidate.destroyRecord().then(function () {
            candidate.unloadRecord();
            model.get('content').removeObject(course);
            course.unloadRecord();
            flash.success('Course ' + course.get('name') + ' removed from survey!'); // => DELETE to /candidates/ids
            controller.get('target.router').refresh();
          });
        });
      }
    }
  });
});