define('golf-digest-panelists/courses/course/index/controller', ['exports', 'npm:bluebird', 'npm:underscore'], function (exports, _npmBluebird, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    busy: Ember.inject.service(),

    store: Ember.inject.service(),
    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),
    userId: Ember.computed.alias('session.data.currentUser.id'),

    organizationalAddressKinds: Ember.A(),
    organizationalTelKinds: Ember.A(),

    courseName: null,
    websiteUrl: null,
    notesToPanelists: null,
    ngfCourseId: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      return _npmBluebird.default.all([

      // Function Get Store Organization Address Kind for params
      this.get('store').findAll('organizational-address-kind').then(function (kinds) {
        return _this.set('organizationalAddressKinds', kinds);
      }),

      // Function Get Store Organization Tel Kind for params
      this.get('store').findAll('organizational-tel-kind').then(function (kinds) {
        return _this.set('organizationalTelKinds', kinds);
      })]);
    },


    actions: {
      toggleWebsiteUrlEdit: function toggleWebsiteUrlEdit() {
        setTimeout(function () {
          Ember.$('#website-url .ember-inline-edit .ember-inline-edit-toggle-btn').focus().click();
        }, 50);
      },
      beginChangeWebsiteUrl: function beginChangeWebsiteUrl() {
        this.set('websiteUrl', this.get('model.websiteUrl'));
      },
      changeWebsiteUrl: function changeWebsiteUrl() {
        this.set('websiteUrl', this.get('model.websiteUrl'));

        return this.get('model').save();
      },
      cancelChangeWebsiteUrl: function cancelChangeWebsiteUrl() {
        this.set('model.websiteUrl', this.get('websiteUrl'));
      },
      toggleNotesToPanelistsEdit: function toggleNotesToPanelistsEdit() {
        setTimeout(function () {
          Ember.$('#notes-to-panelists .ember-inline-edit .ember-inline-edit-toggle-btn').focus().click();
        }, 50);
      },
      beginChangeNotesToPanelists: function beginChangeNotesToPanelists() {
        this.set('notesToPanelists', this.get('model.notesToPanelists'));
      },
      changeNotesToPanelists: function changeNotesToPanelists() {
        this.set('notesToPanelists', this.get('model.notesToPanelists'));

        return this.get('model').save();
      },
      cancelChangeNotesToPanelists: function cancelChangeNotesToPanelists() {
        this.set('model.notesToPanelists', this.get('notesToPanelists'));
      },
      addCandidacy: function addCandidacy(survey) {
        var model = this.get('model');
        var store = this.get('store');

        var candidacy = store.createRecord('candidate', {
          survey: survey,
          course: model,
          isFullyAssigned: false
        });

        return candidacy.save().then(function () {
          return model.get('candidates').pushObject(candidacy);
        }).catch(function (err) {
          Ember.Logger.error('Failed to add candidacy: ' + (err.message + '\n' + (err && err.stack ? err.stack : '')));

          alert('We ran into a problem when adding a survey to this course: ' + ('' + err.message));
        });
      },


      /**
       * Function Remove Candidacy By Param
       * @param {*} candidacy 
       */
      removeCandidacy: function removeCandidacy(candidacy) {
        var model = this.get('model');

        /**
         * Function Destroy Record By Param
         */
        return candidacy.destroyRecord().then(function () {
          return model.get('candidates').removeObject(candidacy);
        }).catch(function (err) {
          Ember.Logger.error('Failed to remove candidacy: ' + (err.message + '\n' + (err && err.stack ? err.stack : '')));

          alert('We ran into a problem when removing a survey from this course: ' + ('' + err.message));
        });
      },


      /**
       * Function toggle full Assigned By Params
       * @param {*} candidacy 
       * @param {*} value 
       */
      toggleFullyAssigned: function toggleFullyAssigned(candidacy, value) {
        // If the value was changed downstream don't touch it. Otherwise, do the
        // toggle.
        if (value !== true && value !== false) {
          candidacy.toggleProperty('isFullyAssigned');
        }

        // Save Candidacy
        return candidacy.save();
      },
      toggleCourseNameEdit: function toggleCourseNameEdit() {
        setTimeout(function () {
          Ember.$('#course-name .ember-inline-edit span').focus().click();
        }, 50);
      },
      toggleActivelySeekingPanelists: function toggleActivelySeekingPanelists() {
        var model = this.get('model');

        model.toggleProperty('isActivelySeekingPanelists');

        return model.save();
      },
      beginChangeCourseName: function beginChangeCourseName() {
        this.set('courseName', this.get('model.name'));
      },
      changeCourseName: function changeCourseName() {
        this.set('courseName', this.get('model.name'));

        return this.get('model').save();
      },
      cancelChangeCourseName: function cancelChangeCourseName() {
        this.set('model.name', this.get('courseName'));
      },
      getEvaluations: function getEvaluations(course) {
        var courseId = course.get('id');
        this.transitionToRoute('courses.course.evaluations', courseId, { queryParams: { active: false } });
      },


      /**
       * Function Delete Course by param
       * @param {*} course 
       */
      deleteCourse: function deleteCourse(course) {
        var flash = this.get('flashMessages');
        var that = this;

        var busy = this.get('busy');
        busy.show({ message: 'Deleting Course...' });

        // Function Destroy Course Record 
        return course.destroyRecord().then(function () {
          return that.transitionToRoute('courses.index').then(function () {
            busy.hide();
            return flash.success('Course Deleted!!');
          });
        });
      },

      /**
       * Function get Evaluation by param
       * @param {*} course 
       */
      getEvaluation: function getEvaluation(course) {
        var _this2 = this;

        var courseId = course.get('id');
        var userId = this.get('userId');

        // Function get Store Evalution and Query Record by Course ID and User ID
        return this.get('store').queryRecord('evaluation', { CourseId: courseId, UserId: userId }).then(function (evaluation) {
          if (evaluation && !_npmUnderscore.default.isUndefined(evaluation)) {
            return _this2.transitionToRoute('evaluations.evaluation', evaluation);
          }

          _this2.get('session').set('course', course);

          // Transition to route evaluation new
          return _this2.transitionToRoute('evaluations.new');
        }).catch(function () {
          _this2.get('session').set('course', course);

          // Transition to route evaluation new
          return _this2.transitionToRoute('evaluations.new');
        });
      },
      createTel: function createTel() {
        var _this3 = this;

        var controller = this;
        var model = this.get('model');
        var index = -1;

        model.get('courseTels').then(function (tels) {
          if (tels) {
            tels.forEach(function (telephone) {
              var cIndex = parseInt(telephone.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          /**
           * Function get Store Organization Tel Kind by ID
           */
          return _this3.get('store').find('organizational-tel-kind', 1).then(function (organizationalTelKind) {
            var newTel = controller.get('store').createRecord('course-tel', {
              course: model,
              index: index + 1,
              organizationalTelKind: organizationalTelKind
            });
            newTel.set('editing', true);
            model.get('courseTels').pushObject(newTel);
          });
        });
      },

      /**
       * Function Edit Tel by param
       * @param {*} tel 
       */
      toggleEditTel: function toggleEditTel(tel) {
        if (tel.get('isNew')) {
          tel.destroy();
        } else {
          if (tel.get('editing') === true) {
            tel.set('editing', false);
            tel.rollbackAttributes();
          } else {
            tel.set('editing', true);
          }
        }
      },


      /**
       * Function Save Tel by param
       * @param {*} tel 
       */
      saveTel: function saveTel(tel) {
        return tel.save().then(function () {
          return tel.set('editing', false);
        }).then(function () {
          return tel.reload();
        });
      },


      /**
       * Function Delete Tel for param
       * @param {*} tel 
       */
      deleteTel: function deleteTel(tel) {
        var model = this.get('model');

        // Function Destroy Record for param
        return tel.destroyRecord().then(function () {
          return model.get('courseTels').removeObject(tel);
        });
      },


      /**
       * Function Change Tel Kind for param
       * @param {*} tel 
       * @param {*} kind 
       */
      changeTelKind: function changeTelKind(tel, kind) {
        tel.set('kind', kind);
      },


      /**
       * Function Create Address by param
       */
      createAddress: function createAddress() {
        var _this4 = this;

        var controller = this;
        var model = this.get('model');
        var index = -1;

        model.get('courseAddresses').then(function (addresses) {
          if (addresses) {
            addresses.forEach(function (address) {
              var cIndex = parseInt(address.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          /**
           * Function get Store Region by ID
           */
          return _this4.get('store').find('region', 1).then(function (region) {
            return _this4.get('store').find('organizational-address-kind', 1).then(function (organizationalAddressKind) {
              var newAddress = controller.get('store').createRecord('course-address', {
                course: model,
                index: index + 1,
                organizationalAddressKind: organizationalAddressKind,
                region: region
              });
              newAddress.set('editing', true);
              model.get('courseAddresses').pushObject(newAddress);
            });
          });
        });
      },


      /**
       * Function toggle Edit Address by param
       * @param {*} address 
       */
      toggleEditAddress: function toggleEditAddress(address) {
        if (address.get('isNew')) {
          address.destroy();
        } else {
          if (address.get('editing') === true) {
            address.set('editing', false);
            address.rollbackAttributes();
          } else {
            address.set('editing', true);
          }
        }
      },


      /**
       * Function Save Address by param
       * @param {*} address 
       */
      saveAddress: function saveAddress(address) {
        return address.save().then(function () {
          return address.set('editing', false);
        }).then(function () {
          return address.reload();
        });
      },


      /**
       * Function Delete Address by param
       * @param {*} address 
       */
      deleteAddress: function deleteAddress(address) {
        var model = this.get('model');

        // Function Destroy Address Record
        return address.destroyRecord().then(function () {
          return model.get('courseAddresses').removeObject(address);
        });
      },


      /**
       * Function Change Address Kind by params
       * @param {*} address 
       * @param {*} kind 
       */
      changeAddressKind: function changeAddressKind(address, kind) {
        address.set('kind', kind);
      },


      /**
       * Function Change Address and Region Kind by params
       * @param {*} address 
       * @param {*} region 
       */
      changeAddressRegion: function changeAddressRegion(address, region) {
        address.set('region', region);
      },
      createContact: function createContact() {
        var controller = this;
        var model = this.get('model');
        var index = -1;

        model.get('contacts').then(function (contacts) {
          if (contacts) {
            contacts.forEach(function (contacts) {
              var cIndex = parseInt(contacts.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          var newContact = controller.get('store').createRecord('contact', {
            course: model,
            index: index + 1
          });
          newContact.set('editing', true);
          model.get('contacts').pushObject(newContact);
        });
      },


      /**
       * Function toggle Edit Contact by params
       * @param {*} contact 
       */
      toggleEditContact: function toggleEditContact(contact) {
        if (contact.get('isNew')) {
          contact.destroy();
        } else {
          if (contact.get('editing') === true) {
            contact.set('editing', false);
            contact.rollbackAttributes();
          } else {
            contact.set('editing', true);
          }
        }
      },


      /**
       * Function Save Contact by params
       * @param {*} contact 
       */
      saveContact: function saveContact(contact) {
        var that = this;
        var store = this.get('store');
        var cck = contact.get('contactContactKinds');
        var surrogateCk = contact.get('surrogateContactKinds');

        // Save Contact 
        return contact.save().then(function () {
          return contact.reload();
        }).then(function () {
          return _npmBluebird.default.map(cck.toArray(), function (cckItem) {
            var cckContactKindId = cckItem.get('contactKind').get('id');

            if (!surrogateCk.find(function (sckItem) {
              return sckItem.get('id') === cckContactKindId;
            })) {
              return cckItem.destroyRecord();
            }

            return _npmBluebird.default.resolve();
          });
        }).then(function () {
          return contact.reload();
        }).then(function () {
          return _npmBluebird.default.map(surrogateCk.toArray(), function (sckItem) {
            var sckContactKindId = sckItem.get('id');

            if (!cck.find(function (cckItem) {
              return cckItem.get('contactKind').get('id') === sckContactKindId;
            })) {
              var newCck = store.createRecord('contact-contact-kind');
              newCck.set('contact', contact);
              newCck.set('contactKind', sckItem);

              return newCck.save();
            }

            return _npmBluebird.default.resolve();
          });
        }).then(function () {
          return (
            // Go through all of the telephone numbers and addresses that are
            // either new or still in editing mode and save them.

            _npmBluebird.default.all([contact.get('addresses').map(function (a) {
              if (a.get('editing') === true || a.get('isNew') === true) {
                return that.actions.saveAddressInContact(contact, a);
              }

              return _npmBluebird.default.resolve();
            }), contact.get('tels').map(function (t) {
              if (t.get('editing') === true || t.get('isNew') === true) {
                return that.actions.saveTelInContact(contact, t);
              }

              return _npmBluebird.default.resolve();
            })])
          );
        }).then(function () {
          return contact.set('editing', false);
        }).then(function () {
          return contact.reload();
        });
      },


      /**
       * Function Delete Contact by params
       * @param {*} contact 
       */
      deleteContact: function deleteContact(contact) {
        return contact.destroyRecord();
      },


      /**
       * Function Change Salutation Contact by params
       * @param {*} contact 
       * @param {*} salutation 
       */
      changeSalutationInContact: function changeSalutationInContact(contact, salutation) {
        contact.set('salutation', salutation);
      },


      /**
       * Function Kinds in Contact by params
       * @param {*} contact 
       * @param {*} kinds 
       */
      changeKindsInContact: function changeKindsInContact(contact, kinds) {
        contact.get('surrogateContactKinds').setObjects(kinds);
      },


      /**
       * Function Create Tel in Contact by params
       * @param {*} contact 
       */
      createTelInContact: function createTelInContact(contact) {
        var _this5 = this;

        var controller = this;
        var index = -1;

        contact.get('contactTels').then(function (tels) {
          if (tels) {
            tels.forEach(function (telephone) {
              var cIndex = parseInt(telephone.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          // Function get Store and find Personal Tel Kind by param
          return _this5.get('store').find('personal-tel-kind', 1).then(function (personalTelKind) {
            var newTel = controller.get('store').createRecord('contact-tel', {
              contact: contact,
              index: index + 1,
              personalTelKind: personalTelKind
            });
            newTel.set('editing', true);
            contact.get('contactTels').pushObject(newTel);
          });
        });
      },


      /**
       * Function toggle Edit Tel Contact by params
       * @param {*} contact 
       * @param {*} tel 
       */
      toggleEditTelInContact: function toggleEditTelInContact(contact, tel) {
        if (tel.get('isNew')) {
          tel.destroy();
        } else {
          if (tel.get('editing') === true) {
            tel.set('editing', false);
            tel.rollbackAttributes();
          } else {
            tel.set('editing', true);
          }
        }
      },


      /**
       * Function Save Tel In Contact by params
       * @param {*} contact 
       * @param {*} tel 
       */
      saveTelInContact: function saveTelInContact(contact, tel) {
        if (contact.get('isNew') === true) {
          // We'll store this later once the contact exists in the database
          return tel.set('editing', false);
        }

        return tel.save().then(function () {
          return tel.set('editing', false);
        }).then(function () {
          return tel.reload();
        });
      },


      /**
       * Function Delete Tel in Contact by params
       * @param {*} contact 
       * @param {*} tel 
       */
      deleteTelInContact: function deleteTelInContact(contact, tel) {
        return tel.destroyRecord();
      },


      /**
       * Function Change Tel Kind in Contact by params
       * @param {*} contact 
       * @param {*} tel 
       * @param {*} kind 
       */
      changeTelKindInContact: function changeTelKindInContact(contact, tel, kind) {
        tel.set('kind', kind);
      },


      /**
       * Function Create Address in Contact by params
       * @param {*} contact 
       */
      createAddressInContact: function createAddressInContact(contact) {
        var _this6 = this;

        var controller = this;
        var index = -1;

        contact.get('contactAddresses').then(function (addresses) {
          if (addresses) {
            addresses.forEach(function (address) {
              var cIndex = parseInt(address.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          // Function get Store and Find Region by ID
          return _this6.get('store').find('region', 1).then(function (region) {
            return _this6.get('store').find('personal-address-kind', 1).then(function (personalAddressKind) {
              var newAddress = controller.get('store').createRecord('contact-address', {
                contact: contact,
                index: index + 1,
                personalAddressKind: personalAddressKind,
                region: region
              });
              newAddress.set('editing', true);
              contact.get('contactAddresses').pushObject(newAddress);
            });
          });
        });
      },


      /**
       * Function toggle Edit Address by params
       * @param {*} contact 
       * @param {*} address 
       */
      toggleEditAddressInContact: function toggleEditAddressInContact(contact, address) {
        if (address.get('isNew')) {
          address.destroy();
        } else {
          if (address.get('editing') === true) {
            address.set('editing', false);
            address.rollbackAttributes();
          } else {
            address.set('editing', true);
          }
        }
      },


      /**
       * Function Save Address in Contact by params
       * @param {*} contact 
       * @param {*} address 
       */
      saveAddressInContact: function saveAddressInContact(contact, address) {
        if (contact.get('isNew') === true) {
          // We'll store this later once the contact exists in the database
          return address.set('editing', false);
        }

        return address.save().then(function () {
          return address.set('editing', false);
        }).then(function () {
          return address.reload();
        });
      },


      /**
       * Function Delete Address in Contact by params
       * @param {*} contact 
       * @param {*} address 
       */
      deleteAddressInContact: function deleteAddressInContact(contact, address) {
        return address.destroyRecord();
      },


      /**
       * Function Change Address Kind Contact by params
       * @param {*} contact 
       * @param {*} address 
       * @param {*} kind 
       */
      changeAddressKindInContact: function changeAddressKindInContact(contact, address, kind) {
        address.set('kind', kind);
      },


      /**
       * Function Change Address Region in Contact by params
       * @param {*} contact 
       * @param {*} address 
       * @param {*} region 
       */
      changeAddressRegionInContact: function changeAddressRegionInContact(contact, address, region) {
        address.set('region', region);
      },
      beginEditNGFId: function beginEditNGFId() {
        this.set('ngfCourseId', this.get('model.ngfCourseId'));
      },
      editNGFId: function editNGFId() {
        this.set('ngfCourseId', this.get('model.ngfCourseId'));

        return this.get('model').save();
      },
      cancelNGFId: function cancelNGFId() {
        this.set('model.ngfCourseId', this.get('ngfCourseId'));
      },
      submit: function submit() {
        var controller = this;
        var model = this.get('model');

        return controller.saveModel(model);
      }
    }
  });
});