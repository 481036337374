define('golf-digest-panelists/utils/inject-token', ['exports', 'npm:url-parse', 'golf-digest-panelists/config/environment'], function (exports, _npmUrlParse, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (html, accessToken) {
    var elem = Ember.$('<div>' + html + '</div>');
    var apiLinks = elem.find('a[href^="/api/"]');
    var envApiParsedUrl = (0, _npmUrlParse.default)(_environment.default.jsonAdapterHost);

    Ember.$.each(apiLinks, function (index, element) {
      var linkElem = Ember.$(element);
      var url = linkElem.attr('href');
      var parsed = (0, _npmUrlParse.default)(url, true);

      parsed.protocol = envApiParsedUrl.protocol;
      parsed.slashes = envApiParsedUrl.slashes;
      parsed.host = envApiParsedUrl.host;
      parsed.hostname = envApiParsedUrl.hostname;
      parsed.port = envApiParsedUrl.port;
      parsed.query['access_token'] = accessToken;

      linkElem.attr('href', parsed.toString());
    });

    return elem.html();
  };
});