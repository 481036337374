define('golf-digest-panelists/components/main-rail/component', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['col-xs-3', 'rail'],
    classNameBindings: ['openRequested:mobile-open:mobile-closed'],
    id: 'main-rail',

    session: Ember.inject.service(),
    store: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),
    blocks: Ember.inject.service(),
    messageBus: Ember.inject.service(),

    items: Ember.A(),
    sortedItemsProperties: ['index:asc'],
    sortedItems: Ember.computed.sort('blocksState.blocks', 'sortedItemsProperties'),
    blockContext: {},

    openRequested: null,

    retrieveBlocks: function () {
      var _this = this;

      var that = this;
      var blocksSvc = this.get('blocks');

      var getStatePromise = new _npmBluebird.default(function (resolve) {
        if (_this.get('blocksState')) {
          return resolve(_this.get('blocksState'));
        }

        return resolve(blocksSvc.resolveState());
      });

      return getStatePromise.then(function (state) {
        return that.set('blocksState', state);
      });
    }.on('init'),

    subscribeToEvents: function () {
      var messageBus = this.get('messageBus');

      messageBus.subscribe('rail-toggle', this, this.toggleVisibility);
      messageBus.subscribe('rail-set', this, this.setVisibility);
      messageBus.subscribe('ready', this, this.resetVisibility);
    }.on('init'),

    resetVisibility: function resetVisibility() {
      var messageBus = this.get('messageBus');

      this.set('openRequested', null);

      messageBus.publish('rail-update', { isVisible: false });
      messageBus.publish('main-navigation-visibility', true);
    },
    setVisibility: function setVisibility(isVisible) {
      var messageBus = this.get('messageBus');

      this.set('openRequested', isVisible === true);

      messageBus.publish('rail-update', { isVisible: isVisible });
      messageBus.publish('main-navigation-set', isVisible === false);
    },
    toggleVisibility: function toggleVisibility() {
      var messageBus = this.get('messageBus');

      this.toggleProperty('openRequested');

      var isVisible = this.get('openRequested');

      messageBus.publish('rail-update', { isVisible: isVisible });
      messageBus.publish('main-navigation-visibility', isVisible === false);
    },
    init: function init() {
      this._super.apply(this, arguments);

      var editorMode = this.get('editorMode');

      if (editorMode) {
        this.set('classNames', ['rail']);
      }
    },


    actions: {
      goToCourse: function goToCourse(course) {
        this.sendAction('goToCourse', course);
      },
      selectRegion: function selectRegion(regionId) {
        this.get('searchTask')(regionId);
      },
      selectCountry: function selectCountry(countryId) {
        this.get('searchTaskCountry')(countryId);
      }
    }
  });
});