define('golf-digest-panelists/profile/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    model: function model() {
      return this.store.findRecord('user', this.get('session').currentUser.id);
    },


    actions: {
      changeSalutation: function changeSalutation(salutation) {
        this.currentModel.set('salutation', salutation);
      },
      addAddress: function addAddress(userAddress) {
        var user = this.modelFor('user.my-profile');

        return user.get('userAddresses').pushObject(userAddress);
      },
      addTels: function addTels(userTel) {
        var user = this.modelFor('user.my-profile');

        return user.get('userTels').pushObject(userTel);
      },
      removeAddress: function removeAddress(address) {
        var user = this.modelFor('user.my-profile');
        return user.get('userAddresses').removeObject(address);
      },
      removeTels: function removeTels(telephone) {
        var user = this.modelFor('user.my-profile');
        return user.get('userTels').removeObject(telephone);
      },
      submit: function submit() {
        return this.currentModel.save();
      }
    }
  });
});