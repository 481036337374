define('golf-digest-panelists/components/twitter-widget/component', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    _loadPlugin: function _loadPlugin(callback) {
      try {
        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.$.getScript('https://platform.twitter.com/widgets.js').done(callback).fail(function (jqxhr, textStatus, exc) {
            Ember.Logger.error('Failed to load Twitter plugin: ' + exc);
          });
        });
      } catch (e) {
        Ember.Logger.error('Failed to load Twitter plugin: ' + e.message);
      }
    },
    _createTimeline: function _createTimeline(component) {
      if (!_npmUnderscore.default.isUndefined(window.twttr)) {
        try {
          //noinspection JSUnresolvedVariable,JSUnresolvedFunction
          twttr.widgets.createTimeline('344842986540118018', component.element, {
            listOwnerScreenName: 'CNCustomApps',
            listSlug: 'golf-digest-courses'
          });
        } catch (e) {
          // The Twitter widget failed for unknown reasons. Trash it.
          Ember.Logger.error('Failed to load Twitter widget: ' + e.message);
        }
      } else {
        Ember.Logger.error('Twitter widget not loaded.');
      }
    },
    didInsertElement: function didInsertElement() {
      var that = this;

      that._super();

      if (_npmUnderscore.default.isUndefined(window.twttr)) {
        that._loadPlugin(function () {
          return that._createTimeline(that);
        });
      } else {
        that._createTimeline(that);
      }
    }
  });
});