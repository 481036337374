define('golf-digest-panelists/application/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      if (!attribute.hasOwnProperty('options') || !attribute.options.hasOwnProperty('readOnly') || attribute.options.readOnly !== true) {

        return this._super(snapshot, json, key, attribute);
      }

      // Otherwise, don't serialize it! It's read-only, after all.
    }
  });
});