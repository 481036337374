define('golf-digest-panelists/components/main-navigation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['closeRequested:mobile-closed:mobile-open'],

    cap: Ember.computed.alias('session.data.cap'),
    messageBus: Ember.inject.service(),

    closeRequested: null,

    subscribeToEvents: function () {
      var messageBus = this.get('messageBus');

      messageBus.subscribe('main-navigation-toggle', this, this.toggleVisibility);
      messageBus.subscribe('main-navigation-set', this, this.setVisibility);
    }.on('init'),

    toggleVisibility: function toggleVisibility() {
      var messageBus = this.get('messageBus');

      this.toggleProperty('closeRequested');
      messageBus.publish('main-navigation-update', { isVisible: this.get('closeRequested') === false });
    },
    setVisibility: function setVisibility(isVisible) {
      var messageBus = this.get('messageBus');

      this.set('closeRequested', isVisible === false);
      messageBus.publish('main-navigation-update', { isVisible: isVisible === true });
    }
  });
});