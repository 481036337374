define('golf-digest-panelists/models/evaluation', ['exports', 'ember-data', 'ember-data-has-many-query', 'ember-cp-validations', 'moment'], function (exports, _emberData, _emberDataHasManyQuery, _emberCpValidations, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    course: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    dateExperienced: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', { after: '1/1/1950', before: 'now' })]
    },
    scores: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('collection', true), (0, _emberCpValidations.validator)('has-many')]
    },
    notes: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('length', {
        allowBlank: true,
        max: 65536
      })]
    }
  });

  exports.default = _emberData.default.Model.extend(_emberDataHasManyQuery.default.ModelMixin, Validations, {
    user: _emberData.default.belongsTo('user', { inverse: null, readOnly: true }),
    course: _emberData.default.belongsTo('course', { inverse: null }),
    dateExperienced: _emberData.default.attr('utc'),
    fomattedDate: Ember.computed('dateExperienced', function () {
      return (0, _moment.default)(this.get('dateExperienced')).format('YYYY-MM-DD');
    }),
    evaluationYear: Ember.computed('dateExperienced', function () {
      return (0, _moment.default)(this.get('dateExperienced')).format('YYYY');
    }),
    version: _emberData.default.attr('number', { readOnly: true }),
    notes: _emberData.default.attr('string'),
    submittedAt: _emberData.default.attr('utc', { readOnly: true }),
    createdByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),
    scores: _emberData.default.hasMany('score'),
    scorestr: _emberData.default.attr('string'),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser')
  });
});