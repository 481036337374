define('golf-digest-panelists/models/page-image', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    page: _emberData.default.belongsTo('page'),
    index: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    fileName: _emberData.default.attr('string'),
    mimeType: _emberData.default.attr('string', { readOnly: true }),
    createdByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),

    fileData: _emberData.default.attr('raw'),

    baseUrl: Ember.computed(function () {
      var adapter = this.store.adapterFor('page-image');

      return adapter.buildURL('page-image', this.id);
    }),

    url: Ember.computed('fileName', function () {
      var size = 'original';

      return this.get('baseUrl') + '/' + size + '/' + this.get('fileName');
    }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser')
  });
});