define('golf-digest-panelists/components/bsi-url/component', ['exports', 'npm:underscore', 'npm:validator'], function (exports, _npmUnderscore, _npmValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showUrlErrorTooltip: false,

    keyUp: function keyUp() {
      var currentValue = this.get('value');

      if (!_npmUnderscore.default.isEmpty(currentValue) && !_npmValidator.default.isURL(currentValue)) {
        this.set('showUrlErrorTooltip', true);
      } else {
        this.set('showUrlErrorTooltip', false);
      }
    }
  });
});