define('golf-digest-panelists/helpers/prepend-http', ['exports', 'npm:underscore', 'npm:starts-with'], function (exports, _npmUnderscore, _npmStartsWith) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.prependHttp = prependHttp;
  function prependHttp(params) {
    if (params && params.length > 0) {
      var val = params.join(' ');

      if (_npmUnderscore.default.isString(val)) {
        if ((0, _npmStartsWith.default)(val, '//')) {
          return 'http' + val;
        }

        if (val.indexOf('://') === -1) {
          return 'http://' + val;
        }
      }

      return val;
    }
  }

  exports.default = Ember.Helper.helper(prependHttp);
});