define('golf-digest-panelists/helpers/coalesce', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.coalesce = coalesce;
  function coalesce(params /*, hash*/) {
    return _npmUnderscore.default.first(_npmUnderscore.default.compact(_npmUnderscore.default.flatten(params)));
  }

  exports.default = Ember.Helper.helper(coalesce);
});