define('golf-digest-panelists/surveys/survey/controller', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    busy: Ember.inject.service(),
    saved: false,
    selectedCourse: {},
    selectedSurvey: {},

    actions: {
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
      },
      done: function done() {
        this.transitionToRoute('surveys');
      },
      search: function search(query) {
        return this.get('store').query('course', {
          query: query
        });
      },
      searchSurvey: function searchSurvey(query) {
        return this.get('store').query('survey', {
          query: query
        });
      },
      changeSurveyValue: function changeSurveyValue(survey) {
        this.set('surveyValue', survey);
        this.set('selectedSurvey', survey);
      },
      addSurveyCourses: function addSurveyCourses() {
        var _this = this;

        var busy = this.get('busy');
        busy.show({ message: 'Adding courses...' });
        var flash = this.get('flashMessages');
        if (Object.keys(this.get('selectedSurvey')).length === 0) {
          flash.danger('Please select a Survey');
        } else {
          var controller = this;
          var surveyId = this.get('selectedSurvey').get('id');
          var existsCount = 0;
          var totalCount = 0;
          var model = this.get('model');
          var store = this.get('store');

          return store.query('survey', { surveyId: model.id, getCourse: true, noLimit: true }).then(function (existingCourses) {
            return _this.get('store').query('survey', { surveyId: surveyId, getCourse: true, noLimit: true }).then(function (courses) {
              totalCount = courses.content.length;
              courses.forEach(function (course) {
                existingCourses.forEach(function (currentCourse) {
                  if (currentCourse.id === course.id) {
                    existsCount++;
                  }
                });
              });

              var allCourses = [];
              var allExistingCourses = [];
              courses.forEach(function (course) {
                allCourses.push(course.id);
              });

              existingCourses.forEach(function (currentCourse) {
                allExistingCourses.push(currentCourse.id);
              });

              var ids = _npmUnderscore.default.difference(allCourses, allExistingCourses);

              if (ids.length > 0) {
                var newCourses = store.createRecord('candidate', {
                  survey: model,
                  course: ids,
                  coursesArray: ids
                });
                newCourses.save().then(function () {
                  var finalAddCount = totalCount - existsCount;
                  flash.info(finalAddCount + ' Course(s) added and ' + existsCount + ' Course(s) already exists in the survey');
                  busy.hide();
                  controller.get('target.router').refresh();
                });
              } else {
                var finalAddCount = totalCount - existsCount;
                flash.info(finalAddCount + ' Course(s) added and ' + existsCount + ' Course(s) already exists in the survey');
                busy.hide();
              }
            });
          });
        }
      },
      changeValue: function changeValue(course) {
        this.set('internalValue', course);
        this.set('selectedCourse', course);
      },
      addCourse: function addCourse(course) {
        var controller = this;
        var flash = this.get('flashMessages');
        if (Object.keys(course).length === 0) {
          flash.danger('Please select a Course');
        } else {
          var model = this.get('model');
          var courses = model.get('courses');
          var store = this.get('store');
          var found = false;
          courses.forEach(function (currentCourse) {
            if (currentCourse.id === course.id) {
              found = true;
              flash.danger(course.get('name') + ' Course already exists in the survey');
              controller.get('target.router').refresh();
            }
          });
          if (found === false) {
            var newCourse = store.createRecord('candidate', {
              survey: model,
              course: course
            });
            newCourse.save().then(function () {
              courses.pushObject(course);
              flash.success('Course ' + course.get('name') + ' added to survey');
              controller.get('target.router').refresh();
            });
          }
        }
      },
      saveSurvey: function saveSurvey() {
        this.toggleProperty('saved');
        var flash = this.get('flashMessages');

        var model = this.get('model');
        return model.save().then(function () {
          return flash.success('Survey Updated!');
        });
      },
      editSurvey: function editSurvey() {
        this.toggleProperty('saved');
      }
    }
  });
});