define('golf-digest-panelists/utils/capabilities', ['exports', 'npm:underscore', 'golf-digest-panelists/utils/permissions'], function (exports, _npmUnderscore, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (user) {
    var permissions = [];

    if (_npmUnderscore.default.isObject(user) && user.hasOwnProperty('Roles') && _npmUnderscore.default.isArray(user.Roles)) {

      user.Roles.forEach(function (r) {
        return r.Permissions.forEach(function (p) {
          return permissions.push(p.name.toLowerCase().trim());
        });
      });
    }

    permissions = _npmUnderscore.default.uniq(_npmUnderscore.default.compact(permissions));

    var cap = {
      // Articles & Article Images
      canReadArticles: hasPermission(permissions, _permissions.default.join(_permissions.default.ARTICLES_READ, _permissions.default.ARTICLES_CREATE, _permissions.default.ARTICLES_UPDATE)),
      canCreateArticles: hasPermission(permissions, _permissions.default.ARTICLES_CREATE),
      canUpdateArticles: hasPermission(permissions, _permissions.default.ARTICLES_UPDATE),
      canDeleteArticles: hasPermission(permissions, _permissions.default.ARTICLES_DELETE),

      // Self-service Security
      canUpdateSecret: hasPermission(permissions, _permissions.default.SEC_SECRET_UPDATE),

      // Blocks
      canReadBlocks: hasPermission(permissions, _permissions.default.join(_permissions.default.BLOCKS_READ, _permissions.default.BLOCKS_CREATE, _permissions.default.BLOCKS_UPDATE)),
      canCreateBlocks: hasPermission(permissions, _permissions.default.BLOCKS_CREATE),
      canUpdateBlocks: hasPermission(permissions, _permissions.default.BLOCKS_UPDATE),
      canDeleteBlocks: hasPermission(permissions, _permissions.default.BLOCKS_DELETE),

      // Options: Contact Kinds
      canCreateContactKinds: hasPermission(permissions, _permissions.default.CONTACT_KINDS_CREATE),
      canUpdateContactKinds: hasPermission(permissions, _permissions.default.CONTACT_KINDS_UPDATE),
      canDeleteContactKinds: hasPermission(permissions, _permissions.default.CONTACT_KINDS_DELETE),

      // Options: Countries
      canCreateCountries: hasPermission(permissions, _permissions.default.COUNTRIES_CREATE),
      canUpdateCountries: hasPermission(permissions, _permissions.default.COUNTRIES_UPDATE),
      canDeleteCountries: hasPermission(permissions, _permissions.default.COUNTRIES_DELETE),

      // Options: Course Kinds
      canCreateCourseKinds: hasPermission(permissions, _permissions.default.COURSE_KINDS_CREATE),
      canUpdateCourseKinds: hasPermission(permissions, _permissions.default.COURSE_KINDS_UPDATE),
      canDeleteCourseKinds: hasPermission(permissions, _permissions.default.COURSE_KINDS_DELETE),

      // Options: Course Statuses
      canCreateCourseStatuses: hasPermission(permissions, _permissions.default.COURSE_STATUSES_CREATE),
      canUpdateCourseStatuses: hasPermission(permissions, _permissions.default.COURSE_STATUSES_UPDATE),
      canDeleteCourseStatuses: hasPermission(permissions, _permissions.default.COURSE_STATUSES_DELETE),

      // Courses, Contacts, Course Addresses, Contact Addresses, Course Tels,
      // Contact Tels, Tees, Course Putting Turfs, Course Fairway Turfs, and
      // Candidates
      canReadCourses: hasPermission(permissions, _permissions.default.join(_permissions.default.COURSES_READ_CURRENT_CANDIDATES, _permissions.default.COURSES_READ_ALL)),
      canReadAllCourses: hasPermission(permissions, _permissions.default.COURSES_READ_ALL),
      canCreateCourses: hasPermission(permissions, _permissions.default.COURSES_CREATE),
      canUpdateCourses: hasPermission(permissions, _permissions.default.COURSES_UPDATE),
      canDeleteCourses: hasPermission(permissions, _permissions.default.COURSES_DELETE),

      // Criteria
      canCreateCriteria: hasPermission(permissions, _permissions.default.CRITERIA_CREATE),
      canUpdateCriteria: hasPermission(permissions, _permissions.default.CRITERIA_UPDATE),
      canDeleteCriteria: hasPermission(permissions, _permissions.default.CRITERIA_DELETE),

      // Discussions
      canReadDiscussions: hasPermission(permissions, _permissions.default.join(_permissions.default.DISCUSSIONS_READ_UNHIDDEN, _permissions.default.DISCUSSIONS_READ_HIDDEN)),
      canCreateDiscussions: hasPermission(permissions, _permissions.default.DISCUSSIONS_CREATE),
      canUpdateOwnDiscussions: hasPermission(permissions, _permissions.default.join(_permissions.default.DISCUSSIONS_UPDATE_OWN, _permissions.default.DISCUSSIONS_UPDATE_ALL, _permissions.default.DISCUSSIONS_LOCK, _permissions.default.DISCUSSIONS_HIDE)),
      canUpdateAllDiscussions: hasPermission(permissions, _permissions.default.DISCUSSIONS_UPDATE_ALL),
      canUpdateLockedDiscussions: hasPermission(permissions, _permissions.default.join(_permissions.default.DISCUSSIONS_LOCK, _permissions.default.DISCUSSIONS_UPDATE_ALL)),
      canLockDiscussions: hasPermission(permissions, _permissions.default.DISCUSSIONS_LOCK),
      canHideDiscussions: hasPermission(permissions, _permissions.default.DISCUSSIONS_HIDE),
      canDeleteOwnDiscussions: hasPermission(permissions, _permissions.default.join(_permissions.default.DISCUSSIONS_DELETE_OWN, _permissions.default.DISCUSSIONS_DELETE_ALL)),
      canDeleteAllDiscussions: hasPermission(permissions, _permissions.default.DISCUSSIONS_DELETE_ALL),

      // Documents
      canReadDocuments: hasPermission(permissions, _permissions.default.join(_permissions.default.DOCUMENTS_READ, _permissions.default.DOCUMENTS_CREATE, _permissions.default.DOCUMENTS_UPDATE)),
      canCreateDocuments: hasPermission(permissions, _permissions.default.DOCUMENTS_CREATE),
      canUpdateDocuments: hasPermission(permissions, _permissions.default.DOCUMENTS_UPDATE),
      canDeleteDocuments: hasPermission(permissions, _permissions.default.DOCUMENTS_DELETE),

      // Evaluations and their scores
      canReadOwnEvaluations: hasPermission(permissions, _permissions.default.join(_permissions.default.EVALUATIONS_READ_OWN, _permissions.default.EVALUATIONS_READ_ALL)),
      canReadAllEvaluations: hasPermission(permissions, _permissions.default.EVALUATIONS_READ_ALL),
      canCreateEvaluations: hasPermission(permissions, _permissions.default.EVALUATIONS_CREATE),
      canUpdateOwnEvaluations: hasPermission(permissions, _permissions.default.join(_permissions.default.EVALUATIONS_UPDATE_OWN, _permissions.default.EVALUATIONS_UPDATE_ALL)),
      canUpdateAllEvaluations: hasPermission(permissions, _permissions.default.EVALUATIONS_UPDATE_ALL),
      canDeleteOwnEvaluations: hasPermission(permissions, _permissions.default.join(_permissions.default.EVALUATIONS_DELETE_OWN, _permissions.default.EVALUATIONS_DELETE_ALL)),
      canDeleteAllEvaluations: hasPermission(permissions, _permissions.default.EVALUATIONS_DELETE_ALL),

      // Facilities, Facility Tels, and Facility Addresses
      canReadFacilities: hasPermission(permissions, _permissions.default.FACILITIES_READ),
      canCreateFacilities: hasPermission(permissions, _permissions.default.FACILITIES_CREATE),
      canUpdateFacilities: hasPermission(permissions, _permissions.default.FACILITIES_UPDATE),
      canDeleteFacilities: hasPermission(permissions, _permissions.default.FACILITIES_DELETE),

      // Options: Facility Kinds
      canCreateFacilityKinds: hasPermission(permissions, _permissions.default.FACILITY_KINDS_CREATE),
      canUpdateFacilityKinds: hasPermission(permissions, _permissions.default.FACILITY_KINDS_UPDATE),
      canDeleteFacilityKinds: hasPermission(permissions, _permissions.default.FACILITY_KINDS_DELETE),

      // NGF Facilities, NGF Facility Tels, and NGF Facility Addresses
      canReadNgfFacilities: hasPermission(permissions, _permissions.default.NGF_FACILITIES_READ),
      canCreateNgfFacilities: hasPermission(permissions, _permissions.default.NGF_FACILITIES_CREATE),
      canUpdateNgfFacilities: hasPermission(permissions, _permissions.default.NGF_FACILITIES_UPDATE),
      canDeleteNgfFacilities: hasPermission(permissions, _permissions.default.NGF_FACILITIES_DELETE),

      // Options: NGF Facility Kinds
      canCreateNgfFacilityKinds: hasPermission(permissions, _permissions.default.NGF_FACILITY_KINDS_CREATE),
      canUpdateNgfFacilityKinds: hasPermission(permissions, _permissions.default.NGF_FACILITY_KINDS_UPDATE),
      canDeleteNgfFacilityKinds: hasPermission(permissions, _permissions.default.NGF_FACILITY_KINDS_DELETE),

      // Options: Organizational Address Kinds
      canCreateOrganizationalAddressKinds: hasPermission(permissions, _permissions.default.ORG_ADDRESS_KINDS_CREATE),
      canUpdateOrganizationalAddressKinds: hasPermission(permissions, _permissions.default.ORG_ADDRESS_KINDS_UPDATE),
      canDeleteOrganizationalAddressKinds: hasPermission(permissions, _permissions.default.ORG_ADDRESS_KINDS_DELETE),

      // Options: Organizational Tel Kinds
      canCreateOrganizationalTelKinds: hasPermission(permissions, _permissions.default.ORG_TEL_KINDS_CREATE),
      canUpdateOrganizationalTelKinds: hasPermission(permissions, _permissions.default.ORG_TEL_KINDS_UPDATE),
      canDeleteOrganizationalTelKinds: hasPermission(permissions, _permissions.default.ORG_TEL_KINDS_DELETE),

      // Pages & Page Images
      canReadPages: hasPermission(permissions, _permissions.default.join(_permissions.default.PAGES_READ, _permissions.default.PAGES_CREATE, _permissions.default.PAGES_UPDATE)),
      canCreatePages: hasPermission(permissions, _permissions.default.PAGES_CREATE),
      canUpdatePages: hasPermission(permissions, _permissions.default.PAGES_UPDATE),
      canDeletePages: hasPermission(permissions, _permissions.default.PAGES_DELETE),

      // Options: Permissions
      canCreatePermissions: hasPermission(permissions, _permissions.default.PERMISSIONS_CREATE),
      canUpdatePermissions: hasPermission(permissions, _permissions.default.PERMISSIONS_UPDATE),
      canDeletePermissions: hasPermission(permissions, _permissions.default.PERMISSIONS_DELETE),

      // Options: Personal Address Kinds
      canCreatePersonalAddressKinds: hasPermission(permissions, _permissions.default.PERSONAL_ADDRESS_KINDS_CREATE),
      canUpdatePersonalAddressKinds: hasPermission(permissions, _permissions.default.PERSONAL_ADDRESS_KINDS_UPDATE),
      canDeletePersonalAddressKinds: hasPermission(permissions, _permissions.default.PERSONAL_ADDRESS_KINDS_DELETE),

      // Options: Personal Tel Kinds
      canCreatePersonalTelKinds: hasPermission(permissions, _permissions.default.PERSONAL_TEL_KINDS_CREATE),
      canUpdatePersonalTelKinds: hasPermission(permissions, _permissions.default.PERSONAL_TEL_KINDS_UPDATE),
      canDeletePersonalTelKinds: hasPermission(permissions, _permissions.default.PERSONAL_TEL_KINDS_DELETE),

      // Options: Profile Fields
      canCreateProfileFields: hasPermission(permissions, _permissions.default.PROFILE_FIELDS_CREATE),
      canUpdateProfileFields: hasPermission(permissions, _permissions.default.PROFILE_FIELDS_UPDATE),
      canDeleteProfileFields: hasPermission(permissions, _permissions.default.PROFILE_FIELDS_DELETE),

      // Profile Values (not addresses, tels, or avatars)
      canUpdateOwnProfile: hasPermission(permissions, _permissions.default.join(_permissions.default.PROFILES_UPDATE_OWN, _permissions.default.PROFILES_UPDATE_ALL)),
      canUpdateAllProfiles: hasPermission(permissions, _permissions.default.PROFILES_UPDATE_ALL),

      // Options: Regions
      canCreateRegions: hasPermission(permissions, _permissions.default.REGIONS_CREATE),
      canUpdateRegions: hasPermission(permissions, _permissions.default.REGIONS_UPDATE),
      canDeleteRegions: hasPermission(permissions, _permissions.default.REGIONS_DELETE),

      // Options: Roles
      canCreateRoles: hasPermission(permissions, _permissions.default.ROLES_CREATE),
      canUpdateRoles: hasPermission(permissions, _permissions.default.ROLES_UPDATE),
      canDeleteRoles: hasPermission(permissions, _permissions.default.ROLES_DELETE),

      // Options: Salutations
      canCreateSalutations: hasPermission(permissions, _permissions.default.SALUTATIONS_CREATE),
      canUpdateSalutations: hasPermission(permissions, _permissions.default.SALUTATIONS_UPDATE),
      canDeleteSalutations: hasPermission(permissions, _permissions.default.SALUTATIONS_DELETE),

      // Self-service Security Answers
      canUpdateSecurityAnswers: hasPermission(permissions, _permissions.default.SEC_SECURITY_ANSWERS_UPDATE),

      // Options: Security Questions
      canCreateSecurityQuestions: hasPermission(permissions, _permissions.default.SECURITY_QUESTIONS_CREATE),
      canUpdateSecurityQuestions: hasPermission(permissions, _permissions.default.SECURITY_QUESTIONS_UPDATE),
      canDeleteSecurityQuestions: hasPermission(permissions, _permissions.default.SECURITY_QUESTIONS_DELETE),

      // Surveys
      canReadSurveys: hasPermission(permissions, _permissions.default.SURVEYS_READ),
      canCreateSurveys: hasPermission(permissions, _permissions.default.SURVEYS_CREATE),
      canUpdateSurveys: hasPermission(permissions, _permissions.default.SURVEYS_UPDATE),
      canDeleteSurveys: hasPermission(permissions, _permissions.default.SURVEYS_DELETE),

      // Options: Turfs
      canCreateTurfs: hasPermission(permissions, _permissions.default.TURFS_CREATE),
      canUpdateTurfs: hasPermission(permissions, _permissions.default.TURFS_UPDATE),
      canDeleteTurfs: hasPermission(permissions, _permissions.default.TURFS_DELETE),

      // Users, User Addresses, User Emails, and User Tels
      canReadNonsensitiveUserDetails: hasPermission(permissions, _permissions.default.join(_permissions.default.PROFILES_READ, _permissions.default.USERS_READ_SENSITIVE, _permissions.default.PROFILES_UPDATE_OWN, _permissions.default.PROFILES_UPDATE_ALL, _permissions.default.USERS_CREATE, _permissions.default.USERS_UPDATE)),
      canReadSensitiveUserDetails: hasPermission(permissions, _permissions.default.USERS_READ_SENSITIVE),
      canCreateUsers: hasPermission(permissions, _permissions.default.USERS_CREATE),
      canUpdateOwnUserDetails: hasPermission(permissions, _permissions.default.join(_permissions.default.USERS_UPDATE, _permissions.default.PROFILES_UPDATE_OWN, _permissions.default.PROFILES_UPDATE_ALL)),
      canUpdateAllUserDetails: hasPermission(permissions, _permissions.default.join(_permissions.default.USERS_UPDATE, _permissions.default.PROFILES_UPDATE_ALL)),
      canUpdateOwnAvatar: hasPermission(permissions, _permissions.default.join(_permissions.default.PROFILES_AVATAR_UPDATE_OWN, _permissions.default.PROFILES_AVATAR_UPDATE_ALL)),
      canUpdateAllAvatars: hasPermission(permissions, _permissions.default.PROFILES_AVATAR_UPDATE_ALL),
      canDeleteUsers: hasPermission(permissions, _permissions.default.USERS_DELETE),

      // Administrative password resets
      canResetPasswords: hasPermission(permissions, _permissions.default.join(_permissions.default.SEC_SECRET_RESET, _permissions.default.SEC_SECRET_RESET_MASS)),
      canMassResetPasswords: hasPermission(permissions, _permissions.default.SEC_SECRET_RESET_MASS),

      // Places to Play
      canAccessPtp: hasPermission(permissions, _permissions.default.join(_permissions.default.PTP_REVIEWS_READ_OWN, _permissions.default.PTP_REVIEWS_READ_ALL, _permissions.default.PTP_REVIEWS_CREATE, _permissions.default.PTP_REVIEWS_UPDATE_OWN, _permissions.default.PTP_REVIEWS_UPDATE_ALL, _permissions.default.PTP_REVIEWS_DELETE_OWN, _permissions.default.PTP_REVIEWS_DELETE_ALL)),
      canReadOwnPtpReviews: hasPermission(permissions, _permissions.default.join(_permissions.default.PTP_REVIEWS_READ_OWN, _permissions.default.PTP_REVIEWS_READ_ALL)),
      canReadAllPtpReviews: hasPermission(permissions, _permissions.default.PTP_REVIEWS_READ_ALL),
      canCreatePtpReviews: hasPermission(permissions, _permissions.default.PTP_REVIEWS_CREATE),
      canUpdateOwnPtpReviews: hasPermission(permissions, _permissions.default.join(_permissions.default.PTP_REVIEWS_UPDATE_OWN, _permissions.default.PTP_REVIEWS_UPDATE_ALL)),
      canUpdateAllPtpReviews: hasPermission(permissions, _permissions.default.PTP_REVIEWS_UPDATE_ALL),
      canDeleteOwnPtpReviews: hasPermission(permissions, _permissions.default.join(_permissions.default.PTP_REVIEWS_DELETE_OWN, _permissions.default.PTP_REVIEWS_DELETE_ALL)),
      canDeleteAllPtpReviews: hasPermission(permissions, _permissions.default.PTP_REVIEWS_DELETE_ALL),
      canCreateUnratedPtpReviews: hasPermission(permissions, _permissions.default.PTP_REVIEWS_CREATE_UNRATED)
    };

    // Compound capabilities for convenience
    cap.canSelectCandidates = cap.canCreateCourses || cap.canUpdateCourses || cap.canCreateSurveys || cap.canUpdateSurveys;

    return cap;
  };

  // Please note: The capabilities exposed via the functionality below are not
  // a substitution for real security on the API. The purpose of this is to show
  // or hide user interface elements based on security, not permit or deny access.

  function hasPermission(userPermissions, permission) {
    if (permission === true) {
      return true;
    }

    if (permission === false || !_npmUnderscore.default.isString(permission) && !_npmUnderscore.default.isArray(permission)) {

      return false;
    }

    if (_npmUnderscore.default.isString(permission)) {
      var cleanPerm = permission.toLowerCase().trim();

      if (permission === true) {
        return true;
      }

      if (permission === false) {
        return false;
      }

      return _npmUnderscore.default.some(userPermissions, function (p) {
        return p === cleanPerm;
      });
    }

    if (_npmUnderscore.default.isArray(permission)) {
      return _npmUnderscore.default.some(permission, function (p) {
        return hasPermission(userPermissions, p);
      });
    }

    return false;
  }
});