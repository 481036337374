define('golf-digest-panelists/courses/course/evaluations/route', ['exports', 'ember-cli-pagination/remote/route-mixin'], function (exports, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,

    model: function model(params) {
      var courseId = this.paramsFor('courses/course').course_id;

      params.CourseId = courseId;
      params.course_id = courseId;

      return Ember.RSVP.hash({

        // Find and return Course By ID
        course: this.store.findRecord('course', courseId),

        // Find and return Evaluation By ID
        evaluations: this.findPaged('evaluation', params)
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (model.course.get('evaluationsCount') > 0) {
        controller.set('IsActiveEvalAvailable', true);
      } else {
        controller.set('IsActiveEvalAvailable', false);
      }
      var data = model.evaluations;
      if (data.otherParams.active === 'true') {
        controller.set('activeEval', true);
      } else {
        controller.set('activeEval', false);
      }
    },

    actions: {
      pageClicked: function pageClicked(page) {
        this.set('page', page);
      }
    }
  });
});