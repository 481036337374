define('golf-digest-panelists/components/evaluation-editor/evaluation-criteria/evaluation-criterion/hole-averager/component', ['exports', 'npm:underscore', 'npm:is-numeric', 'npm:round-to'], function (exports, _npmUnderscore, _npmIsNumeric, _npmRoundTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),

    average: Ember.computed('holes.@each.value', function () {
      var holes = this.get('holes');

      if (holes) {
        var values = holes.filter(function (i) {
          return _npmUnderscore.default.isNumber(i.value) || (0, _npmIsNumeric.default)(i.value);
        }).map(function (i) {
          return _npmUnderscore.default.isNumber(i.value) ? i.value : parseFloat(i.value);
        });

        if (values.length > 0) {
          var sum = 0.0;
          var width = values.length;

          values.forEach(function (v) {
            return sum = sum + v;
          });

          return (0, _npmRoundTo.default)(sum / width, 4);
        }
      }

      return 0.0;
    }),

    averageObserver: function () {
      if (this) {

        // Initialize whatever gets this value with whatever the starting value is

        var func = this.get('onChange');

        if (func) {
          return func(this.get('average'));
        }

        window.Ember.Logger.warn('Missing onChange function!');
      }
    }.observes('average'),

    init: function init() {
      this._super.apply(this, arguments);

      var holeCount = this.get('holeCount') || 18;
      var holes = this.get('holes');

      if (!holes) {
        holes = new Ember.A();
      }

      if (holes.length === 0) {
        for (var i = 0; i < holeCount; i++) {
          holes.push({ holeNumber: i + 1, value: null });
        }

        // Initialize whatever gets this value with whatever the starting value is

        var func = this.get('onChange');

        if (func) {
          return func(this.get('average'));
        }

        window.Ember.Logger.warn('Missing onChange function!');
      }
    }
  });
});