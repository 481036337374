define('golf-digest-panelists/discussions/new/route', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var discussion = this.store.createRecord('discussion');

      discussion.get('posts').pushObject(this.store.createRecord('discussion-post'));

      return discussion;
    },


    actions: {
      abort: function abort(post) {
        var discussion = this.modelFor('discussions.new');

        post.destroyRecord();
        discussion.destroyRecord();

        this.transitionTo('discussions');
      },
      submit: function submit(post) {
        var that = this;
        var discussion = that.modelFor('discussions.new');

        // Temporarily yank out all images. We need to save just the post first
        var images = post.get('images').map(function (image) {
          return image;
        }); // Clone hack
        post.get('images').clear();

        return discussion.save().then(function () {
          return post.save();
        }).then(function () {
          return post.reload();
        }).then(function () {
          images.forEach(function (image) {
            // Now that we have the post ID via the reload call we can use it
            image.set('discussionPost', post);

            // Put the image back into the post.
            post.get('images').pushObject(image);
          });

          return _npmBluebird.default.all(post.get('images').map(function (image, index) {
            image.set('index', index);
            return image.save().then(function () {
              return image.reload();
            }).then(function () {
              return image.set('fileData', undefined);
            });
          }));
        }).then(function () {
          return that.transitionTo('discussions.discussion', discussion.get('id'));
        });
      },
      addImage: function addImage(post, file) {
        var that = this;

        return new _npmBluebird.default(function (resolve, reject) {
          var reader = new FileReader();
          reader.onload = function (e) {
            return resolve(e.target.result);
          };
          reader.onerror = function (err) {
            return reject(err);
          };
          reader.onabort = function () {
            return reject(new Error('File read aborted'));
          };

          reader.readAsDataURL(file);
        }).then(function (data) {
          var image = that.store.createRecord('discussion-post-image');

          image.set('title', file.name);
          image.set('fileName', file.name);
          image.set('mimeType', file.type);
          image.set('fileData', data);

          post.get('images').pushObject(image);
        });
      },
      deleteImage: function deleteImage(post, image) {
        post.get('images').removeObject(image);
        image.deleteRecord();
      }
    }
  });
});