define('golf-digest-panelists/components/table-tel/table-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',

    actions: {
      toggleEdit: function toggleEdit() {
        this.sendAction('toggleEdit', this.get('item'));
      },
      save: function save() {
        this.sendAction('save', this.get('item'));
      },
      delete: function _delete() {
        this.sendAction('delete', this.get('item'));
      },
      changeKind: function changeKind(kind) {
        this.sendAction('changeKind', this.get('item'), kind);
      }
    }
  });
});