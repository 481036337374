define('golf-digest-panelists/models/role', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    createdByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),
    permissions: _emberData.default.hasMany('permission'),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser')
  });
});