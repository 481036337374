define('golf-digest-panelists/components/select-candidacies/list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['list-group-item'],

    actions: {
      removeCandidacy: function removeCandidacy() {
        this.sendAction('removeCandidacy', this.get('item'));
      },
      toggleFullyAssigned: function toggleFullyAssigned(value) {
        this.sendAction('toggleFullyAssigned', this.get('item'), value);
      }
    }
  });
});