define('golf-digest-panelists/components/document-list/document-item/component', ['exports', 'golf-digest-panelists/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['document-item', 'table-row'],
    tagName: 'tr',
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),
    documentsURL: _environment.default.jsonAdapterHost + '/api/v2/documents/',

    actions: {
      deleteDocument: function deleteDocument(document) {
        var flash = this.get('flashMessages');

        return document.destroyRecord().then(function () {
          return flash.success('Document Deleted!');
        });
        // => DELETE to /documents/id
      }
    }
  });
});