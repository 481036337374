define('golf-digest-panelists/components/ptp-course-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    region: null,
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    internalValue: Ember.computed('value', function () {
      return this.get('value');
    }),

    actions: {
      search: function search(query) {
        return this.get('store').query('ngf-course', {
          query: query,
          ptp: true,
          RegionId: this.get('region') ? this.get('region').get('id') : null
        });
      },
      changeRegion: function changeRegion(region) {
        if (region) {
          this.set('region', region);
          this.get('regionChange')(region);
        } else {
          this.set('region', null);
          this.get('regionChange')(null);
        }
        this.send('changeValue', null);
      },
      changeValue: function changeValue(course) {
        var _this = this;

        if (course) {
          // Find and return Record Course By ID
          return this.get('store').findRecord('ngf-course', course.get('id')).then(function (realCourse) {
            return _this.get('onChange')(realCourse);
          });
        }

        this.get('onChange')(null);
      }
    }
  });
});