define('golf-digest-panelists/models/discussion', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    isLocked: _emberData.default.attr('boolean'),
    isHidden: _emberData.default.attr('boolean'),
    isDeactivated: _emberData.default.attr('boolean'),
    createdByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedAtLocal: Ember.computed('updatedAt', function () {
      return (0, _moment.default)(this.get('updatedAt')).local();
    }),
    discussionPosts: _emberData.default.hasMany('discussion-post'),

    firstPost: Ember.computed.alias('discussionPosts.firstObject'),
    author: Ember.computed.alias('firstPost.author'),
    title: Ember.computed.alias('firstPost.title'),
    lead: Ember.computed.alias('firstPost.lead'),
    thumbnailImage: Ember.computed.alias('firstPost.thumbnailImage'),

    otherPosts: Ember.computed('discussionPosts', function () {
      return this.get('discussionPosts').filter(function (i, x) {
        return x > 0;
      });
    }),

    postCount: Ember.computed.alias('discussionPosts.length'),
    replyCount: Ember.computed('postCount', function () {
      return this.get('postCount') - 1;
    }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser'),
    posts: Ember.computed.alias('discussionPosts')
  });
});