define('golf-digest-panelists/models/security-answer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    answerSecret: _emberData.default.attr('string'),
    User: _emberData.default.belongsTo('user'),
    securityQuestion: _emberData.default.belongsTo('security-question')
  });
});