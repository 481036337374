define('golf-digest-panelists/ptp/review/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),
    userId: Ember.computed.alias('session.data.currentUser.id')
  });
});