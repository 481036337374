define('golf-digest-panelists/components/latest-articles/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap')
  });
});