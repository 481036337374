define('golf-digest-panelists/initializers/route-styles', ['exports', 'ember-component-css/pod-names'], function (exports, _podNames) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  Ember.Router.reopen({
    didTransition: function didTransition(routes) {
      this._super.apply(this, arguments);

      var classes = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = routes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var route = _step.value;

          var currentPath = route.name.replace(/\./g, '/');

          if (_podNames.default[currentPath]) {
            Ember.getOwner(this).lookup('controller:' + route.name).set('styleNamespace', _podNames.default[currentPath]);
            classes.push(_podNames.default[currentPath]);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      Ember.getOwner(this).lookup('controller:application').set('routeStyleNamespaceClassSet', classes.join(' '));
    }
  });

  function initialize() {}

  exports.default = {
    name: 'route-styles',
    initialize: initialize
  };
});