define('golf-digest-panelists/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'golf-digest-panelists/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP,
      run = Ember.run;


  var esaConf = _environment.default['ember-simple-auth'];

  exports.default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: esaConf.serverTokenEndpoint,
    serverTokenRevocationEndpoint: esaConf.serverTokenRevocationEndpoint,
    refreshAccessTokens: true,

    /**
     * Function Make Request OAuth 2 for params
     * @param {*} url 
     * @param {*} data 
     */
    makeRequest: function makeRequest(url, data) {
      var clientId = esaConf.client;
      var clientSecret = esaConf.secret;

      return Ember.$.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        contentType: 'application/x-www-form-urlencoded',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        headers: {
          Authorization: 'Basic ' + btoa(clientId + ':' + clientSecret),
          client_id: clientId
        }
      });
    },


    /**
     * Function Authenticate OAuth 2 for params
     * @param {*} identification 
     * @param {*} password 
     * @param {*} scope 
     */
    authenticate: function authenticate(identification, password) {
      var _this = this;

      var scope = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

      return new RSVP.Promise(function (resolve, reject) {
        var data = {
          grant_type: 'password',
          username: identification,
          password: password
        };
        var serverTokenEndpoint = _this.get('serverTokenEndpoint');
        var scopesString = Ember.makeArray(scope).join(' ');

        if (!Ember.isEmpty(scopesString)) {
          data.scope = scopesString;
        }

        _this.makeRequest(serverTokenEndpoint, data).then(function (response) {
          run(function () {
            var expiresAtString = response['accessTokenExpiresAt'];
            var expiresAt = new Date(expiresAtString).getTime();

            _this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);

            if (!Ember.isEmpty(expiresAt)) {
              response = Ember.merge(response, { expires_at: expiresAt });
            }

            return resolve(response);
          });
        }, function (xhr) {
          run(null, reject, xhr.responseJSON || xhr.responseText);
        });
      });
    },


    /**
     * Function Restore OAuth 2 for param
     * @param {*} data 
     */
    restore: function restore(data) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        var now = new Date().getTime();
        var refreshAccessTokens = _this2.get('refreshAccessTokens');
        var accessTokenExpiresAt = data['accessTokenExpiresAt'];

        if (!Ember.isEmpty(accessTokenExpiresAt) && new Date(accessTokenExpiresAt).getTime() < now) {

          if (refreshAccessTokens) {
            _this2._refreshAccessToken(data['expires_in'], data['refresh_token']).then(resolve, reject);
          } else {
            return reject();
          }
        } else {
          if (Ember.isEmpty(data['access_token'])) {
            return reject();
          }

          _this2._scheduleAccessTokenRefresh(data['expires_in'], data['expires_at'], data['refresh_token']);

          return resolve(data);
        }
      });
    },


    /**
     * Function Refresh Access Token for params
     * @param {*} expiresIn 
     * @param {*} refreshToken 
     */
    _refreshAccessToken: function _refreshAccessToken(expiresIn, refreshToken) {
      var _this3 = this;

      var data = { grant_type: 'refresh_token', refresh_token: refreshToken };
      var serverTokenEndpoint = this.get('serverTokenEndpoint');

      return new RSVP.Promise(function (resolve, reject) {
        _this3.makeRequest(serverTokenEndpoint, data).then(function (response) {
          run(function () {
            expiresIn = response['expires_in'] || expiresIn;
            refreshToken = response['refresh_token'] || refreshToken;
            var expiresAtString = response['accessTokenExpiresAt'];
            var expiresAt = new Date(expiresAtString).getTime();
            var data = Ember.merge(response, {
              expires_in: expiresIn,
              expires_at: expiresAt,
              refresh_token: refreshToken
            });

            _this3._scheduleAccessTokenRefresh(expiresIn, expiresAt, refreshToken);
            _this3.trigger('sessionDataUpdated', data);

            return resolve(data);
          });
        }, function (xhr, status, error) {
          _this3.trigger('sessionDataInvalidated', data);

          Ember.Logger.warn('Access token could not be refreshed - ' + ('server responded with ' + error));

          return reject(new Error('Access token could not be refreshed - ' + ('server responded with ' + error)));
        });
      });
    }
  });
});