define('golf-digest-panelists/components/human-name/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      changeSalutation: function changeSalutation(salutation) {
        this.sendAction('changeSalutation', salutation);
      }
    }
  });
});