define('golf-digest-panelists/discussions/index/route', ['exports', 'ember-cli-pagination/remote/route-mixin'], function (exports, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,

    model: function model(params) {
      return this.findPaged('discussion', params);
    },

    queryParams: {
      query: {
        refreshModel: true
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('discussion_query', controller.get('model').otherParams.query);
    },

    actions: {
      goToCourse: function goToCourse(course) {
        this.transitionTo('courses.course', course.get('id'));
      },
      regionRedirect: function regionRedirect(regionId) {
        this.transitionTo('courses', { queryParams: { RegionId: regionId } });
      },
      // Redirect Course per Country By Id
      countryRedirect: function countryRedirect(countryId) {
        this.transitionTo('courses', { queryParams: { CountryId: countryId } });
      }
    }
  });
});