define('golf-digest-panelists/components/survey-list/survey-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['survey-item', 'table-row'],
    tagName: 'tr',
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    actions: {
      deleteSurvey: function deleteSurvey(survey) {
        var flash = this.get('flashMessages');

        return survey.destroyRecord().then(function () {
          return flash.success('Survey Deleted!');
        });
        // => DELETE to /surveys/id
      }
    }
  });
});