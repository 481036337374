define('golf-digest-panelists/components/wysiwyg-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    options: {
      plugins: ['hr', 'link', 'paste', 'code', 'fullscreen', 'table'],

      toolbar: ['bold italic underline strikethrough removeformat | numlist bullist', 'pastetext | table | link hr | fullscreen code'],

      inline: false,
      menubar: false,

      // skin: 'lightgray',
      statusbar: true,
      theme: 'silver',

      browser_spellcheck: true,

      element_format: 'html',
      valid_elements: ['a[href|title],address,b,big,blockquote,br,caption,code,dd,del', 'div[class],dl,dt,em,h1,h2,h3,h4,h5,h6,header[class],hr,i[class]', 'img[src|width|height|alt|title],kbd,li,ol,p,pre,s,section[class]', 'small,strike,strong,sub,sup,table[border|cellpadding|cellspacing]', 'tbody,td[colspan|rowspan],th,time[datetime],thead,tr,u,ul[class]'].join(','),

      min_height: 240,
      content_css: '/assets/tinymce.css',
      body_class: 'block-module'
    },
    actions: {}
  });
});