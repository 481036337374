define('golf-digest-panelists/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'golf-digest-panelists/mixins/secure-default-route-factory', 'golf-digest-panelists/utils/forbidden-error', 'golf-digest-panelists/config/environment'], function (exports, _applicationRouteMixin, _secureDefaultRouteFactory, _forbiddenError, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Ember.Route.reopenClass(_secureDefaultRouteFactory.default);

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    messageBus: Ember.inject.service(),

    title: function title(tokens) {
      if (!!tokens && tokens.length > 0) {
        return tokens.join(' - ') + ' - ' + _environment.default.brand;
      }

      return _environment.default.brand;
    },


    // Returns at anchor position, to the top
    top: function top() {
      try {
        document.querySelector('#page-container').scrollTop = 0;
      } catch (e) {
        // Ignore.
      }
    },


    // Activate route
    activate: function activate() {
      this._super.apply(this, arguments);
      this.top();
    },


    // Load Current User Session
    _loadCurrentUser: function _loadCurrentUser() {
      return this.get('session').loadCurrentUser();
    },
    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },


    // Authenticating user
    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this;

      this._super.apply(this, arguments);

      return this._loadCurrentUser().then(function () {
        var isDisabled = _this.get('session.currentUser.isDisabled');
        var forceReset = _this.get('session.data.authenticated.user.isPasswordTemporary');
        var secResponsesComplete = _this.get('session.data.authenticated.user.hasAuthAnswers');

        if (isDisabled) {
          return _this.transitionTo('pay-now');
        } else {
          if (typeof forceReset !== 'undefined' && forceReset) {
            return _this.transitionTo('/change-password');
          }

          if (typeof secResponsesComplete !== 'undefined' && !secResponsesComplete) {
            return _this.transitionTo('/change-security-responses');
          }
        }
      }).catch(function () {
        _this.get('session').logout();
      });
    },


    actions: {
      // Returns to the previous page of the application
      goBack: function goBack() {
        return history.back();
      },
      error: function error(err) {
        if (err instanceof _forbiddenError.default) {
          Ember.Logger.error('Access denied: ' + err.message);

          // If the user is denied access
          return this.render('forbidden-error', {
            into: 'application',
            model: {
              error: err
            }
          });
        }

        //If there is an application error
        Ember.Logger.error('Uncaught application error: ' + err.message + '\n' + ('' + (err && err.stack ? err.stack : '')));

        return this.render('application-error', {
          into: 'application',
          model: {
            error: err,
            status: err.errors ? err.errors[0].status : null,
            detail: err.errors ? err.errors[0].detail : null
          }
        });
      },


      // Access Course By Id
      goToCourse: function goToCourse(course) {
        return this.transitionTo('courses.course', course.get('id'));
      },

      // Redirect Course per Region By Id
      regionRedirect: function regionRedirect(regionId) {
        return this.transitionTo('courses', { queryParams: { RegionId: regionId } });
      },

      didTransition: function didTransition() {
        var _this2 = this;

        this._super.apply(this, arguments);

        var messageBus = this.get('messageBus');

        Ember.run.scheduleOnce('afterRender', this, function () {
          messageBus.publish('ready');
          _this2.top();
        });
      }
    }
  });
});