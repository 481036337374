define('golf-digest-panelists/ptp/index/route', ['exports', 'ember-infinity/mixins/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_route.default, {
    placesToPlayLayout: true,

    model: function model(params) {
      params.ptp = true;

      return this.infinityModel('ngf-course', params);
    },

    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('application');
        controller.set('currentlyLoading', true);
        transition.promise.finally(function () {
          controller.set('currentlyLoading', false);
        });
      },
      setlimit: function setlimit(limit) {
        this.controller.set('perPage', limit);
      },
      goToCourse: function goToCourse(course) {
        return this.transitionTo('ptp.review', course.get('id'));
      },
      selectRegion: function selectRegion(region) {
        this.controller.set('region', region);
        var that = this;
        this.controller.set('loading', false);
        return that.transitionTo({ queryParams: { RegionId: region ? region.id : null, perPage: 25 } }).then(function () {
          return that.refresh();
        }).then(function () {
          return that.controller.set('loading', true);
        });
      },
      search: function search(nameChar) {
        var char = null;
        if (nameChar) {
          char = nameChar.get('name');
        } else {
          char = '';
        }
        var that = this;
        return that.transitionTo({ queryParams: { startsWith: char, perPage: 25 } }).then(function () {
          return that.refresh();
        });
      }
    }
  });
});