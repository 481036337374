define("golf-digest-panelists/documents/new/controller", ["exports", "npm:bluebird", "npm:underscore"], function (exports, _npmBluebird, _npmUnderscore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ACCEPTED = ["application/pdf", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/html", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]; /* eslint-disable */
  exports.default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    disabledFileUpload: Ember.computed('model.title', 'model.fileData', function () {
      var title = this.get('model.title');
      var fileData = this.get('model.fileData');
      return !title || !fileData;
    }),

    actions: {
      removeDocument: function removeDocument() {
        var model = this.get("model");
        model.set("fileName", null);
        model.set("mimeType", null);
        model.set("index", null);
        model.set("isDraft", null);
        model.set("fileData", null);

        return;
      },
      selectFiles: function selectFiles(files) {
        var file = files[0];
        var model = this.get("model");
        var flash = this.get("flashMessages");

        if (_npmUnderscore.default.contains(ACCEPTED, file.type)) {
          this.set("refresh", false);

          return new _npmBluebird.default(function (resolve, reject) {
            var reader = new FileReader();

            reader.onload = function (e) {
              return resolve(e.target.result);
            };
            reader.onerror = reject;
            reader.onabort = function () {
              return reject(new Error("File read aborted"));
            };

            reader.readAsDataURL(file);
          }).then(function (data) {
            var fileName = file.name;

            model.set("index", 1);
            model.set("isDraft", false);
            model.set("fileData", data);
            model.set("fileName", fileName);
            model.set("mimeType", file.type);
          });
        } else {
          flash.danger("Document files in PDF, XLS, XLSX, DOC, DOCX, HTML only.");
        }
      },
      cancel: function cancel() {
        this.transitionToRoute("documents");
        this.get("model").rollbackAttributes();
      },
      done: function done() {
        this.transitionToRoute("documents");
      },
      submit: function submit() {
        var _this = this;

        var controller = this;
        var flash = this.get("flashMessages");
        var model = this.get("model");

        return model.save().then(function () {
          _this.set("refresh", true);
          flash.success("Document Created!");
          controller.transitionToRoute("documents");
        });
      }
    }
  });
});