define('golf-digest-panelists/components/list-contact/list-item/edit-mode/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      toggleEdit: function toggleEdit() {
        this.sendAction('toggleEdit');
      },
      changeSalutation: function changeSalutation(salutation) {
        this.sendAction('changeSalutation', salutation);
      },
      changeKinds: function changeKinds(kinds) {
        this.sendAction('changeKinds', kinds);
      },
      save: function save() {
        this.sendAction('save');
      },
      delete: function _delete() {
        this.sendAction('delete');
      },
      createTel: function createTel() {
        this.sendAction('createTel');
      },
      toggleEditTel: function toggleEditTel(tel) {
        this.sendAction('toggleEditTel', tel);
      },
      saveTel: function saveTel(tel) {
        this.sendAction('saveTel', tel);
      },
      deleteTel: function deleteTel(tel) {
        this.sendAction('deleteTel', tel);
      },
      changeTelKind: function changeTelKind(tel, kind) {
        this.sendAction('changeTelKind', tel, kind);
      },
      createAddress: function createAddress() {
        this.sendAction('createAddress');
      },
      toggleEditAddress: function toggleEditAddress(address) {
        this.sendAction('toggleEditAddress', address);
      },
      saveAddress: function saveAddress(address) {
        this.sendAction('saveAddress', address);
      },
      deleteAddress: function deleteAddress(address) {
        this.sendAction('deleteAddress', address);
      },
      changeAddressKind: function changeAddressKind(address, kind) {
        this.sendAction('changeAddressKind', address, kind);
      },
      changeAddressRegion: function changeAddressRegion(address, region) {
        this.sendAction('changeAddressRegion', address, region);
      }
    }
  });
});