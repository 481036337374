define('golf-digest-panelists/utils/filter-html', ['exports', 'npm:xss'], function (exports, _npmXss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (text) {
    var filtered = (0, _npmXss.default)(text, {
      stripIgnoreTag: true,
      whiteList: {
        a: ['href', 'title'],
        address: [],
        b: [],
        big: [],
        blockquote: [],
        br: [],
        caption: [],
        code: [],
        dd: [],
        del: [],
        div: ['class'],
        dl: [],
        dt: [],
        em: [],
        h1: [],
        h2: [],
        h3: [],
        h4: [],
        h5: [],
        h6: [],
        header: ['class'],
        hr: [],
        i: ['class'],
        img: ['src', 'width', 'height', 'alt', 'title'],
        kbd: [],
        li: [],
        ol: [],
        p: [],
        pre: [],
        s: [],
        section: ['class'],
        small: [],
        strike: [],
        strong: [],
        sub: [],
        sup: [],
        table: ['border', 'cellpadding', 'cellspacing'],
        tbody: [],
        td: ['colspan', 'rowspan'],
        th: [],
        time: ['datetime'],
        thead: [],
        tr: [],
        u: [],
        ul: ['class']
      },

      // Do permit tables to be .table .table-bordered
      onIgnoreTagAttr: function onIgnoreTagAttr(tag, name, value) {
        if (tag === 'table' && name === 'class' && (value === 'table table-bordered' || value === 'table-bordered table' || value === 'table')) {

          return name + '="' + value + '"';
        }
      }
    });

    return Ember.String.htmlSafe(filtered);
  };
});