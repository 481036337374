define('golf-digest-panelists/facilities/index/controller', ['exports', 'golf-digest-panelists/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    queryParams: ['page', 'perPage', 'query', 'RegionId', 'CountryId'],

    totalPages: Ember.computed.alias('content.totalPages'),
    worldLayout: !!_environment.default.brand && _environment.default.brand === 'Golf Digest Panelists World',

    page: 1,
    perPage: 25,
    RegionId: null,
    CountryId: null,
    query: null,

    filteredFacilities: Ember.computed('query', 'model', function () {
      var query = this.get('query');
      var facilities = this.get('model');

      if (query) {
        return facilities.filterBy('query', query);
      }

      return facilities;
    }),

    actions: {
      pageClicked: function pageClicked(page) {
        this.setProperties({ page: page });
      },
      changeRegion: function changeRegion(region) {
        if (region) {
          this.set('region', region);
          this.set('RegionId', region.get('id'));
        } else {
          this.set('region', null);
          this.set('RegionId', null);
        }
      },
      changeCountry: function changeCountry(country) {
        if (country) {
          this.set('country', country);
          this.set('CountryId', country.get('id'));
          this.set("region", null);
          this.set("RegionId", null);
        } else {
          this.set('country', null);
          this.set("region", null);
          this.set("RegionId", null);
          this.set("CountryId", null);
        }
      },
      changeRegionCountry: function changeRegionCountry(region) {
        if (region) {
          this.set('region', region);
          this.set('RegionId', region.get('id'));
        } else {
          this.set('region', null);
          this.set('RegionId', null);
        }
      },
      searchFacility: function searchFacility() {
        var query = this.get('query');
        var region = this.get('region');
        var country = this.get('country');

        this.set('query', query);

        if (region) {
          this.set('RegionId', region.id);
        } else {
          this.set('RegionId', null);
        }

        if (country) {
          this.set('CountryId', country.id);
        } else {
          this.set('CountryId', null);
        }

        this.send('refreshSearch');
      }
    }
  });
});