define("golf-digest-panelists/components/evaluation-editor/course-select/region-country-select/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service("store"),
    regions: [],

    currentCountryObservable: Ember.observer("currentCountry", function () {
      this._super.apply(this, arguments);
      this.send(["handleChangeRegionByCountry"]);
    }),

    internalValue: Ember.computed("value", function () {
      var that = this;

      if (that.get("value")) {
        var regions = that.get("regions");
        var targetId = that.get("value").get("id");

        // Retrieves region selected
        return regions.find(function (region) {
          return region.get("id") === targetId;
        });
      }

      return undefined;
    }),

    init: function init() {
      var that = this;

      this._super.apply(this, arguments);

      var currentCountry = that.get("currentCountry");
      if (!currentCountry) return;
      var currentCountryId = currentCountry.get("id");
      if (!currentCountryId) return;

      return that.get("store").query("region", {
        CountryId: currentCountryId
      }).then(function (regions) {
        return that.set("regions", regions);
      }).then(function () {
        return that.notifyPropertyChange("value");
      });
    },


    actions: {
      /**
       * Function Change Value Region for param
       * @param {*} region
       */
      changeValue: function changeValue(region) {
        var _this = this;

        if (region) {
          // Find and return Record Region By ID
          return this.get("store").findRecord("region", region.get("id")).then(function (realRegion) {
            return _this.get("onChange")(realRegion);
          });
        }

        this.get("onChange")(null);
      },
      handleChangeRegionByCountry: function handleChangeRegionByCountry() {
        if (!this) return;
        var that = this;
        that._super.apply(that, arguments);

        that.set("regions", []);

        var currentCountry = that.get("currentCountry");
        if (!currentCountry) return;
        var currentCountryId = currentCountry.get("id");
        if (!currentCountryId) return;

        that.get("store").query("region", {
          CountryId: currentCountryId
        }).then(function (regions) {
          that.set("regions", regions);
        }).then(function () {
          return that.notifyPropertyChange("value");
        });
      }
    }
  });
});