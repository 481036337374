define('golf-digest-panelists/ptp/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    placesToPlayLayout: true,

    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),

    beforeModel: function beforeModel() {
      if (this.get('cap').canAccessPtp) {
        return true;
      }

      return this.transitionTo('forbidden-error');
    }
  });
});