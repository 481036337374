define('golf-digest-panelists/components/discussion-replies/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      triggerEdit: function triggerEdit() {
        this.get('onEdit').apply(undefined, arguments);
      },
      triggerDelete: function triggerDelete() {
        this.get('onDelete').apply(undefined, arguments);
      },
      triggerCancel: function triggerCancel() {
        this.get('onCancel').apply(undefined, arguments);
      },
      triggerAddImage: function triggerAddImage() {
        this.get('onAddImage').apply(undefined, arguments);
      },
      triggerDeleteImage: function triggerDeleteImage() {
        this.get('onDeleteImage').apply(undefined, arguments);
      },
      triggerSubmit: function triggerSubmit() {
        this.get('onSubmit').apply(undefined, arguments);
      }
    }
  });
});