define('golf-digest-panelists/components/discussion-post-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      triggerCancel: function triggerCancel() {
        this.get('onCancel')(this.get('post'));
      },
      triggerSubmit: function triggerSubmit() {
        this.get('onSubmit')(this.get('post'));
      },
      triggerAddImage: function triggerAddImage(file) {
        this.get('onAddImage')(this.get('post'), file);
      },
      triggerDeleteImage: function triggerDeleteImage(file) {
        this.get('onDeleteImage')(this.get('post'), file);
      }
    }
  });
});