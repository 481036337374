define('golf-digest-panelists/models/survey', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-data', 'moment', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberData, _moment, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    dateExperiencedBegin: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    dateExperiencedEnd: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    tabulationDate: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    publicationDate: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  exports.default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    dateExperiencedBegin: (0, _attr.default)('utc'),
    dateExperiencedEnd: (0, _attr.default)('utc'),
    tabulationDate: (0, _attr.default)('utc'),
    publicationDate: (0, _attr.default)('utc'),
    createdByUser: (0, _relationships.belongsTo)('user', { readOnly: true }),
    createdAt: (0, _attr.default)('utc', { readOnly: true }),
    updatedByUser: (0, _relationships.belongsTo)('user', { readOnly: true }),
    updatedAt: (0, _attr.default)('utc', { readOnly: true }),
    courses: _emberData.default.hasMany('course'),
    dateExpBegin: function () {
      return (0, _moment.default)(this.get('dateExperiencedBegin')).format('YYYY-MM-DD');
    }.property('dateExperiencedBegin'),
    dateExpEnd: function () {
      return (0, _moment.default)(this.get('dateExperiencedEnd')).format('YYYY-MM-DD');
    }.property('dateExperiencedEnd'),
    tabDate: function () {
      return (0, _moment.default)(this.get('tabulationDate')).format('YYYY-MM-DD');
    }.property('tabulationDate'),
    publicatDate: function () {
      return (0, _moment.default)(this.get('publicationDate')).format('YYYY-MM-DD');
    }.property('publicationDate'),
    evaluationsAreAssigned: (0, _attr.default)('boolean')
  });
});