define('golf-digest-panelists/faqs/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() /* transition */{
            var _this = this;

            this.get('store').findAll('faq').then(function (faqs) {
                var newFaqArray = faqs.filter(function (faq) {
                    return faq.get('id') !== null;
                });

                if (newFaqArray.length > 0) {
                    _this.transitionTo('faqs.faq', newFaqArray[0].get('id'));
                } else {
                    _this.transitionTo('faqs.new');
                }
            });
        }
    });
});