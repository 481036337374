define('golf-digest-panelists/components/email-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['editing'],
    editing: false,
    store: Ember.inject.service('store'),
    flashMessages: Ember.inject.service(),

    lastValue: null,

    actions: {
      editEmail: function editEmail() {
        if (this.get('lastValue')) {
          this.set('userEmail.email', this.get('lastValue'));
          this.set('lastValue', null);
        } else {
          this.set('lastValue', this.get('userEmail.email'));
        }

        this.toggleProperty('editing');
      },
      deleteEmail: function deleteEmail() {
        var email = this.get('userEmail');
        var isPrimary = email.get('isPrimary');
        var flash = this.get('flashMessages');
        if (isPrimary) {
          flash.danger('Cannot delete primary email!');
          return;
        }
        this.get('store').find('user-email', email.get('id')).then(function (email) {
          return email.destroyRecord();
        }).then(function () {
          return flash.success('Email Deleted!');
        });
      },
      submitEmail: function submitEmail() {
        this.set('editing', false);
      },
      triggerIsPrimary: function triggerIsPrimary() {
        var userEmail = this.get('userEmail');
        var model = this.get('model');
        var flash = this.get('flashMessages');

        model.get('emails').then(function (emails) {
          if (emails) {
            var l = emails.get('length');

            emails.forEach(function (email) {
              var is = l === 1 || email.get('email') === userEmail.get('email');
              email.set('isPrimary', is);

              // TODO: Should this be saved? There's a "Save Changes" btn on page
              email.save().then(function (email) {
                if (email.get('isPrimary')) {
                  flash.success(email.get('email') + ' is set as the Primary email address');
                }
              });
            });
          }
        });
      }
    }
  });
});