define('golf-digest-panelists/models/ngf-facility-address', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    ngfFacility: _emberData.default.belongsTo('ngf-facility'),
    index: _emberData.default.attr('number'),
    organizationalAddressKind: _emberData.default.belongsTo('organizational-address-kind'),
    street1: _emberData.default.attr('string'),
    street2: _emberData.default.attr('string'),
    street3: _emberData.default.attr('string'),
    locality: _emberData.default.attr('string'),
    county: _emberData.default.attr('string'),
    region: _emberData.default.belongsTo('region'),
    postalCode: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    kind: Ember.computed.alias('organizationalAddressKind')
  });
});