define('golf-digest-panelists/components/discussion-post-viewer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),
    currentUserId: Ember.computed.alias('session.data.currentUser.id'),

    isAllowedToEdit: Ember.computed('cap', 'post', 'post.createdBy', 'post.createdBy.id', 'currentUserId', function () {
      if (this) {
        try {
          var cap = this.get('cap');

          if (cap && (cap.canUpdateAllDiscussions || cap.canDeleteAllDiscussions)) {

            return true;
          }
        } catch (err) {
          Ember.Logger.warn('Unable to retrieve capabilities: ' + (err.message + '\n' + (err && err.stack ? err.stack : '')));

          // Ignore. We still want to see if this is the original user.
        }

        try {
          var currentUserId = this.get('currentUserId');

          try {
            var post = this.get('post');

            if (post && post.get('createdBy') && post.get('createdBy.id') && currentUserId) {

              return post.get('createdBy.id') === currentUserId;
            }
          } catch (err) {
            Ember.Logger.warn('Unable to retrieve post: ' + err.message + '\n' + ('' + (err && err.stack ? err.stack : '')));

            return false;
          }
        } catch (err) {
          Ember.Logger.warn('Unable to retrieve current user ID: ' + err.message + '\n' + ('' + (err && err.stack ? err.stack : '')));

          return false;
        }
      }

      return false;
    }),

    actions: {
      triggerEdit: function triggerEdit() {
        var func = this.get('onEdit');

        try {
          var post = this.get('post');

          return func(post);
        } catch (err) {
          Ember.Logger.warn('Unable to retrieve post: ' + err.message + '\n' + ('' + (err && err.stack ? err.stack : '')));

          return func(null);
        }
      },
      triggerDelete: function triggerDelete() {
        var func = this.get('onDelete');

        try {
          var post = this.get('post');

          return func(post);
        } catch (err) {
          Ember.Logger.warn('Unable to retrieve post: ' + err.message + '\n' + ('' + (err && err.stack ? err.stack : '')));

          return func(null);
        }
      }
    }
  });
});