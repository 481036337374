define('golf-digest-panelists/user-report/route', ['exports', 'ember-cli-pagination/remote/route-mixin'], function (exports, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,

    model: function model(params) {
      return this.findPaged('user', params);
    },


    queryParams: {
      query: {
        refreshModel: true
      },
      emailSearch: {
        refreshModel: true
      },
      RegionId: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      CountryId: {
        refreshModel: true
      }
    }
  });
});