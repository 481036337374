define('golf-digest-panelists/components/evaluation-editor/date-experienced/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showError: false,
    todayDate: (0, _moment.default)().format("DD MMM YYYY"),

    actions: {
      change: function change() {
        var div = Ember.$('#div');
        var dtp = Ember.$('#datetimepicker');
        var that = this;

        Ember.$('#datetimepicker').datetimepicker({
          widgetParent: div,
          format: 'MM/dd/YYYY',
          minDate: (0, _moment.default)('1950-01-01'),
          maxDate: (0, _moment.default)().add('days', 1),
          disabledDates: [(0, _moment.default)().add('days', 1)],
          showClose: true,
          toolbarPlacement: 'top'
        });

        var dateTimePicker = Ember.$('#datetimepicker').data('DateTimePicker');

        dtp.on('dp.change', function () {
          if (dateTimePicker.date()) {
            that.set('value', dateTimePicker.date());
          }
          dateTimePicker.hide();
        });

        dateTimePicker.toggle();
      }
    }
  });
});