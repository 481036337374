define('golf-digest-panelists/components/ptp-course-select/region-select/component', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    regions: [],

    internalValue: Ember.computed('value', function () {
      var that = this;

      if (that.get('value')) {
        var groups = that.get('regions');
        var allRegions = [];
        var targId = that.get('value').get('id');

        groups.forEach(function (g) {
          return Array.prototype.push.apply(allRegions, g.options);
        });

        return _npmUnderscore.default.find(allRegions, function (r) {
          return r.get('id') === targId;
        });
      }

      return undefined;
    }),

    init: function init() {
      var that = this;

      that._super.apply(that, arguments);

      var candidatesOnly = true;

      if (this.get('allRegions') === true) {
        candidatesOnly = false;
      }

      return that.get('store').query('region', candidatesOnly ? { hasCandidates: true } : {}).then(function (regions) {
        var groupedRegions = [];

        regions.forEach(function (r) {
          var country = r.get('country').get('name');
          var group = _npmUnderscore.default.find(groupedRegions, function (g) {
            return g.groupName === country;
          });

          if (_npmUnderscore.default.isUndefined(group)) {
            group = { groupName: country, options: [] };
            groupedRegions.push(group);
          }

          group.options.push(r);
        });
        groupedRegions = groupedRegions.reverse();
        return that.set('regions', groupedRegions);
      }).then(function () {
        return that.notifyPropertyChange('value');
      });
    },


    actions: {
      changeValue: function changeValue(region) {
        var _this = this;

        if (region) {

          // Find and return Record Region By ID
          return this.get('store').findRecord('region', region.get('id')).then(function (realRegion) {
            return _this.get('onChange')(realRegion);
          });
        }

        this.get('onChange')(null);
      }
    }
  });
});