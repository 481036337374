define('golf-digest-panelists/components/review-viewer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scroller: Ember.inject.service(),
    showModal: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var scrollTo = this.get('scrollTo');

      if (scrollTo === true || scrollTo === 'true') {
        var scroller = this.get('scroller');

        scroller.scrollVertical(this.$());
      }
    },


    actions: {
      toggleEdit: function toggleEdit() {
        var review = this.get('review');

        review.set('isNew', false);
        this.get('onCancel')(review);
      },
      deleteReview: function deleteReview(review) {
        this.get('onDelete')(review);
      },
      togglePopUp: function togglePopUp() {
        this.toggleProperty('showModal');
      }
    }
  });
});