define('golf-digest-panelists/transforms/string', ['exports', 'ember-data', 'npm:underscore'], function (exports, _emberData, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.StringTransform.extend({
    serialize: function serialize(deserialized) {
      return Ember.isNone(deserialized) || _npmUnderscore.default.isEmpty(deserialized) ? null : String(deserialized);
    }
  });
});