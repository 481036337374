define('golf-digest-panelists/services/session', ['exports', 'ember-simple-auth/services/session', 'golf-digest-panelists/utils/capabilities', 'npm:bluebird', 'golf-digest-panelists/config/environment'], function (exports, _session, _capabilities, _npmBluebird, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({
    _setUsername: function _setUsername(username) {
      if (!this.get('data')) {
        this.set('data', {});
      }

      if (username) {
        this.set('data.userId', username);
      } else {
        this.set('data.userId', '');
      }
    },
    _setProperties: function _setProperties(userObj) {
      if (!this.get('data')) {
        this.set('data', {
          currentUser: null,
          currentUserId: null,
          cap: {}
        });
      }

      if (userObj) {
        this.set('currentUser', userObj);
        this.set('data.currentUser', userObj);
        this.set('data.currentUserId', userObj.id);
        this.set('data.cap', (0, _capabilities.default)(userObj));
      } else {
        this.set('currentUser', '');
        this.set('data.currentUser', '');
        this.set('data.currentUserId', '');
        this.set('data.cap', {});
      }
    },
    authenticate: function authenticate(authenticator, username) {
      this._setUsername(username);
      return this._super.apply(this, arguments);
    },
    logout: function logout() {
      var session = this;

      if (session.get('isAuthenticated') === true) {
        return session.invalidate().then(function () {
          session._setUsername(null);
          session._setProperties(null);
        }).catch(function (err) {
          Ember.Logger.error('Failed to log out: ' + (err.message + '\n' + (err && err.stack ? err.stack : '')));

          var brandTitle = _environment.default.brand || 'Golf Digest Panelists';

          alert('We\'re sorry, but ' + brandTitle + ' encountered an error ' + 'while logging out. Please try again. If you are still unable to ' + 'log out, please close and reopen your web browser.\n\n' + err);
        });
      }

      return _npmBluebird.default.resolve();
    },
    loadCurrentUser: function loadCurrentUser() {
      var session = this;

      return new _npmBluebird.default(function (resolve, reject) {
        if (session.get('isAuthenticated')) {
          var user = session.get('data.authenticated.user');

          if (user) {
            session._setProperties(user);

            Ember.Logger.debug('Current user loaded successfully');
            return resolve();
          }

          Ember.Logger.debug('User object is invalid!');
          return reject(new Error('Cannot load user details'));
        }

        Ember.Logger.debug('Not authenticated');
        return resolve();
      });
    }
  });
});