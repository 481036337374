define('golf-digest-panelists/ngf-courses/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['perPage', 'RegionId', 'query', 'startsWith'],
    flashMessages: Ember.inject.service(),
    totalPages: Ember.computed.alias('content.totalPages'),

    session: Ember.inject.service(),
    page: 1,
    perPage: 25,
    query: null,

    cap: Ember.computed.alias('session.data.cap'),
    userId: Ember.computed.alias('session.data.currentUser.id'),

    linkValue: function linkValue(course, ngfCourse) {
      var _this = this;

      var model = this.get('model');
      var promises = Ember.A();
      var that = this;

      // Function get NGF Facility and find Record By ID
      this.get('store').findRecord('ngf-facility', ngfCourse.get('ngfFacilityId')).then(function (facility) {
        course.set('name', ngfCourse.get('name'));
        course.set('ngfCourseId', ngfCourse.get('id'));
        course.set('ngfFacility', facility);
        course.set('courseKindCode', ngfCourse.get('courseKind'));
        course.set('courseStatusCode', ngfCourse.get('courseStatus'));
        course.set('websiteUrl', ngfCourse.get('websiteUrl'));
        course.set('holeCount', ngfCourse.get('holeCount'));
        course.set('designerFullName', ngfCourse.get('designerFullName'));
        course.set('dateOpened', ngfCourse.get('dateOpened'));

        promises.push(course.save());
      });

      // Function Get All NGF Course Address, in each Get Store by NGF Course Address Region
      ngfCourse.get('ngfCourseAddresses').forEach(function (ngfAddr) {
        _this.get('store').query('region', { abbreviation: ngfAddr.get('region') }).then(function (regions) {
          regions.forEach(function (region) {
            that.get('store').query('course-address', { courseId: course.id, organizationalAddressKindId: ngfAddr.get('organizationalAddressKind').get('id') }).then(function (courseAddresses) {
              if (courseAddresses.content.length === 0) {
                var newAddress = that.get('store').createRecord('course-address', {
                  course: course,
                  index: 0,
                  organizationalAddressKind: ngfAddr.get('organizationalAddressKind'),
                  street1: ngfAddr.get('street1'),
                  street2: ngfAddr.get('street2'),
                  street3: ngfAddr.get('street3'),
                  locality: ngfAddr.get('locality'),
                  county: ngfAddr.get('county'),
                  region: region,
                  postalCode: ngfAddr.get('postalCode'),
                  updatedAt: null
                });

                promises.push(newAddress.save());
              } else {
                courseAddresses.forEach(function (addr) {
                  addr.set('index', addr.get('index') + 1);
                  addr.set('street1', ngfAddr.get('street1'));
                  addr.set('street2', ngfAddr.get('street2'));
                  addr.set('street3', ngfAddr.get('street3'));
                  addr.set('locality', ngfAddr.get('locality'));
                  addr.set('county', ngfAddr.get('county'));
                  addr.set('region', region);
                  addr.set('postalCode', ngfAddr.get('postalCode'));

                  promises.push(addr.save());
                });
              }
            });
          });
        });
      });

      // Function Get All NGF Course Tel, in each Get Store By Course Tel 
      ngfCourse.get('ngfCourseTels').forEach(function (ngftel) {
        that.get('store').query('course-tel', { courseId: course.id, organizationalTelKindId: ngftel.get('organizationalTelKind').get('id') }).then(function (courseTels) {
          if (ngftel.get('tel')) {
            if (courseTels.content.length === 0) {
              var newTel = that.get('store').createRecord('course-tel', {
                course: course,
                index: 0,
                organizationalTelKind: ngftel.get('organizationalTelKind'),
                tel: ngftel.get('tel')
              });

              promises.push(newTel.save());
            } else {
              courseTels.forEach(function (tel) {
                tel.set('index', tel.get('index') + 1);
                tel.set('tel', ngftel.get('tel'));

                promises.push(tel.save());
              });
            }
          }
        });
      });

      // Function Get All NGF Tee, in each Get Store Tee
      this.get('store').query('ngf-tee', { ngfCourseId: ngfCourse.id }).then(function (ngfTees) {
        ngfTees.forEach(function (ngfTee) {
          that.get('store').query('tee', { courseId: course.id, name: ngfTee.get('name') }).then(function (courseTees) {
            if (courseTees.content.length === 0) {
              var newTee = that.get('store').createRecord('tee', {
                course: course,
                index: 0,
                name: ngfTee.get('name'),
                length: ngfTee.get('length'),
                par: ngfTee.get('par'),
                slope: ngfTee.get('slope'),
                rating: ngfTee.get('rating')
              });

              promises.push(newTee.save());
            } else {
              courseTees.forEach(function (tee) {
                tee.set('index', tee.get('index') + 1);
                tee.set('length', tee.get('length'));
                tee.set('par', tee.get('par'));
                tee.set('slope', tee.get('slope'));
                tee.set('rating', tee.get('rating'));

                promises.push(tee.save());
              });
            }
          });
        });
      });

      return Ember.RSVP.Promise.all(promises).then(function () {
        ngfCourse.set('matched', true);
        ngfCourse.save().then(function () {
          model.get('content').removeObject(ngfCourse);
          ngfCourse.unloadRecord();
        });
      });
    },


    actions: {
      pageClicked: function pageClicked(page) {
        this.set('page', page);
      },
      showIgnoredCourses: function showIgnoredCourses() {
        return this.transitionToRoute('ignored-courses');
      },
      link: function link(course, ngfCourse) {
        var flash = this.get('flashMessages');

        // Function Get Store and Find Record NGF Facility By ID
        this.get('store').findRecord('ngf-facility', ngfCourse.get('ngfFacilityId')).then(function (facility) {
          course.set('ngfCourseId', ngfCourse.get('id'));
          course.set('ngfFacility', facility);

          course.save().then(function () {
            ngfCourse.set('linked', true);
            ngfCourse.save().then(function () {
              flash.success('Course Linked!');
            });
          });
        });
      },
      unlink: function unlink(ngfCourse) {
        var flash = this.get('flashMessages');

        // Function Get Store and Query Coruse By ID
        this.get('store').query('course', { ngfCourseId: ngfCourse.get('id'), ngfFacilityId: ngfCourse.get('ngfFacilityId') }).then(function (result) {
          result.forEach(function (course) {
            course.set('ngfCourseId', null);
            course.set('ngfFacility', null);

            course.save().then(function () {
              ngfCourse.set('linked', false);
              ngfCourse.save().then(function () {
                flash.success('Course Unlinked!');
              });
            });
          });
        });
      },
      ignoreCourse: function ignoreCourse(course) {
        var flash = this.get('flashMessages');
        var model = this.get('model');
        var courseAddrs = course.get('ngfCourseAddresses');
        var courseTels = course.get('ngfCourseTels');
        var courseTees = course.get('ngfTees');

        var controller = this;

        var ignoredCourse = controller.get('store').createRecord('ignored-course', {
          facilityName: course.get('facilityName'),
          ngfFacilityId: course.get('ngfFacilityId'),
          ngfCourseId: course.get('id'),
          name: course.get('name'),
          websiteUrl: course.get('websiteUrl'),
          holeCount: course.get('holeCount'),
          courseKind: course.get('courseKind'),
          courseStatus: course.get('courseStatus'),
          designerFullName: course.get('designerFullName'),
          dateOpened: course.get('dateOpened')
        });
        ignoredCourse.save().then(function (ignoredCourse) {

          var promises = Ember.A();

          courseTels.forEach(function (tel) {
            if (tel.get('tel')) {
              var newTel = controller.get('store').createRecord('ignored-course-tel', {
                ignoredCourse: ignoredCourse,
                index: 0,
                organizationalTelKind: tel.get('organizationalTelKind'),
                tel: tel.get('tel')
              });
              promises.push(newTel.save());
            }
          });

          courseAddrs.forEach(function (addr) {
            var newAddress = controller.get('store').createRecord('ignored-course-address', {
              ignoredCourse: ignoredCourse,
              index: 0,
              organizationalAddressKind: addr.get('organizationalAddressKind'),
              street1: addr.get('street1'),
              street2: addr.get('street2'),
              street3: addr.get('street3'),
              locality: addr.get('locality'),
              county: addr.get('county'),
              region: addr.get('region'),
              postalCode: addr.get('postalCode')
            });
            promises.push(newAddress.save());
          });

          courseTees.forEach(function (tee) {
            var newTee = controller.get('store').createRecord('ignored-tee', {
              ignoredCourse: ignoredCourse,
              index: 0,
              name: tee.get('name'),
              length: tee.get('length'),
              par: tee.get('par'),
              slope: tee.get('slope'),
              rating: tee.get('rating'),
              updatedAt: null
            });
            promises.push(newTee.save());
          });

          return Ember.RSVP.Promise.all(promises).then(function () {
            course.destroyRecord().then(function () {
              flash.success('Course Ignored!');
              model.get('content').removeObject(course);
              course.unloadRecord();
            });
          });
        });
      },
      confirmLinks: function confirmLinks() {
        var that = this;

        // Function Get Store and Query Course, in each Get Store By Course By ID
        this.get('store').query('ngf-course', { linked: true }).then(function (ngfCourses) {
          ngfCourses.forEach(function (ngfCourse) {
            that.get('store').query('course', { ngfCourseId: ngfCourse.get('ngfCourseId'), ngfFacilityId: ngfCourse.get('ngfFacilityId') }).then(function (courses) {
              courses.forEach(function (course) {
                that.linkValue(course, ngfCourse);
              });
            });
          });
        });
      }
    }
  });
});