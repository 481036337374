define('golf-digest-panelists/initializers/main', ['exports', 'npm:starts-with'], function (exports, _npmStartsWith) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    if (Ember.Debug && typeof Ember.Debug.registerDeprecationHandler === 'function') {

      Ember.Debug.registerDeprecationHandler(function (message, options, next) {
        if (options && options.until && (0, _npmStartsWith.default)(options.until, '3.')) {
          // We aren't concerned yet about things being turned off in 3.x. Shaddup
          return;
        }

        // I know that using storageKey is deprecated. Shaddup.
        if (message.indexOf('storageKey') !== -1) {
          return;
        }

        return next(message, options);
      });
    }
  }

  exports.default = {
    name: 'main',
    initialize: initialize
  };
});