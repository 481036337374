define('golf-digest-panelists/helpers/is-modulo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isModulo = isModulo;
  function isModulo(params) {
    var dividend = params[0];
    var divisor = params[1];
    var modulo = params[2];

    return dividend % divisor === modulo;
  }

  exports.default = Ember.Helper.helper(isModulo);
});