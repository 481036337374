define('golf-digest-panelists/helpers/is-even', ['exports', 'npm:is-numeric'], function (exports, _npmIsNumeric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEven = isEven;
  function isEven(params /*, hash*/) {
    var value = params[0];

    if ((0, _npmIsNumeric.default)(value)) {
      return value % 2 === 0;
    }

    return false;
  }

  exports.default = Ember.Helper.helper(isEven);
});