define('golf-digest-panelists/facilities/facility/controller', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    busy: Ember.inject.service(),

    store: Ember.inject.service(),
    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),
    userId: Ember.computed.alias('session.data.currentUser.id'),

    organizationalAddressKinds: Ember.A(),
    organizationalTelKinds: Ember.A(),

    facilityName: null,
    websiteUrl: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      return _npmBluebird.default.all([this.get('store').findAll('organizational-address-kind').then(function (kinds) {
        return _this.set('organizationalAddressKinds', kinds);
      }), this.get('store').findAll('organizational-tel-kind').then(function (kinds) {
        return _this.set('organizationalTelKinds', kinds);
      })]);
    },


    actions: {
      toggleWebsiteUrlEdit: function toggleWebsiteUrlEdit() {
        setTimeout(function () {
          Ember.$('#website-url .ember-inline-edit .ember-inline-edit-toggle-btn').focus().click();
        }, 50);
      },
      beginChangeWebsiteUrl: function beginChangeWebsiteUrl() {
        this.set('websiteUrl', this.get('model.websiteUrl'));
      },
      changeWebsiteUrl: function changeWebsiteUrl() {
        this.set('websiteUrl', this.get('model.websiteUrl'));

        return this.get('model').save();
      },
      cancelChangeWebsiteUrl: function cancelChangeWebsiteUrl() {
        this.set('model.websiteUrl', this.get('websiteUrl'));
      },
      toggleFacilityNameEdit: function toggleFacilityNameEdit() {
        setTimeout(function () {
          Ember.$('#facility-name .ember-inline-edit span').focus().click();
        }, 50);
      },
      beginChangeFacilityName: function beginChangeFacilityName() {
        this.set('facilityName', this.get('model.name'));
      },
      changeFacilityName: function changeFacilityName() {
        this.set('facilityName', this.get('model.name'));

        return this.get('model').save();
      },
      cancelChangeFacilityName: function cancelChangeFacilityName() {
        this.set('model.name', this.get('facilityName'));
      },
      deleteFacility: function deleteFacility(facility) {
        var flash = this.get('flashMessages');
        var that = this;

        var busy = this.get('busy');
        busy.show({ message: 'Deleting Facility...' });

        return facility.destroyRecord().then(function () {
          return that.transitionToRoute('facilities.index').then(function () {
            busy.hide();
            return flash.success('Facility Deleted!!');
          });
        });
      },
      createTel: function createTel() {
        var _this2 = this;

        var controller = this;
        var model = this.get('model');
        var index = -1;

        model.get('facilityTels').then(function (tels) {
          if (tels) {
            tels.forEach(function (telephone) {
              var cIndex = parseInt(telephone.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          // Function get Store and Find Organization Tel Kind by ID
          return _this2.get('store').find('organizational-tel-kind', 1).then(function (organizationalTelKind) {
            var newTel = controller.get('store').createRecord('facility-tel', {
              facility: model,
              index: index + 1,
              organizationalTelKind: organizationalTelKind
            });
            newTel.set('editing', true);
            model.get('facilityTels').pushObject(newTel);
          });
        });
      },
      toggleEditTel: function toggleEditTel(tel) {
        if (tel.get('isNew')) {
          tel.destroy();
        } else {
          if (tel.get('editing') === true) {
            tel.set('editing', false);
            tel.rollbackAttributes();
          } else {
            tel.set('editing', true);
          }
        }
      },
      saveTel: function saveTel(tel) {
        return tel.save().then(function () {
          return tel.set('editing', false);
        }).then(function () {
          return tel.reload();
        });
      },
      deleteTel: function deleteTel(tel) {
        var model = this.get('model');

        return tel.destroyRecord().then(function () {
          return model.get('facilityTels').removeObject(tel);
        });
      },
      changeTelKind: function changeTelKind(tel, kind) {
        tel.set('kind', kind);
      },
      createAddress: function createAddress() {
        var _this3 = this;

        var controller = this;
        var model = this.get('model');
        var index = -1;

        model.get('facilityAddresses').then(function (addresses) {
          if (addresses) {
            addresses.forEach(function (address) {
              var cIndex = parseInt(address.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          // Function get Store and Find Region by ID
          return _this3.get('store').find('region', 1).then(function (region) {
            return _this3.get('store').find('organizational-address-kind', 1).then(function (organizationalAddressKind) {
              var newAddress = controller.get('store').createRecord('facility-address', {
                facility: model,
                index: index + 1,
                organizationalAddressKind: organizationalAddressKind,
                region: region
              });
              newAddress.set('editing', true);
              model.get('facilityAddresses').pushObject(newAddress);
            });
          });
        });
      },
      toggleEditAddress: function toggleEditAddress(address) {
        if (address.get('isNew')) {
          address.destroy();
        } else {
          if (address.get('editing') === true) {
            address.set('editing', false);
            address.rollbackAttributes();
          } else {
            address.set('editing', true);
          }
        }
      },
      saveAddress: function saveAddress(address) {
        return address.save().then(function () {
          return address.set('editing', false);
        }).then(function () {
          return address.reload();
        });
      },
      deleteAddress: function deleteAddress(address) {
        var model = this.get('model');

        return address.destroyRecord().then(function () {
          return model.get('facilityAddresses').removeObject(address);
        });
      },
      changeAddressKind: function changeAddressKind(address, kind) {
        address.set('kind', kind);
      },
      changeAddressRegion: function changeAddressRegion(address, region) {
        address.set('region', region);
      },
      submit: function submit() {
        var controller = this;
        var model = this.get('model');

        return controller.saveModel(model);
      }
    }
  });
});