define('golf-digest-panelists/courses/new/controller', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['FacilityId'],

    organizationalAddressKinds: [],
    organizationalTelKinds: [],

    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),
    userId: Ember.computed.alias('session.data.currentUser.id'),

    facility: function () {
      // Find and Record Facility By ID
      if (this.get('FacilityId')) {
        return this.store.findRecord('facility', this.get('FacilityId'));
      }

      return null;
    }.property('FacilityId'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      return _npmBluebird.default.all([
      // Function get Store and Find All Organizational Address Kind By params
      this.get('store').findAll('organizational-address-kind').then(function (kinds) {
        return _this.set('organizationalAddressKinds', kinds);
      }),
      // Function get Store and Find All Organizational Tel Kind By params
      this.get('store').findAll('organizational-tel-kind').then(function (kinds) {
        return _this.set('organizationalTelKinds', kinds);
      })]);
    },


    actions: {
      toggleActivelySeekingPanelists: function toggleActivelySeekingPanelists() {
        var model = this.get('model');

        model.toggleProperty('isActivelySeekingPanelists');
      },
      createTel: function createTel() {
        var _this2 = this;

        var controller = this;
        var model = this.get('model');
        var index = -1;

        model.get('courseTels').then(function (tels) {
          if (tels) {
            tels.forEach(function (telephone) {
              var cIndex = parseInt(telephone.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          // Function get Store and Find Organization Tel Kind By ID
          return _this2.get('store').find('organizational-tel-kind', 1).then(function (organizationalTelKind) {
            var newTel = controller.get('store').createRecord('course-tel', {
              course: model,
              index: index + 1,
              organizationalTelKind: organizationalTelKind
            });
            newTel.set('editing', true);
            model.get('courseTels').pushObject(newTel);
          });
        });
      },


      /**
       * Function toggle Edit Tel by param
       * @param {*} tel 
       */
      toggleEditTel: function toggleEditTel(tel) {
        if (tel.get('isNew')) {
          tel.destroy();
        } else {
          if (tel.get('editing') === true) {
            tel.set('editing', false);
            tel.rollbackAttributes();
          } else {
            tel.set('editing', true);
          }
        }
      },
      saveTel: function saveTel(tel) {
        tel.set('editing', false);
      },
      deleteTel: function deleteTel(tel) {
        var model = this.get('model');

        return tel.destroyRecord().then(function () {
          return model.get('courseTels').removeObject(tel);
        });
      },
      changeTelKind: function changeTelKind(tel, kind) {
        tel.set('kind', kind);
      },
      createAddress: function createAddress() {
        var _this3 = this;

        var controller = this;
        var model = this.get('model');
        var index = -1;

        model.get('courseAddresses').then(function (addresses) {
          if (addresses) {
            addresses.forEach(function (address) {
              var cIndex = parseInt(address.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          // Function get Store and Find Region By ID
          return _this3.get('store').find('region', 1).then(function (region) {
            return _this3.get('store')

            // Find Record Organizational Address Kind By ID
            .find('organizational-address-kind', 1).then(function (organizationalAddressKind) {
              var newAddress = controller.get('store').createRecord('course-address', {
                course: model,
                index: index + 1,
                organizationalAddressKind: organizationalAddressKind,
                region: region
              });
              newAddress.set('editing', true);
              model.get('courseAddresses').pushObject(newAddress);
            });
          });
        });
      },


      /**
       * Function toggle Edit Address by param
       * @param {*} address 
       */
      toggleEditAddress: function toggleEditAddress(address) {
        if (address.get('isNew')) {
          address.destroy();
        } else {
          if (address.get('editing') === true) {
            address.set('editing', false);
            address.rollbackAttributes();
          } else {
            address.set('editing', true);
          }
        }
      },


      /**
       * Function Save Address by params
       * @param {*} address 
       */
      saveAddress: function saveAddress(address) {
        address.set('editing', false);
      },


      /**
       * Function Delete Address by params
       * @param {*} address 
       */
      deleteAddress: function deleteAddress(address) {
        var model = this.get('model');

        return address.destroyRecord().then(function () {
          return model.get('courseAddresses').removeObject(address);
        });
      },


      /**
       * Function Change Address Kind by params
       * @param {*} address 
       * @param {*} kind 
       */
      changeAddressKind: function changeAddressKind(address, kind) {
        address.set('kind', kind);
      },


      /**
       * Function Change Address Region by params
       * @param {*} address 
       * @param {*} region 
       */
      changeAddressRegion: function changeAddressRegion(address, region) {
        address.set('region', region);
      },
      createContact: function createContact() {
        var controller = this;
        var model = this.get('model');
        var index = -1;

        model.get('contacts').then(function (contacts) {
          if (contacts) {
            contacts.forEach(function (contacts) {
              var cIndex = parseInt(contacts.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          var newContact = controller.get('store').createRecord('contact', {
            course: model,
            index: index + 1
          });
          newContact.set('editing', true);
          model.get('contacts').pushObject(newContact);
        });
      },


      /**
       * Function toggle Edit Contact by param
       * @param {*} contact 
       */
      toggleEditContact: function toggleEditContact(contact) {
        if (contact.get('isNew')) {
          contact.destroy();
        } else {
          if (contact.get('editing') === true) {
            contact.set('editing', false);
            contact.rollbackAttributes();
          } else {
            contact.set('editing', true);
          }
        }
      },


      /**
       * Function Save Contact by param
       * @param {*} contact 
       */
      saveContact: function saveContact(contact) {
        contact.set('editing', false);
      },


      /**
       * Function Delete Contact by param
       * @param {*} contact 
       */
      deleteContact: function deleteContact(contact) {
        return contact.destroyRecord();
      },


      /**
       * Function Change Salutation in Contact by params
       * @param {*} contact 
       * @param {*} salutation 
       */
      changeSalutationInContact: function changeSalutationInContact(contact, salutation) {
        contact.set('salutation', salutation);
      },


      /**
       * Function Change Kinds in Contact by param
       * @param {*} contact 
       * @param {*} kinds 
       */
      changeKindsInContact: function changeKindsInContact(contact, kinds) {
        contact.get('surrogateContactKinds').setObjects(kinds);
      },


      /**
       * Function Create Tel in Contact by param
       * @param {*} contact 
       */
      createTelInContact: function createTelInContact(contact) {
        var _this4 = this;

        var controller = this;
        var index = -1;

        contact.get('contactTels').then(function (tels) {
          if (tels) {
            tels.forEach(function (telephone) {
              var cIndex = parseInt(telephone.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          // Find get Store Personal Tel Kind by ID
          return _this4.get('store').find('personal-tel-kind', 1).then(function (personalTelKind) {
            var newTel = controller.get('store').createRecord('contact-tel', {
              contact: contact,
              index: index + 1,
              personalTelKind: personalTelKind
            });
            newTel.set('editing', true);
            contact.get('contactTels').pushObject(newTel);
          });
        });
      },


      /**
       * Function toggle Edit Tel in Contact by params
       * @param {*} contact 
       * @param {*} tel 
       */
      toggleEditTelInContact: function toggleEditTelInContact(contact, tel) {
        if (tel.get('isNew')) {
          tel.destroy();
        } else {
          if (tel.get('editing') === true) {
            tel.set('editing', false);
            tel.rollbackAttributes();
          } else {
            tel.set('editing', true);
          }
        }
      },


      /**
       * Function Save Tel in Contact by param
       * @param {*} contact 
       * @param {*} tel 
       */
      saveTelInContact: function saveTelInContact(contact, tel) {
        tel.set('editing', false);
      },


      /**
       * Function Delete Tel in Contact by param
       * @param {*} contact 
       * @param {*} tel 
       */
      deleteTelInContact: function deleteTelInContact(contact, tel) {
        return tel.destroyRecord();
      },


      /**
       * Function Change Tel Kind in Contact by param
       * @param {*} contact 
       * @param {*} tel 
       * @param {*} kind 
       */
      changeTelKindInContact: function changeTelKindInContact(contact, tel, kind) {
        tel.set('kind', kind);
      },


      /**
       * Function Create Address in Contact by param
       * @param {*} contact 
       */
      createAddressInContact: function createAddressInContact(contact) {
        var _this5 = this;

        var controller = this;
        var index = -1;

        contact.get('contactAddresses').then(function (addresses) {
          if (addresses) {
            addresses.forEach(function (address) {
              var cIndex = parseInt(address.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          return _this5.get('store').find('region', 1).then(function (region) {
            return _this5.get('store').find('personal-address-kind', 1).then(function (personalAddressKind) {
              var newAddress = controller.get('store').createRecord('contact-address', {
                contact: contact,
                index: index + 1,
                personalAddressKind: personalAddressKind,
                region: region
              });
              newAddress.set('editing', true);
              contact.get('contactAddresses').pushObject(newAddress);
            });
          });
        });
      },


      /**
       * Function toggle Edit Address in Contact by params
       * @param {*} contact 
       * @param {*} address 
       */
      toggleEditAddressInContact: function toggleEditAddressInContact(contact, address) {
        if (address.get('isNew')) {
          address.destroy();
        } else {
          if (address.get('editing') === true) {
            address.set('editing', false);
            address.rollbackAttributes();
          } else {
            address.set('editing', true);
          }
        }
      },


      /**
       * Function Save Address in Contact by params
       * @param {*} contact 
       * @param {*} address 
       */
      saveAddressInContact: function saveAddressInContact(contact, address) {
        if (contact.get('isNew') === true) {
          // We'll store this later once the contact exists in the database
          return address.set('editing', false);
        }

        return address.save().then(function () {
          return address.set('editing', false);
        }).then(function () {
          return address.reload();
        });
      },


      /**
       * Function Delete Address in Contact by params
       * @param {*} contact 
       * @param {*} address 
       */
      deleteAddressInContact: function deleteAddressInContact(contact, address) {
        return address.destroyRecord();
      },


      /**
       * Function Change Address Kind in Contact by params
       * @param {*} contact 
       * @param {*} address 
       * @param {*} kind 
       */
      changeAddressKindInContact: function changeAddressKindInContact(contact, address, kind) {
        address.set('kind', kind);
      },


      /**
       * Function Change Address Region in Contact by params
       * @param {*} contact 
       * @param {*} address 
       * @param {*} region 
       */
      changeAddressRegionInContact: function changeAddressRegionInContact(contact, address, region) {
        address.set('region', region);
      }
    }
  });
});