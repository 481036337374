define('golf-digest-panelists/models/ngf-course', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data', 'npm:underscore'], function (exports, _model, _attr, _emberData, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    facilityName: (0, _attr.default)('string'),
    ngfFacilityId: (0, _attr.default)('number'),
    ngfCourseId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    websiteUrl: (0, _attr.default)('string'),
    holeCount: (0, _attr.default)('number'),
    courseKind: (0, _attr.default)('string'),
    courseStatus: (0, _attr.default)('string'),
    designerFullName: (0, _attr.default)('string'),
    dateOpened: (0, _attr.default)('utc', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    ngfCourseAddresses: _emberData.default.hasMany('ngf-course-address'),
    ngfCourseTels: _emberData.default.hasMany('ngf-course-tel'),
    ngfTees: _emberData.default.hasMany('ngf-tee'),
    matched: _emberData.default.attr('boolean'),
    linked: _emberData.default.attr('boolean'),
    averageRating: (0, _attr.default)('number'),
    averageCount: (0, _attr.default)('number'),
    currentUserReview: (0, _attr.default)('number'),

    currentUserHasReview: Ember.computed('currentUserReview', function () {
      var value = this.get('currentUserReview');
      return value > 0 ? true : false;
    }),

    CourseKind: Ember.computed('courseKind', function () {
      var value = this.get('courseKind');
      return value ? value !== 'PR' ? value !== 'PE' ? value !== 'PN' ? value === 'DF' ? 'Daily Fee' : 'Municipal' : 'Private Non-Equity' : 'Private Equity' : 'Private' : null;
    }),

    CourseStatus: Ember.computed('courseStatus', function () {
      var value = this.get('courseStatus');
      return value === 'O' ? 'Opened' : 'Closed';
    }),

    mapAddress: Ember.computed('ngfCourseAddresses', function () {
      return this.get('ngfCourseAddresses').filter(function (a) {
        return a.get('kind') && a.get('kind').get('name') === 'Physical';
      }).get('firstObject');
    }),

    displayLocation: Ember.computed('ngfCourseAddresses', function () {
      var mapAddress = this.get('mapAddress');
      if (mapAddress) {
        return _npmUnderscore.default.compact([mapAddress.get('locality'), mapAddress.get('region') ? mapAddress.get('region').get('name') : null, mapAddress.get('region') ? mapAddress.get('region').get('country').get('name') : null]).join(' - ');
      }

      return '';
    }),

    MailingAddress: Ember.computed('ngfCourseAddresses', function () {
      return this.get('ngfCourseAddresses').filter(function (a) {
        return a.get('kind') && a.get('kind').get('name') === 'Mailing';
      }).get('firstObject');
    }),

    displayName: Ember.computed('facility', 'name', function () {
      var facilityName = this.get('facilityName');
      var courseName = this.get('name');

      if (_npmUnderscore.default.isString(facilityName) && !_npmUnderscore.default.isEmpty(facilityName.trim())) {
        if (_npmUnderscore.default.isString(courseName) && !_npmUnderscore.default.isEmpty(courseName.trim())) {
          return facilityName.trim() === courseName.trim();
        }
      }
      return true;
    })
  });
});