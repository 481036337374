define('golf-digest-panelists/components/select-candidacies/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',

    includedSurveys: Ember.computed.alias('items'),

    currentSurveys: function () {
      if (this) {
        var items = this.get('items');

        if (items) {
          return items.filter(function (candidacy) {
            return candidacy.get('survey.tabulationDate') > new Date();
          });
        }
      }

      return new Ember.A();
    }.property('items'),

    pastSurveys: function () {
      if (this) {
        var items = this.get('items');

        if (items) {
          return items.filter(function (candidacy) {
            return candidacy.get('survey.tabulationDate') <= new Date();
          });
        }
      }

      return new Ember.A();
    }.property('items'),

    actions: {
      addCandidacy: function addCandidacy(survey) {
        this.sendAction('addCandidacy', survey);
      },
      removeCandidacy: function removeCandidacy(candidacy) {
        this.sendAction('removeCandidacy', candidacy);
      },
      toggleFullyAssigned: function toggleFullyAssigned(candidacy, value) {
        this.sendAction('toggleFullyAssigned', candidacy, value);
      }
    }
  });
});