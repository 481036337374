define('golf-digest-panelists/components/evaluation-viewer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isShowPrompt: false,
    actions: {
      edit: function edit() {
        this.sendAction('edit', this.get('evaluation'));
      },
      triggerCancel: function triggerCancel() {
        this.set('isShowPrompt', false);
      },
      delete: function _delete() {
        this.set('isShowPrompt', true);
      },
      cancel: function cancel() {
        this.sendAction('delete', this.get('evaluation'));
      }
    }
  });
});