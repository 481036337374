define('golf-digest-panelists/components/image-uploader/uploaded-image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['col-md-6', 'col-sm-4', 'col-xs-4'],
    session: Ember.inject.service(),
    actions: {
      delete: function _delete() {
        this.get('onDelete')(this.get('image'));
      }
    }
  });
});