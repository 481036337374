define('golf-digest-panelists/components/evaluation-editor/course-select/component', ['exports', 'golf-digest-panelists/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    region: null,
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    worldLayout: !!_environment.default.brand && _environment.default.brand === 'Golf Digest Panelists World',
    country: null,

    internalValue: Ember.computed('value', function () {
      return this.get('value');
    }),

    enableCountry: Ember.computed('enableCountry', function () {
      return this.get('enableCountry') || false;
    }),

    actions: {
      /**
       * Function Search Course for param
       * @param {*} query
       */
      search: function search(query) {
        return this.get('store').query('course', {
          query: query,
          isCandidate: true,
          CountryId: this.get('country') ? this.get('country').get('id') : null,
          RegionId: this.get('region') ? this.get('region').get('id') : null
        });
      },


      /**
       * Function Change Region for param
       * @param {*} region
       */
      changeRegion: function changeRegion(region) {
        if (region) {
          this.set('region', region);

          if (this.get('regionChange')) {
            this.get('regionChange')(region);
          }
        } else {
          this.set('region', null);

          if (this.get('regionChange')) {
            this.get('regionChange')(null);
          }
        }
        this.send('changeValue', null);
      },


      /**
       * Function Change Country for param
       * @param {*} country
       */
      changeCountry: function changeCountry(country) {
        if (country) {
          this.set('country', country);
          this.set("region", null);

          if (this.get('countryChange')) {
            this.get('countryChange')(country);
          }
        } else {
          this.set('country', null);
          this.set("region", null);

          if (this.get('countryChange')) {
            this.get('countryChange')(null);
          }
        }
        this.send('changeValue', null);
      },
      changeRegionCountry: function changeRegionCountry(region) {
        if (region) {
          this.set("region", region);

          if (this.get('regionChange')) {
            this.get('regionChange')(region);
          }
        } else {
          this.set("region", null);

          if (this.get('regionChange')) {
            this.get('regionChange')(null);
          }
        }
        this.send('changeValue', null);
      },


      /**
       * Function Change Value for param
       * @param {*} course
       */
      changeValue: function changeValue(course) {
        var _this = this;

        if (course) {

          // Find and return Record Course By ID
          return this.get('store').findRecord('course', course.get('id')).then(function (realCourse) {
            return _this.get('onChange')(realCourse);
          });
        }

        this.get('onChange')(null);
      }
    }
  });
});