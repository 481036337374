define('golf-digest-panelists/components/table-address/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    itemSort: ['index:asc', 'id:desc'],
    sortedItems: Ember.computed.sort('items', 'itemSort'),

    actions: {
      create: function create() {
        this.sendAction('create');
      },
      toggleEdit: function toggleEdit(item) {
        this.sendAction('toggleEdit', item);
      },
      save: function save(item) {
        this.sendAction('save', item);
      },
      delete: function _delete(item) {
        this.sendAction('delete', item);
      },
      changeKind: function changeKind(item, kind) {
        this.sendAction('changeKind', item, kind);
      },
      changeRegion: function changeRegion(item, region) {
        this.sendAction('changeRegion', item, region);
      },
      reorderAddresses: function reorderAddresses(sortedAddresses) {
        sortedAddresses.forEach(function (item, index) {
          item.set('index', index);
          item.save();
        });
      },
      moveToTop: function moveToTop(topItem) {
        var items = this.get('items');

        items.forEach(function (item) {
          var curIndex = item.get('index');

          if (item.get('id') === topItem.get('id')) {
            item.set('index', 0);
          } else {
            item.set('index', curIndex + 1);
          }

          item.save();
        });
      }
    }
  });
});