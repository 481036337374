define('golf-digest-panelists/components/table-tel/table-row/select-kind/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    internalValue: Ember.computed('value', function () {
      var _this = this;

      if (this.get('value') && this.get('kinds')) {
        return this.get('kinds').find(function (item) {
          return item.get('id') === _this.get('value').get('id');
        });
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.notifyPropertyChange('value');
    },


    actions: {
      changeValue: function changeValue(kind) {
        var _this2 = this;

        if (kind) {
          var modelName = kind.constructor.modelName;

          // Function Get Store Kind and find and return By ID
          return this.get('store').findRecord(modelName, kind.get('id')).then(function (realKind) {
            return _this2.get('onChange')(realKind);
          });
        }

        this.get('onChange')(null);
      }
    }
  });
});