define('golf-digest-panelists/services/ajax', ['exports', 'ember-ajax/services/ajax', 'npm:underscore'], function (exports, _ajax, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    host: Ember.computed('store', function () {
      var adapter = this.get('store').adapterFor('application');

      return adapter.get('host');
    }),

    namespace: Ember.computed('store', function () {
      var adapter = this.get('store').adapterFor('application');

      return adapter.namespace;
    }),

    token: Ember.computed.alias('session.data.authenticated.access_token'),

    authHeaderValue: Ember.computed('token', function () {
      var token = this.get('token');

      if (token) {
        return 'Bearer ' + token;
      }

      return null;
    }),

    headers: Ember.computed('authHeaderValue', {
      get: function get() {
        var authHeaderValue = this.get('authHeaderValue');
        var headers = {};

        if (_npmUnderscore.default.isString(authHeaderValue)) {
          headers['Authorization'] = authHeaderValue;
        }

        return headers;
      }
    }),

    urlFor: function urlFor(path) {
      var host = this.get('host');
      var namespace = this.get('namespace');
      var token = this.get('token');

      return host + '/' + namespace + path + '?access_token=' + token;
    }
  });
});