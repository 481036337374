define('golf-digest-panelists/users/new/controller', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    actions: {
      cancel: function cancel() {
        this.transitionToRoute('user-report');
        this.get('model').rollbackAttributes();
        this.set('email', '');
        this.set('newPassword', '');
        this.set('confirmPassword', '');
      },
      submit: function submit() {
        var that = this;
        var store = that.get('store');
        var flash = that.get('flashMessages');

        var model = that.get('model');

        var newPassword = that.get('newPassword');
        var confirmPassword = that.get('confirmPassword');

        if (newPassword !== confirmPassword) {
          flash.danger('Passwords do not match. Please try again');
          return;
        }

        var email = that.get('email');
        if (email === undefined) {
          flash.danger('Please enter an email');
          return;
        }

        return store.query('user-email', { email: email }).then(function (collidingEmailUsers) {
          if (collidingEmailUsers && collidingEmailUsers.get('length') > 0) {
            flash.danger('This email is already in use');
            return _npmBluebird.default.resolve();
          }
          var createdBy = that.get('session').currentUser;
          var user = that.store.createRecord('user', {
            avatarFileData: '',
            givenName: model.get('givenName'),
            surname: model.get('surname'),
            middleName: model.get('middleName'),
            submittedAt: new Date(),
            createdBy: createdBy,
            createdAt: new Date(),
            secret: newPassword,
            newPassword: confirmPassword,
            salutation: model.get('salutation')
          });

          return user.save().then(function () {
            flash.success('User Created');

            var userEmail = store.createRecord('user-email', {
              email: email,
              isPrimary: true,
              user: user,
              index: 0,
              createdAt: new Date()
            });

            return userEmail.save().then(function () {
              that.set('email', '');
              that.set('newPassword', '');
              that.set('confirmPassword', '');

              return that.transitionToRoute('users.user.edit', user.get('id'));
            });
          }).catch(function (err) {
            flash.danger('' + err.errors[0].detail);

            Ember.Logger.error('Failed to save user: ' + (err.message + '\n' + (err && err.stack ? err.stack : '')));
          });
        });
      }
    }
  });
});