define('golf-digest-panelists/change-password/controller', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // TODO: This is really ugly. Should be grabbed from the API somehow.
  var MIN_COMPLEXITY_SCORE = 2;
  var REC_COMPLEXITY_SCORE = 3;

  exports.default = Ember.Controller.extend({
    passwordReset: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),

    minComplexityScore: MIN_COMPLEXITY_SCORE,
    recComplexityScore: REC_COMPLEXITY_SCORE,

    apiAcceptedPassword: null,
    apiStrengthWarning: null,
    apiStrengthSuggestions: Ember.A(),

    oldPassword: '',
    oldPasswordWasChecked: false,
    oldPasswordIsValid: null,

    actions: {

      /**
       * Function Submit New Password
       * @param {*} newPassword 
       * @param {*} confirmNewPassword 
       */
      submit: function submit(newPassword, confirmNewPassword) {
        var that = this;
        var flash = this.get('flashMessages');
        var oldPassword = this.get('oldPassword');
        var passwordReset = this.get('passwordReset');

        if (!_npmUnderscore.default.isString(oldPassword) || _npmUnderscore.default.isEmpty(oldPassword.trim())) {
          return flash.danger('Please enter your current password into the ' + 'first field to change it.');
        }

        // Change password
        return passwordReset.changePassword(oldPassword, newPassword, confirmNewPassword).then(function (payload) {
          that.set('oldPasswordWasChecked', true);
          that.set('oldPasswordIsValid', payload.oldPasswordIsValid);
          that.set('apiAcceptedPassword', payload.newPasswordsMatch === true && payload.passwordIsStrong === true && payload.passwordChangeWasCompleted === true);
          that.set('apiStrengthWarning', payload.strengthWarning);

          var apiSuggestions = that.get('apiStrengthSuggestions');
          var inboundSuggestions = payload.strengthSuggestions || [];

          apiSuggestions.clear();
          inboundSuggestions.forEach(function (s) {
            apiSuggestions.push(s);
          });

          if (payload.oldPasswordIsValid === true) {
            if (payload.passwordChangeWasCompleted === true) {
              return that.transitionToRoute('home').then(function () {
                return flash.success(payload.message, { timeout: 15000 });
              });
            }

            // Something else went wrong. Let the user know:
            return flash.danger(payload.message, { timeout: 15000 });
          }
        });
      }
    }
  });
});