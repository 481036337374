define('golf-digest-panelists/helpers/is-plural', ['exports', 'npm:is-numeric'], function (exports, _npmIsNumeric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isPlural = isPlural;
  function isPlural(params) {
    var value = params[0];

    if ((0, _npmIsNumeric.default)(value)) {
      return value !== 1;
    }

    return false;
  }

  exports.default = Ember.Helper.helper(isPlural);
});