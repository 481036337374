define('golf-digest-panelists/components/course-id/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    courseChar: null,
    init: function init() {
      this._super.apply(this, arguments);
      var that = this;
      var ngfCourse = this.get('model');

      this.get('store').query('course', { ngfCourseId: ngfCourse.get('id'), ngfFacilityId: ngfCourse.get('ngfFacilityId') }).then(function (result) {
        result.forEach(function (crs) {
          that.set('course', crs);
        });
      });
    },


    actions: {
      clear: function clear() {}
    }
  });
});