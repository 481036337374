define('golf-digest-panelists/users/index/controller', ['exports', 'golf-digest-panelists/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    queryParams: ['page', 'perPage', 'query', 'CountryId', 'RegionId', 'emailSearch'],

    totalPages: Ember.computed.alias('content.totalPages'),

    page: 1,
    perPage: 25,
    RegionId: null,
    CountryId: null,
    query: null,
    emailSearch: null,
    worldLayout: !!_environment.default.brand && _environment.default.brand === 'Golf Digest Panelists World',

    filteredUsers: Ember.computed('query', 'model', function () {
      var query = this.get('query');
      var users = this.get('model');

      if (query) {
        return users.filterBy('query', query);
      }

      return users;
    }),

    actions: {
      pageClicked: function pageClicked(page) {
        this.setProperties({ page: page });
      },
      changeRegion: function changeRegion(region) {
        if (region) {
          this.set('region', region);
        } else {
          this.set('region', null);
        }
      },
      changeCountry: function changeCountry(country) {
        if (country) {
          this.set('country', country);
          this.set("region", null);
        } else {
          this.set('country', null);
          this.set("region", null);
        }
      },
      changeRegionCountry: function changeRegionCountry(region) {
        if (region) {
          this.set("region", region);
        } else {
          this.set("region", null);
        }
      },
      searchUser: function searchUser() {
        var query = this.get('debounce_query');
        var region = this.get('region');
        var country = this.get('country');

        if (!!query && query.match(/\S+@\S+\.\S+/)) {
          this.set('emailSearch', query);
          this.set('query', '');
        } else {
          this.set('query', query);
          this.set('emailSearch', '');
        }

        if (region) {
          this.set('RegionId', region.id);
        } else {
          this.set('RegionId', null);
        }

        if (country) {
          this.set('CountryId', country.id);
        } else {
          this.set('CountryId', null);
        }
      }
    }
  });
});