define('golf-digest-panelists/models/user-tel', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    tel: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    personalTelKind: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    user: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    }
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    user: _emberData.default.belongsTo('user'),
    personalTelKind: _emberData.default.belongsTo('personal-tel-kind'),
    index: _emberData.default.attr('number'),
    tel: _emberData.default.attr('string'),
    extension: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    kind: Ember.computed.alias('personalTelKind')
  });
});