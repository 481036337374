define('golf-digest-panelists/components/table-tel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      create: function create() {
        this.sendAction('create');
      },
      toggleEdit: function toggleEdit(item) {
        this.sendAction('toggleEdit', item);
      },
      save: function save(item) {
        this.sendAction('save', item);
      },
      delete: function _delete(item) {
        this.sendAction('delete', item);
      },
      changeKind: function changeKind(item, kind) {
        this.sendAction('changeKind', item, kind);
      }
    }
  });
});