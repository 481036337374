define('golf-digest-panelists/components/latest-discussions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var that = this;

      that.$().find('.carousel').on('slid.bs.carousel', function (evt) {
        return that.slideBootstrapCarousel(Ember.$(evt.target));
      }).trigger('slid.bs.carousel');
    },
    slideBootstrapCarousel: function slideBootstrapCarousel(carousel) {
      var currentSlide = carousel.find('.active');
      var leftControl = carousel.find('.left');
      var rightControl = carousel.find('.right');

      if (currentSlide.is(':first-child')) {
        leftControl.addClass('carousel-control-disabled').removeClass('carousel-control-enabled');
        rightControl.removeClass('carousel-control-disabled').addClass('carousel-control-enabled');
      } else if (currentSlide.is(':last-child')) {
        leftControl.removeClass('carousel-control-disabled').addClass('carousel-control-enabled');
        rightControl.addClass('carousel-control-disabled').removeClass('carousel-control-enabled');
      } else {
        leftControl.removeClass('carousel-control-disabled').addClass('carousel-control-enabled');
        rightControl.removeClass('carousel-control-disabled').addClass('carousel-control-enabled');
      }
    }
  });
});