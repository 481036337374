define('golf-digest-panelists/password-reset/step-two/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // TODO: This is really ugly. Should be grabbed from the API somehow.
  var MIN_COMPLEXITY_SCORE = 2;
  var REC_COMPLEXITY_SCORE = 3;

  exports.default = Ember.Controller.extend({
    passwordReset: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    busy: Ember.inject.service(),

    minComplexityScore: MIN_COMPLEXITY_SCORE,
    recComplexityScore: REC_COMPLEXITY_SCORE,

    resetToken: null,
    tokenWasChecked: false,
    tokenIsValid: null,

    apiAcceptedPassword: null,
    apiStrengthWarning: null,
    apiStrengthSuggestions: Ember.A(),

    actions: {

      /**
       * Function Submit Passwords By Params
       * @param {*} password 
       * @param {*} confirmPassword 
       */
      submitPasswords: function submitPasswords(password, confirmPassword) {
        var that = this;
        var busy = this.get('busy');
        var flash = this.get('flashMessages');
        var passwordReset = this.get('passwordReset');

        var resetToken = this.get('resetToken');

        busy.show();

        /**
         * Function Reset Password By Params
         */
        return passwordReset.setPassword(resetToken, password, confirmPassword).then(function (payload) {
          that.set('tokenIsValid', payload.passwordResetTokenIsValid);
          that.set('apiAcceptedPassword', payload.newPasswordsMatch === true && payload.passwordIsStrong === true && payload.passwordResetWasCompleted === true);
          that.set('apiStrengthWarning', payload.strengthWarning);

          var apiSuggestions = that.get('apiStrengthSuggestions');
          var inboundSuggestions = payload.strengthSuggestions || [];

          apiSuggestions.clear();
          inboundSuggestions.forEach(function (s) {
            apiSuggestions.push(s);
          });

          busy.hide();

          if (payload.passwordResetWasCompleted === true) {
            return that.transitionToRoute('index').then(function () {
              return flash.success(payload.message, { timeout: 30000 });
            });
          }

          return flash.danger(payload.message, { timeout: 15000 });
        }).catch(function (err) {
          busy.hide();

          Ember.Logger.error(err);

          return flash.warning(err.message, { timeout: 15000 });
        });
      }
    }
  });
});