define('golf-digest-panelists/services/blocks', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    resolveState: function resolveState() {
      var store = this.get('store');

      return _npmBluebird.default.join(store.findAll('block'), store.findAll('document'), function (blocks, documents) {
        var result = {
          blocks: Ember.A(),
          context: {
            documents: {}
          }
        };

        blocks.forEach(function (b) {
          return result.blocks.addObject(b);
        });

        documents.forEach(function (d) {
          var docObject = d.toJSON();
          docObject.id = d.get('id');

          result.context.documents[d.get('id').toString()] = docObject;
        });

        return result;
      });
    }
  });
});