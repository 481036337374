define('golf-digest-panelists/components/ignored-courses-report/ignored-course-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-item', 'table-row'],
    tagName: 'tr',
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    actions: {
      unIgnoreCourse: function unIgnoreCourse(course) {
        this.sendAction('unIgnoreCourse', course);
      }
    }
  });
});