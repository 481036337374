define('golf-digest-panelists/components/load-more-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    loadText: 'Load More',
    loadedText: 'Loaded',
    click: function click() {
      this.sendAction('action', this.get('infinityModel'));
    }
  });
});