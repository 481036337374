define('golf-digest-panelists/evaluations/evaluation/index/route', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      deleteEvaluation: function deleteEvaluation(evaluation) {
        var _this = this;

        var flash = this.get('flashMessages');
        return evaluation.destroyRecord().then(function () {
          return _this.transitionTo('evaluations');
        }).then(function () {
          return flash.success('Evaluation deleted!');
        });
      },
      editEvaluation: function editEvaluation(evaluation) {
        var _this2 = this;

        var that = this;
        var oldEvaluation = evaluation;
        var scores = oldEvaluation.get('scores').map(function (score) {
          return score;
        });
        oldEvaluation.get('scores').clear();
        return this.store.findAll('criterion').then(function (criteria) {
          criteria.forEach(function (criterion) {
            var name = criterion.get('name');
            if (!criterion.get('disableDate')) {
              var scoreObject = _npmUnderscore.default.find(scores, function (s) {
                return s.get('criterion.name') === name;
              });
              if (scoreObject) {
                // Put the score back into the evaluation.
                oldEvaluation.get('scores').pushObject(scoreObject);
              } else {
                var newScore = that.store.createRecord('score');
                newScore.set('criterion', criterion);
                newScore.set('evaluation', oldEvaluation);
                oldEvaluation.get('scores').pushObject(newScore);
              }
            }
          });

          // De-activates old Evaluation and takes the User to create
          // a new evaluation with previous data.
          // Deactivate handled in backend while creating new evaluation
          _this2.transitionTo('evaluations.evaluation.edit', oldEvaluation);
        });
      }
    }
  });
});