define('golf-digest-panelists/application/adapter', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'golf-digest-panelists/config/environment', 'ember-data-has-many-query', 'golf-digest-panelists/utils/forbidden-error'], function (exports, _emberData, _dataAdapterMixin, _environment, _emberDataHasManyQuery, _forbiddenError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, _emberDataHasManyQuery.default.RESTAdapterMixin, {
    host: _environment.default.jsonAdapterHost,
    namespace: 'api/v2',
    authorizer: 'authorizer:application',
    headers: {
      client_id: _environment.default['ember-simple-auth'].client
    },

    // Check if will Reload all adapters
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },


    // Check if will Reload all adapters in background
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 403 && payload) {
        return new _forbiddenError.default('' + payload, { attemptedUrl: window.location });
      }

      return this._super.apply(this, arguments);
    }
  });
});