define('golf-digest-panelists/blocks/route', ['exports', 'npm:underscore', 'npm:bluebird'], function (exports, _npmUnderscore, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    store: Ember.inject.service(),
    blocks: Ember.inject.service(),

    markedForDestroy: Ember.A(),

    // Function get Blocks and Resolve State
    model: function model() {
      return this.get('blocks').resolveState();
    },


    /**
     * Function Find Previous Sibling for params
     * @param {*} blocks
     * @param {*} index
     */
    _findPreviousSibling: function _findPreviousSibling(blocks, index) {
      var beforeIndex = blocks.filter(function (b) {
        return parseInt(b.get('index'), 10) < parseInt(index, 10);
      });

      return _npmUnderscore.default.max(beforeIndex, function (b) {
        return parseInt(b.get('index'), 10);
      });
    },


    /**
     * Function Find Next Sibling for params
     * @param {*} blocks
     * @param {*} index
     */
    _findNextSibling: function _findNextSibling(blocks, index) {
      var afterIndex = blocks.filter(function (b) {
        return parseInt(b.get('index'), 10) > parseInt(index, 10);
      });

      return _npmUnderscore.default.min(afterIndex, function (b) {
        return parseInt(b.get('index'), 10);
      });
    },


    /**
     * Function Swap Blocks for params
     * @param {*} a
     * @param {*} b
     */
    _swapBlocks: function _swapBlocks(a, b) {
      if (a && b) {
        var bIndex = b.get('index');
        b.set('index', a.get('index'));
        a.set('index', bIndex);
      }
    },


    /**
     * Function Make Gap for params
     * @param {*} blocks
     * @param {*} forIndex
     */
    _makeGap: function _makeGap(blocks, forIndex) {
      var blocker = blocks.find(function (b) {
        return parseInt(b.get('index'), 10) === parseInt(forIndex, 10);
      });

      if (blocker) {
        var afterIndex = blocks.filter(function (b) {
          return parseInt(b.get('index'), 10) >= parseInt(forIndex, 10);
        });

        afterIndex.forEach(function (b) {
          return b.set('index', parseInt(b.get('index'), 10) + 1);
        });
      }
    },


    actions: {
      // Cancel create Blocks
      cancel: function cancel() {
        var blocks = this.currentModel.blocks;
        var markedForDestroy = this.get('markedForDestroy');

        var destroy = blocks.filterBy('isNew', true);
        blocks.removeObjects(destroy);
        destroy.forEach(function (b) {
          return b.deleteRecord();
        });

        blocks.addObjects(markedForDestroy);
        markedForDestroy.clear();

        blocks.forEach(function (b) {
          return b.rollbackAttributes();
        });

        return this.refresh();
      },

      // Save Blocks
      save: function save() {
        var that = this;
        var blocks = this.currentModel.blocks;
        var markedForDestroy = this.get('markedForDestroy');

        return _npmBluebird.default.all([_npmBluebird.default.all(blocks.map(function (b) {
          return b.save();
        })), _npmBluebird.default.map(markedForDestroy, function (b) {
          return b.save();
        })]).then(function () {
          return that.transitionTo('home');
        });
      },

      /**
       * Function Add before Blocks for param
       * @param {*} index
       */
      addBefore: function addBefore(index) {
        var blocks = this.currentModel.blocks;
        var newIndex = parseInt(index, 10) === 0 ? 0 : parseInt(index, 10) - 1;

        this._makeGap(blocks, newIndex);

        var store = this.get('store');
        var newBlock = store.createRecord('block', { index: newIndex });

        blocks.addObject(newBlock);
      },


      /**
       * Function Add after Blocks for param
       * @param {*} index
       */
      addAfter: function addAfter(index) {
        var blocks = this.currentModel.blocks;
        var newIndex = parseInt(index, 10) + 1;

        this._makeGap(blocks, newIndex);

        var store = this.get('store');
        var newBlock = store.createRecord('block', { index: newIndex });

        blocks.addObject(newBlock);
      },


      /**
       * Function Delete Item for param
       * @param {*} item
       */
      delete: function _delete(item) {
        var blocks = this.currentModel.blocks;
        var markedForDestroy = this.get('markedForDestroy');

        markedForDestroy.push(item);
        blocks.removeObject(item);
        item.deleteRecord();
      },


      /**
       * Function Move Up for param
       * @param {*} item
       */
      moveUp: function moveUp(item) {
        var blocks = this.currentModel.blocks;
        var swap = this._findPreviousSibling(blocks, item.get('index'));

        this._swapBlocks(item, swap);
      },


      /**
       * Function Move Down for param
       * @param {*} item
       */
      moveDown: function moveDown(item) {
        var blocks = this.currentModel.blocks;
        var swap = this._findNextSibling(blocks, item.get('index'));

        this._swapBlocks(item, swap);
      },


      // Redirect Course per Region By Id
      regionRedirect: function regionRedirect(regionId) {
        this.transitionTo('courses', { queryParams: { RegionId: regionId } });
      },
      // Redirect Course per Country By Id
      countryRedirect: function countryRedirect(countryId) {
        this.transitionTo('courses', { queryParams: { CountryId: countryId } });
      }
    }
  });
});