define('golf-digest-panelists/surveys/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),

    actions: {
      cancel: function cancel() {
        this.transitionToRoute('surveys');
        this.get('model').rollbackAttributes();
      },
      done: function done() {
        this.transitionToRoute('surveys');
      },
      submit: function submit() {
        var controller = this;
        var store = this.get('store');
        var flash = this.get('flashMessages');
        var model = this.get('model');
        var surveyName = model.get('name');
        return store.query('survey', { name: surveyName }) // Find surveys/name:surveyName
        .then(function (existingSurvey) {
          if (existingSurvey && existingSurvey.content.length > 0) {
            flash.danger('This Survey Name already exists!');
          } else {
            return model.save().then(function () {
              flash.success('Survey Created!');
              controller.transitionToRoute('surveys.survey.courses', model.id);
            });
          }
        });
      }
    }
  });
});