define('golf-digest-panelists/components/latest-discussions/discussion-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['col-xs-4']
  });
});