define('golf-digest-panelists/models/personal-address-kind', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    isDisabled: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true })
  });
});