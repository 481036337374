define('golf-digest-panelists/courses/index/controller', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['perPage', 'RegionId', 'CountryId', 'query', 'startsWith'],

    totalPages: Ember.computed.alias('content.totalPages'),

    session: Ember.inject.service(),
    page: 1,
    perPage: 25,
    RegionId: null,
    CountryId: null,
    query: null,
    loading: true,

    cap: Ember.computed.alias('session.data.cap'),
    userId: Ember.computed.alias('session.data.currentUser.id'),

    actions: {
      pageClicked: function pageClicked(page) {
        this.set('page', page);
      },
      getEvaluations: function getEvaluations(course) {
        var courseId = course.get('id');

        // Transition to Route Course By ID
        return this.transitionToRoute('courses.course.evaluations', courseId, { queryParams: { active: false } });
      },
      getEvaluation: function getEvaluation(course) {
        var _this = this;

        var courseId = course.get('id');
        var userId = this.get('userId');

        return this.get('store').queryRecord('evaluation', { CourseId: courseId, UserId: userId }).then(function (evaluation) {
          if (evaluation && !_npmUnderscore.default.isUndefined(evaluation)) {
            return _this.transitionToRoute('evaluations.evaluation', evaluation);
          }

          _this.get('session').set('course', course);
          return _this.transitionToRoute('evaluations.new');
        }).catch(function () {
          _this.get('session').set('course', course);

          return _this.transitionToRoute('evaluations.new');
        });
      },

      goToCourse: function goToCourse(course) {
        return this.transitionToRoute('courses.course', course);
      }
    }
  });
});