define('golf-digest-panelists/components/rail-toggle/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['hidden-lg', 'hidden-md', 'hidden-sm', 'xs-sidebar-toggle', 'btn'],
    classNameBindings: ['isOpen:open:closed'],

    messageBus: Ember.inject.service(),

    isOpen: false,

    subscribeToEvents: function () {
      var messageBus = this.get('messageBus');

      messageBus.subscribe('rail-update', this, this.handleRailUpdate);
    }.on('init'),

    handleRailUpdate: function handleRailUpdate(railState) {
      this.set('isOpen', railState.isVisible);
    },


    toggleRail: function () {
      var messageBus = this.get('messageBus');

      this.toggleProperty('isOpen');

      var isOpen = this.get('isOpen');

      messageBus.publish('rail-set', isOpen);
      messageBus.publish('main-navigation-set', isOpen === false);
    }.on('click')
  });
});