define('golf-digest-panelists/index/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    panelLayout: true,

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('index.footer', { outlet: 'footer' });
    },

    actions: {
      navigate: function navigate(path) {
        return this.transitionTo(path);
      }
    }
  });
});