define('golf-digest-panelists/users/index/route', ['exports', 'ember-cli-pagination/remote/route-mixin'], function (exports, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,

    model: function model(params) {
      params.isDisabled = false;

      var results = this.findPaged('user', params);

      if (params.page > 1 && !results.content) {
        params.page = 1;
        results = this.findPaged('user', params);
      }

      return results;
    },


    queryParams: {
      query: {
        refreshModel: true
      },
      emailSearch: {
        refreshModel: true
      },
      RegionId: {
        refreshModel: true
      },
      CountryId: {
        refreshModel: true
      }
    },
    actions: {
      // Redirect Course per Region By Id
      regionRedirect: function regionRedirect(regionId) {
        this.transitionTo('courses', { queryParams: { RegionId: regionId } });
      },
      // Redirect Course per Country By Id
      countryRedirect: function countryRedirect(countryId) {
        this.transitionTo('courses', { queryParams: { CountryId: countryId } });
      }
    }
  });
});