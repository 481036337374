define('golf-digest-panelists/models/candidate', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-data'], function (exports, _model, _attr, _relationships, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    course: (0, _relationships.belongsTo)('course', { readOnly: true }),
    survey: (0, _relationships.belongsTo)('survey', { readOnly: true }),
    isFullyAssigned: (0, _attr.default)('boolean'),
    coursesArray: _emberData.default.attr()
  });
});