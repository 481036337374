define('golf-digest-panelists/helpers/len', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.len = len;
  function len(params /*, hash*/) {
    var item = params[0];

    if (_npmUnderscore.default.isString(item)) {
      return item.length;
    }

    if (_npmUnderscore.default.isDate(item) || _npmUnderscore.default.isNumber(item)) {
      return 1;
    }

    if (_npmUnderscore.default.isObject(item)) {
      return _npmUnderscore.default.size(item);
    }

    return 0;
  }

  exports.default = Ember.Helper.helper(len);
});