define('golf-digest-panelists/pay-now/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('username', '');
      controller.set('price', '300');
    }
  });
});