define('golf-digest-panelists/password-reset/step-two/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    panelLayout: true,
    passwordReset: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('index.footer', { outlet: 'footer' });
    },
    model: function model(params) {
      this.set('resetToken', params.reset_token);
    },
    setupController: function setupController(controller) {
      var resetToken = this.get('resetToken');
      var flash = this.get('flashMessages');
      var passwordReset = this.get('passwordReset');
      var that = this;

      controller.set('resetToken', resetToken);

      return passwordReset.checkTokenValidity(resetToken).then(function (payload) {
        if (payload.passwordResetTokenIsValid !== true) {
          controller.set('tokenWasChecked', false);
          controller.set('tokenIsValid', false);

          return that.transitionTo('password-reset.index').then(function () {
            return flash.danger(payload.message, { timeout: 15000 });
          });
        }

        controller.set('tokenWasChecked', true);
        controller.set('tokenIsValid', true);
      });
    }
  });
});