define('golf-digest-panelists/components/block-editor/block-controls/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      addBefore: function addBefore(index) {
        this.sendAction('addBefore', index);
      },
      addAfter: function addAfter(index) {
        this.sendAction('addAfter', index);
      },
      delete: function _delete(item) {
        this.sendAction('delete', item);
      },
      moveUp: function moveUp(item) {
        this.sendAction('moveUp', item);
      },
      moveDown: function moveDown(item) {
        this.sendAction('moveDown', item);
      }
    }
  });
});