define('golf-digest-panelists/pay-now/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    formLayout: true,
    session: Ember.inject.service('session'),

    renderTemplate: function renderTemplate() {
      if (!this.get('session.data.authenticated.user.isDisabled')) {
        this.transitionTo('home');
      }
      this._super.apply(this, arguments);
    }
  });
});