define('golf-digest-panelists/ptp/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['perPage', 'RegionId', 'query', 'startsWith'],

    totalPages: Ember.computed.alias('content.totalPages'),
    currentPos: 0,

    session: Ember.inject.service(),
    page: 1,
    perPage: 25,
    RegionId: null,
    query: null,
    loading: true,

    cap: Ember.computed.alias('session.data.cap'),
    userId: Ember.computed.alias('session.data.currentUser.id'),

    actions: {}
  });
});