define('golf-digest-panelists/blocks/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),

    sortProperties: ['index:asc'],
    sortedBlocks: Ember.computed.sort('model.blocks', 'sortProperties')
  });
});