define('golf-digest-panelists/models/block', ['exports', 'ember-data', 'golf-digest-panelists/utils/filter-html'], function (exports, _emberData, _filterHtml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    index: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    isDraft: _emberData.default.attr('boolean'),
    publishDate: _emberData.default.attr('utc', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    unpublishDate: _emberData.default.attr('utc', { defaultValue: null }),
    createdByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),

    listDate: Ember.computed('publishDate', 'createdAt', 'updatedAt', function () {
      return this.get('publishDate') || this.get('createdAt') || this.get('updatedAt') || new Date();
    }),

    safeBody: Ember.computed('body', 'accessToken', function () {
      var body = this.get('body');

      if (body) {
        return (0, _filterHtml.default)(body);
      }
    }),

    isDraftUnpublishedExpired: Ember.computed('publishDate', 'unpublishDate', 'isDraft', function () {
      var thisDate = Date.parse(new Date());

      return Date.parse(this.get('publishDate')) > thisDate || Date.parse(this.get('unpublishDate')) < thisDate || this.get('isDraft') !== undefined && this.get('isDraft');
    }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser'),
    images: Ember.computed.alias('articleImages')
  });
});