define('golf-digest-panelists/evaluations/evaluation/edit/route', ['exports', 'npm:underscore', 'moment'], function (exports, _npmUnderscore, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    busy: Ember.inject.service(),

    duplicate: function duplicate(oldObject) {
      var newObject = oldObject.toJSON();
      for (var key in newObject) {
        if (newObject.hasOwnProperty(key)) {
          if (newObject[key] !== oldObject.get(key)) {
            newObject[key] = oldObject.get(key);
          }
        }
      }
      newObject.id = null;
      return newObject;
    },
    _save: function _save() {
      var flash = this.get('flashMessages');

      var busy = this.get('busy');
      busy.show({ message: 'Saving your course evaluation...' });

      var that = this;
      var newEvaluation = this.modelFor('evaluations.evaluation.edit');

      // Clone evaluation data to new copy since we have to POST data not PATCH
      // or PUT.
      var newModel = this.duplicate(newEvaluation);

      newModel.dateExperienced = _moment.default.utc(newModel.dateExperienced.format('YYYY-MM-DD'));

      var evaluation = this.store.createRecord('evaluation', newModel);

      newEvaluation.set('saving', true);
      evaluation.set('saving', true);

      // Strangely scores relation is not updated and this is being done manually
      // here.
      var newScores = newEvaluation.get('scores').map(function (score) {
        return score;
      });
      var scoreString = JSON.stringify(newScores);
      newEvaluation.set('scorestr', scoreString);
      newScores.forEach(function (score) {
        var newScore = that.store.createRecord('score', { score: score.get('score'), criterion: score.get('criterion') });
        evaluation.get('scores').pushObject(newScore);
      });

      // Temporarily yank out all scores. We need to save just the evaluation
      // first
      var scores = evaluation.get('scores').map(function (score) {
        return score;
      }); // Clone hack
      scoreString = JSON.stringify(scores);
      evaluation.set('scorestr', scoreString);
      evaluation.get('scores').clear();

      return evaluation.save().then(function () {
        return evaluation.reload();
      }).then(function () {
        busy.hide();

        newEvaluation.set('saving', false);
        evaluation.set('saving', false);

        return that.transitionTo('evaluations.evaluation', evaluation).then(function () {
          return flash.success('Your evaluation of ' + (evaluation.get('course').get('actualDisplayName') + ' has been ') + 'saved!');
        });
      }).catch(function (err) {
        Ember.Logger.error('Failed to save evaluation: ' + (err.message + '\n' + (err && err.stack ? err.stack : '')));

        // Put the removed scores back in case of error.
        evaluation.get('scores').pushObjects(scores);

        newEvaluation.set('saving', false);
        evaluation.set('saving', false);

        var errMsg = err.message;

        if (err.errors && err.errors.length > 0) {
          errMsg = _npmUnderscore.default.pluck(err.errors, 'detail').join('\n');
        }

        alert('We ran into an error while saving your evaluation: ' + errMsg);

        busy.hide();
      });
    },


    actions: {
      abort: function abort() {
        var evaluation = this.modelFor('evaluations.evaluation.edit');
        evaluation.rollbackAttributes();
        this.transitionTo('evaluations.evaluation', evaluation.id);
      },
      submit: function submit() {
        var _this = this;

        var that = this;
        var evaluation = this.modelFor('evaluations.evaluation.edit');

        // eslint-disable-next-line no-unused-vars
        return evaluation.validate().then(function (_ref) {
          var m = _ref.m,
              validations = _ref.validations;

          if (validations.get('isValid')) {
            return that._save();
          }

          var flash = _this.get('flashMessages');

          return flash.danger('Please review the following errors with your evaluation: ' + validations.get('errors').map(function (e) {
            return e.attribute + ': ' + e.message;
          }).join(', '), { sticky: true });
        });
      }
    }
  });
});