define('golf-digest-panelists/documents/index/controller', ['exports', 'golf-digest-panelists/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'perPage', 'id'],
    totalPages: Ember.computed.alias('content.totalPages'),
    page: 1,
    perPage: 25,
    id: 'DESC',
    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),
    documentsExportURL: _environment.default.jsonAdapterHost + '/api/v2/documents/',

    actions: {
      pageClicked: function pageClicked(page) {
        this.setProperties({ page: page });
      }
    }
  });
});