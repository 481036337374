define('golf-digest-panelists/components/login-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      /**
       * Function to authenticate user by parameters
       */
      authenticate: function authenticate() {
        var _getProperties = this.getProperties('login', 'password'),
            login = _getProperties.login,
            password = _getProperties.password;

        var that = this;
        var session = this.get('session');

        return session.authenticate('authenticator:oauth2', login, password).catch(function (err) {
          var flash = that.get('flashMessages');

          session.set('data.userId', '');

          if (err && 'invalid_grant' === err.name) {
            if (err.message && err.message.length > 0) {
              return flash.danger('We couldn\'t log you in: ' + err.message);
            }

            return flash.danger('Please verify e-mail and password entered');
          }

          Ember.Logger.debug('Unrecognized auth error: ' + JSON.stringify(err) + '\n' + ('' + (err && err.stack ? err.stack : '')));
          return flash.danger('System is unreachable. Please try again later.');
        });
      }
    }
  });
});