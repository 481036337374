define('golf-digest-panelists/components/ptp-course-search/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['row'],

    region: null,
    store: Ember.inject.service(),

    actions: {
      changeRegion: function changeRegion(region) {
        this.set('region', region);
      },
      changeCourse: function changeCourse(course) {
        if (course) {
          // Reroute to course from here.
          this.sendAction('goToCourse', course);
        }
      },
      searchCourse: function searchCourse() {
        var region = this.get('region');
        if (region) {
          this.get('searchQuery')(region);
        } else {
          this.get('searchQuery')(null);
        }
      }
    }
  });
});