define('golf-digest-panelists/models/user', ['exports', 'ember-data', 'npm:underscore'], function (exports, _emberData, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    isDisabled: _emberData.default.attr('boolean'),
    isDeactivated: _emberData.default.attr('boolean'),
    secret: _emberData.default.attr('string'),
    disabledAt: _emberData.default.attr('utc'),
    isLocked: _emberData.default.attr('boolean'),
    isSecretExpired: _emberData.default.attr('boolean'),
    isSecurityAnswersLocked: _emberData.default.attr('boolean'),
    lastAuthAt: _emberData.default.attr('utc'),
    lastActivityAt: _emberData.default.attr('utc'),
    lastClientIpAddress: _emberData.default.attr('string'),
    salutation: _emberData.default.belongsTo('salutation'),
    givenName: _emberData.default.attr('string'),
    middleName: _emberData.default.attr('string'),
    surname: _emberData.default.attr('string'),
    suffix: _emberData.default.attr('string'),
    avatarMimeType: _emberData.default.attr('string'),
    panelistSinceYear: _emberData.default.attr('number'),
    createdByUser: _emberData.default.belongsTo('user', { inverse: null, readOnly: true }),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedByUser: _emberData.default.belongsTo('user', { inverse: null, readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),
    userEmails: _emberData.default.hasMany('user-email', { inverse: 'user' }),
    userAddresses: _emberData.default.hasMany('user-address'),
    userTels: _emberData.default.hasMany('user-tel'),
    profileValues: _emberData.default.hasMany('profile-values'),
    roles: _emberData.default.hasMany('role', { inverse: null }),

    newPassword: _emberData.default.attr('string'),
    requestPasswordReset: _emberData.default.attr('boolean'),
    hasAuthAnswers: _emberData.default.attr('boolean'),

    fullName: function () {
      return _npmUnderscore.default.flatten([this.get('salutation').get('name'), this.get('givenName'), this.get('middleName'), this.get('surname'), this.get('suffix')]).join(' ');
    }.property('salutation', 'givenName', 'middleName', 'surname'),

    displayName: function () {
      return _npmUnderscore.default.flatten([this.get('givenName'), this.get('surname')]).join(' ');
    }.property('givenName', 'surname'),

    primaryEmail: function () {
      return this.get('userEmails').get('firstObject');
    }.property('userEmails'),

    primaryAddress: function () {
      return this.get('userAddresses').get('firstObject');
    }.property('userAddresses'),

    role: Ember.computed('roles', function () {
      var ret = '';

      if (this.get('roles').get('length') > 1) {
        ret = 'Multiple';
      } else {
        this.get('roles').forEach(function (role) {
          if (role.get('name')) {
            ret = role.get('name');
          }
        });
      }
      return ret;
    }),

    isAdmin: Ember.computed('roles', function () {
      var ret = false;
      this.get('roles').forEach(function (role) {
        if (role.get('name') === 'admin') {
          ret = true;
          return;
        }
      });
      return ret;
    }),

    isArchitecturalEditor: Ember.computed('roles', function () {
      var ret = false;
      this.get('roles').forEach(function (role) {
        if (role.get('name') === 'Architecture Editor') {
          ret = true;
          return;
        }
      });
      return ret;
    }),

    primaryTel: function () {
      return this.get('userTels').get('firstObject');
    }.property('userTels'),

    avatarFileData: _emberData.default.attr('raw'),

    avatarBaseUrl: Ember.computed(function () {
      var adapter = this.store.adapterFor('user');

      return adapter.buildURL('user', this.id) + '/avatar';
    }),

    // This is always 'image' for avatars since we don't care what the sent
    // file name is... unless we have no image at all.
    avatarFileName: Ember.computed(function () {
      if (this.get('avatarMimeType')) {
        return 'image';
      }

      return null;
    }),

    avatarUrl: Ember.computed('avatarFileName', function () {
      var size = 'original';

      return this.get('avatarBaseUrl') + '/' + size + '/' + this.get('avatarFileName');
    }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser'),
    addresses: Ember.computed.alias('userAddresses'),
    emails: Ember.computed.alias('userEmails'),
    tels: Ember.computed.alias('userTels')
  });
});