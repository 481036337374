define('golf-digest-panelists/services/password-reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    getChallenge: function getChallenge(username) {
      var ajax = this.get('ajax');
      var url = '/password-reset/questions';

      return ajax.request(url, {
        method: 'POST',
        data: {
          userEmailAddress: username
        }
      }).then(function (data) {
        return Ember.RSVP.resolve(data.questions);
      }).catch(function (err) {
        Ember.Logger.warn(err.message);

        return Ember.RSVP.reject(new Error('A problem occurred while trying to obtain security ' + 'challenge questions. Please try again.'));
      });
    },
    sendChallengeResponses: function sendChallengeResponses(requestId, responses) {
      var ajax = this.get('ajax');
      var url = '/password-reset/send-token';

      return ajax.request(url, {
        method: 'POST',
        data: {
          PasswordResetRequestId: requestId,
          securityQuestionResponses: responses
        }
      }).then(function (data) {
        return Ember.RSVP.resolve(data.token);
      }).catch(function (err) {
        Ember.Logger.warn(err.message);

        return Ember.RSVP.reject(new Error('A problem occurred while trying to verify your security ' + 'challenge responses. Please try again.'));
      });
    },
    getSendTokenStatus: function getSendTokenStatus(requestId, taskId) {
      var ajax = this.get('ajax');
      var url = '/password-reset/send-token/tasks/' + taskId;

      return ajax.request(url, {
        method: 'GET',
        data: {
          PasswordResetRequestId: requestId
        }
      }).then(function (data) {
        return Ember.RSVP.resolve(data.task);
      }).catch(function (err) {
        Ember.Logger.warn(err.message);

        // TODO: We'll just ignore the error for now. Should we do more? ~kat
      });
    },
    checkTokenValidity: function checkTokenValidity(resetToken) {
      var ajax = this.get('ajax');
      var url = '/password-reset/validate/' + resetToken;

      return ajax.request(url, {
        method: 'GET'
      }).then(function (data) {
        return Ember.RSVP.resolve(data.tokenValidity);
      }).catch(function (err) {
        Ember.Logger.warn(err.message);

        // It didn't work, so we'll just have the user try again. Resolve to
        // a false-like response.
        return Ember.RSVP.resolve({
          passwordResetTokenIsValid: false,
          message: 'Your token could not be validated due to an unknown ' + 'issue. Please try again. Contact Golf Digest if you continue to' + 'receive this error message.'
        });
      });
    },
    setPassword: function setPassword(resetToken, password, confirmPassword) {
      var ajax = this.get('ajax');
      var url = '/password-reset/set-password/' + resetToken;

      return ajax.request(url, {
        method: 'POST',
        data: {
          password: password,
          confirmPassword: confirmPassword
        }
      }).then(function (data) {
        return Ember.RSVP.resolve(data.setPassword);
      }).catch(function (err) {
        Ember.Logger.warn(err.message);

        // It didn't work, so we'll just have the user try again. Resolve to
        // a false-like response.
        return Ember.RSVP.resolve({
          id: null,
          passwordResetTokenIsValid: false,
          newPasswordsMatch: null,
          passwordIsStrong: null,
          passwordResetWasCompleted: false,
          message: 'Your password could not be updated due to an unknown ' + 'issue. Please try again. Contact Golf Digest if you continue to' + 'receive this error message.',
          strengthWarning: null,
          strengthSuggestions: []
        });
      });
    },
    changePassword: function changePassword(oldPassword, newPassword, confirmNewPassword) {
      var ajax = this.get('ajax');
      var url = '/change-password/';

      return ajax.request(url, {
        method: 'POST',
        data: {
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmNewPassword: confirmNewPassword
        }
      }).then(function (data) {
        return Ember.RSVP.resolve(data.changePassword);
      }).catch(function (err) {
        Ember.Logger.warn(err.message);

        // It didn't work, so we'll just have the user try again. Resolve to
        // a false-like response.
        return Ember.RSVP.resolve({
          oldPasswordIsValid: false,
          newPasswordsMatch: null,
          passwordIsStrong: null,
          passwordChangeWasCompleted: false,
          message: 'Your password could not be updated due to an unknown ' + 'issue. Please try again. Contact Golf Digest if you continue to' + 'receive this error message.',
          strengthWarning: null,
          strengthSuggestions: []
        });
      });
    },
    unlockAndResetUser: function unlockAndResetUser(userId) {
      var ajax = this.get('ajax');
      var url = '/users/' + userId + '/unlock-and-reset';

      return ajax.request(url, {
        method: 'POST'
      }).then(function (data) {
        return Ember.RSVP.resolve(data.unlockAndReset);
      }).catch(function (err) {
        Ember.Logger.warn(err.message);

        // It didn't work, so we'll just have the user try again. Resolve to
        // a false-like response.
        return Ember.RSVP.resolve({
          userIdIsValid: false,
          taskId: null,
          message: 'An unlock and reset could not be performed due to an ' + 'unknown problem. Please try again.'
        });
      });
    },
    getUnlockAndResetStatus: function getUnlockAndResetStatus(userId, taskId) {
      var ajax = this.get('ajax');
      var url = '/users/' + userId + '/unlock-and-reset/tasks/' + taskId;

      return ajax.request(url, {
        method: 'GET'
      }).then(function (data) {
        return Ember.RSVP.resolve(data.task);
      }).catch(function (err) {
        Ember.Logger.warn(err.message);

        // TODO: We'll just ignore the error for now. Should we do more? ~kat
      });
    }
  });
});