define('golf-digest-panelists/components/mapbox-map/component', ['exports', 'npm:mapbox.js', 'npm:underscore'], function (exports, _npmMapbox, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    map: null,

    /**
     * Function Hide Map Box
     */
    _hide: function _hide() {
      this.$().empty();
      this.$().hide();
    },


    /**
     * Function get Map and configurates
     */
    _getMap: function _getMap() {
      if (_npmUnderscore.default.isNull(this.get('map'))) {
        this.set('map', _npmMapbox.default.mapbox.map(this.elementId, 'mapbox.streets', {
          zoomControl: false
        }));
      }

      return this.get('map');
    },
    _setAddress: function _setAddress() {
      var _this = this;

      var address = this.get('address');

      if (address) {
        var compactAddress = void 0;
        var regionAbbr = void 0;
        var countryName = void 0;
        var region = this.get('address').get('region');

        if (region) {
          var country = region.get('country');

          if (country) {
            countryName = country.get('name');
          }

          regionAbbr = region.get('abbreviation');
        }

        compactAddress = _npmUnderscore.default.compact([address.get('street1'), address.get('street2'), address.get('street3'), address.get('locality'), regionAbbr, address.get('postalCode'), countryName]).join(', ');

        _npmMapbox.default.mapbox.geocoder('mapbox.places').query(compactAddress, function (err, res) {
          if (err) {
            _this._hide();
          } else {
            var leaflet = _this._getMap();

            leaflet.setView(res.latlng, 15);
            leaflet.dragging.disable();
            leaflet.touchZoom.disable();
            leaflet.doubleClickZoom.disable();
            leaflet.scrollWheelZoom.disable();
            leaflet.keyboard.disable();

            _npmMapbox.default.marker(res.latlng).addTo(leaflet);
          }
        });
      } else {
        this._hide();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super();

      // TODO: This should probably be configurable
      _npmMapbox.default.mapbox.accessToken = 'pk.eyJ1IjoianRyYWluZXIiLCJhIjoiY2lnd3I1dWV0M' + 'HMxc3ZvbTMyNzRlZnk4dyJ9.45yBoyEhZh-9uoJsPh4blQ';
    },
    didRender: function didRender() {
      this._super();

      this._setAddress();
    }
  });
});