define('golf-digest-panelists/components/main-review/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['row'],

    isExpanded: false,
    topImage: Ember.computed.alias('review.reviewImages.firstObject'),

    actions: {
      toggleExpansion: function toggleExpansion() {
        this.toggleProperty('isExpanded');
      }
    }
  });
});