define('golf-digest-panelists/config/ember-spinner/loading-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    color: '#fe2b00',
    top: '50%',
    left: '50%',
    lines: 15,
    length: 16,
    width: 8,
    radius: 32,
    corners: 0,
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 56,
    fps: 20,
    shadow: false,
    hwaccel: true
  };
});