define('golf-digest-panelists/components/identity-bar/component', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    messageBus: Ember.inject.service(),
    store: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),
    UserId: Ember.computed.alias('session.data.currentUserId'),

    fullName: Ember.computed('session.currentUser', function () {
      var user = this.get('session.currentUser');

      if (user) {
        return _npmUnderscore.default.flatten([user.Salutation && user.Salutation.name ? user.Salutation.name : null, user.givenName, user.middleName, user.surname, user.suffix]).join(' ');
      }

      return '';
    }),

    subscribeToEvents: function () {
      var messageBus = this.get('messageBus');

      messageBus.subscribe('ready', this, this.resetMenu);
    }.on('init'),

    resetMenu: function resetMenu() {
      var $ = Ember.$;
      var dropdowns = this.$('.dropdown') || null;
      var collapses = this.$('.collapse') || null;

      if (dropdowns) {
        dropdowns.each(function () {
          $(this).removeClass('open');
        });
      }

      if (collapses) {
        collapses.each(function () {
          $(this).removeClass('in');
        });
      }
    },


    actions: {
      logout: function logout() {
        var session = this.get('session');

        return session.logout();
      }
    },

    menuClickHandler: function (e) {
      var $ = Ember.$;
      var el = e.target;

      // If we clicked inside of a link that's part of this menu, close it.
      if (this && el && (el.nodeName === 'A' || $(el).parents('a').length > 0) && !$(el).hasClass('dropdown-toggle') && $(el).parents('a.dropdown-toggle').length === 0) {

        this.resetMenu();
        e.stopPropagation();
      }
    }.on('click')
  });
});