define('golf-digest-panelists/models/facility', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'npm:underscore'], function (exports, _model, _attr, _relationships, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    websiteUrl: (0, _attr.default)('string'),
    facilityKind: (0, _relationships.belongsTo)('facility-kind'),
    fipsCountyCode: (0, _attr.default)('string'),
    metropolitanStatisticalArea: (0, _attr.default)('string'),
    designatedMarketArea: (0, _attr.default)('string'),
    createdByUser: (0, _relationships.belongsTo)('user', { readOnly: true }),
    createdAt: (0, _attr.default)('utc', { readOnly: true }),
    updatedByUser: (0, _relationships.belongsTo)('user', { readOnly: true }),
    updatedAt: (0, _attr.default)('utc', { readOnly: true }),
    courses: (0, _relationships.hasMany)('course', { inverse: 'facility' }),
    facilityAddresses: (0, _relationships.hasMany)('facility-address'),
    facilityTels: (0, _relationships.hasMany)('facility-tel'),

    mapAddress: Ember.computed('facilityAddresses', function () {
      return this.get('facilityAddresses').filter(function (a) {
        return a.get('kind') && a.get('kind').get('name') === 'Physical';
      }).get('firstObject');
    }),

    displayLocation: Ember.computed('facilityAddresses', function () {
      var mapAddress = this.get('mapAddress');

      if (mapAddress) {
        return _npmUnderscore.default.compact([mapAddress.get('locality'), mapAddress.get('region') ? mapAddress.get('region').get('name') : null, mapAddress.get('region') ? mapAddress.get('region').get('country').get('name') : null]).join(' - ');
      }

      return '';
    }),

    getRegion: Ember.computed('facilityAddresses', function () {
      var mapAddress = this.get('mapAddress');

      if (mapAddress) {
        return mapAddress.get('region') ? mapAddress.get('region').get('name') : null;
      }
      return '';
    }),

    getCountry: Ember.computed('facilityAddresses', function () {
      var mapAddress = this.get('mapAddress');

      if (mapAddress) {
        return mapAddress.get('region') ? mapAddress.get('region').get('country').get('name') : null;
      }

      return '';
    }),

    displayName: Ember.computed.alias('name'),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser'),
    addresses: Ember.computed.alias('facilityAddresses'),
    kind: Ember.computed.alias('facilityKind'),
    tels: Ember.computed.alias('facilityTels')
  });
});