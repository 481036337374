define('golf-digest-panelists/facilities/new/route', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('facility');
    },


    busy: Ember.inject.service(),

    actions: {
      cancelReturn: function cancelReturn() {
        this.modelFor('facilities.new').destroyRecord();

        return this.transitionTo('facilities');
      },
      saveReturn: function saveReturn() {
        var busy = this.get('busy');
        busy.show({ message: 'Creating facility...' });

        var that = this;
        var model = this.modelFor('facilities.new');

        // First, temporarily hold aside new addresses and telephone numbers
        var addresses = [];
        model.get('facilityAddresses').map(function (a) {
          a.set('editing', false);
          addresses.push(a);
        });
        model.get('facilityAddresses').clear();
        var tels = [];
        model.get('facilityTels').map(function (t) {
          t.set('editing', false);
          tels.push(t);
        });
        model.get('facilityTels').clear();

        // Next, store the model. Finally, put the addresses tels back and save
        // those.
        return model.save().then(function () {
          return model.reload();
        }).then(function () {
          return _npmBluebird.default.all([_npmBluebird.default.all(addresses.map(function (a) {
            a.set('facility', model);
            model.get('facilityAddresses').addObject(a);

            return a.save();
          })), _npmBluebird.default.all(tels.map(function (t) {
            t.set('facility', model);
            model.get('facilityTels').addObject(t);

            return t.save();
          }))]);
        }).then(function () {
          return model.save();
        }).then(function () {
          return model.reload();
        }).then(function () {
          return that.transitionTo('facilities.facility', model.get('id'));
        }).then(function () {
          return busy.hide();
        });
      }
    }
  });
});