define('golf-digest-panelists/components/evaluation-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      Ember.run.schedule('afterRender', this, function () {
        this.send('clearErrors');
      });
    },

    actions: {
      cancel: function cancel() {
        this.get('onCancel')();
      },
      submit: function submit() {
        this.get('onSubmit')();
      },
      changeNotes: function changeNotes(notes) {
        return this.get('onChangeNotes')(notes);
      },
      changeDateExperienced: function changeDateExperienced(dateExperienced) {
        return this.get('onChangeDateExperienced')(dateExperienced);
      },
      changeCourse: function changeCourse(course) {
        return this.get('onChangeCourse')(course);
      },
      clearErrors: function clearErrors() {
        this.$('.has-error').removeClass('has-error');
        this.$('.help-block').css('display', 'none');
        this.$('.alert-danger').css('display', 'none');
      }
    }
  });
});