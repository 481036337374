define('golf-digest-panelists/components/ptp-course-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scrollingTimeout: 50,

    bindScrolling: function () {
      var self = this,
          onScroll = function onScroll() {
        Ember.run.debounce(self, self.runScrolled, self.scrollingTimeout);
      };
      Ember.$(document).on('touchmove.scrollable', onScroll);
      Ember.$(window).on('scroll.scrollable', onScroll);
    }.on('didInsertElement'),

    unbindScrolling: function () {
      Ember.$(window).off('.scrollable');
      Ember.$(document).off('.scrollable');
    }.on('willDestroyElement'),

    preservePos: function () {
      Ember.$(window).scrollTop(this.getWithDefault('currentPos', 0));
    }.on('didInsertElement'),

    runScrolled: function runScrolled() {
      this.set('currentPos', Ember.$(window).scrollTop());
      this.sendAction('setlimit', this.get('items').content.length);
    },
    actions: {
      showCourse: function showCourse(course) {
        this.sendAction('showCourse', course);
      }
    }
  });
});