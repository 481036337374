define('golf-digest-panelists/initializers/inflector', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    var inflector = _emberInflector.default.inflector;

    inflector.irregular('criterion', 'criteria');
    inflector.singular(/criteri/, 'criterion');
  }

  exports.default = {
    name: 'inflector',
    initialize: initialize
  };
});