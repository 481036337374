define('golf-digest-panelists/components/image-uploader/component', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ACCEPTED = ['image/gif', 'image/jpeg', 'image/png'];

  exports.default = Ember.Component.extend({
    actions: {
      selectFiles: function selectFiles(files) {
        for (var i = 0; i < files.length; i++) {
          var file = files[i];

          if (_npmUnderscore.default.contains(ACCEPTED, file.type)) {
            this.get('onSelectFile')(file);
          } else {
            alert('File is not an image.');
          }
        }
      },
      deleteFile: function deleteFile(file) {
        this.get('onDeleteFile')(file);
      }
    }
  });
});