define('golf-digest-panelists/components/course-search/component', ['exports', 'golf-digest-panelists/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    region: null,
    country: null,
    store: Ember.inject.service(),
    worldLayout: !!_environment.default.brand && _environment.default.brand === 'Golf Digest Panelists World',

    actions: {
      changeRegion: function changeRegion(region) {
        this.set("region", region);
      },
      changeCountry: function changeCountry(country) {
        this.set("region", null);
        this.set("country", country);
      },
      changeCourse: function changeCourse(course) {
        if (course) {
          // Reroute to course from here.
          this.sendAction("goToCourse", course);
        }
      },
      searchCourse: function searchCourse() {
        var region = this.get("region");
        var country = this.get("country");

        if (!this.worldLayout) {
          if (region) {
            var regionId = region.get("id");
            this.get("searchQuery")(regionId);
          }
        } else {
          if (region) {
            var _regionId = region.get("id");
            this.get("searchQuery")(_regionId);
          } else if (country) {
            var countryId = country.get("id");
            this.get("searchQueryCountry")(countryId);
            this.get("searchQuery")(null);
          } else {
            this.get("searchQueryCountry")(null);
            this.get("searchQuery")(null);
          }
        }
      }
    }
  });
});