define('golf-digest-panelists/terms/route', ['exports', 'golf-digest-panelists/mixins/open-route-mixin'], function (exports, _openRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_openRouteMixin.default, {
    panelLayout: true,

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      // this.render('index.footer', {outlet: 'footer'});
    }
  });
});