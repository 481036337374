define('golf-digest-panelists/password-reset/index/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    panelLayout: true,

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('index.footer', { outlet: 'footer' });
    },
    setupController: function setupController(controller) {
      controller.set('username', '');
      controller.set('requestId', null);
      controller.set('isValid', null);
      controller.set('isLocked', null);
      controller.set('questions', Ember.A());
      controller.set('questionsMessage', null);
      controller.set('responsesAreValid', false);
      controller.set('responsesMessage', false);
      controller.set('responsesSent', false);
      controller.set('sendTokenPoller', null);
      controller.set('sendTokenTaskId', null);
      controller.set('sendTokenStatus', 'Unknown');
      controller.set('sendTokenProgress', 0.00);
      controller.set('sendTokenResult', null);
      controller.set('sendTokenMessage', null);
    }
  });
});