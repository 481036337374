define('golf-digest-panelists/models/course', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'npm:underscore'], function (exports, _model, _attr, _relationships, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    facility: (0, _relationships.belongsTo)('facility'),
    ngfFacility: (0, _relationships.belongsTo)('ngf-facility'),
    ngfCourseId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    websiteUrl: (0, _attr.default)('string'),
    holeCount: (0, _attr.default)('number'),
    ballotCode: (0, _attr.default)('string'),
    ballotName: (0, _attr.default)('string'),
    courseKind: (0, _relationships.belongsTo)('course-kind'),
    courseStatus: (0, _relationships.belongsTo)('course-status'),
    designerFullName: (0, _attr.default)('string'),
    dateOpened: (0, _attr.default)('utc', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    notes: (0, _attr.default)('string'),
    awardNotes: (0, _attr.default)('string'),
    golfDigestNotes: (0, _attr.default)('string'),
    createdByUser: (0, _relationships.belongsTo)('user', { readOnly: true }),
    createdAt: (0, _attr.default)('utc', { readOnly: true }),
    updatedByUser: (0, _relationships.belongsTo)('user', { readOnly: true }),
    updatedAt: (0, _attr.default)('utc', { readOnly: true }),
    courseAddresses: (0, _relationships.hasMany)('course-address'),
    contacts: (0, _relationships.hasMany)('contact'),
    courseFairwayTurfs: (0, _relationships.hasMany)('turf'),
    coursePuttingTurfs: (0, _relationships.hasMany)('turf'),
    courseTels: (0, _relationships.hasMany)('course-tel'),
    tees: (0, _relationships.hasMany)('tee'),
    isActivelySeekingPanelists: (0, _attr.default)('boolean'),
    notesToPanelists: (0, _attr.default)('string'),
    evaluations: (0, _relationships.hasMany)('evaluation'),
    candidates: (0, _relationships.hasMany)('candidate'),
    evaluationsCount: (0, _attr.default)('number', { readOnly: true }),
    courseKindCode: (0, _attr.default)('string'),
    courseStatusCode: (0, _attr.default)('string'),

    isCandidate: Ember.computed('candidates', function () {
      var today = new Date();

      return _npmUnderscore.default.some(this.get('candidates').toArray(), function (c) {
        return c.get('survey.tabulationDate') > today && c.get('survey.dateExperiencedBegin') <= today && c.get('survey.dateExperiencedEnd') > today;
      });
    }),

    mapAddress: Ember.computed('courseAddresses', function () {
      return this.get('courseAddresses').filter(function (a) {
        return a.get('kind') && a.get('kind').get('name') === 'Physical';
      }).get('firstObject');
    }),

    displayLocation: Ember.computed('courseAddresses', function () {
      var mapAddress = this.get('mapAddress');

      if (mapAddress) {
        return _npmUnderscore.default.compact([mapAddress.get('locality'), mapAddress.get('region') ? mapAddress.get('region').get('name') : null, mapAddress.get('region') ? mapAddress.get('region').get('country').get('name') : null]).join(' - ');
      }

      return '';
    }),

    getRegion: Ember.computed('courseAddresses', function () {
      var mapAddress = this.get('mapAddress');

      if (mapAddress) {
        return mapAddress.get('region') ? mapAddress.get('region').get('name') : null;
      }
      return '';
    }),

    getCountry: Ember.computed('courseAddresses', function () {
      var mapAddress = this.get('mapAddress');

      if (mapAddress) {
        return mapAddress.get('region') ? mapAddress.get('region').get('country').get('name') : null;
      }

      return '';
    }),

    displayName: Ember.computed('facility', 'name', function () {
      var facilityName = this.get('facility').get('name');
      var courseName = this.get('name');

      if (_npmUnderscore.default.isString(facilityName) && !_npmUnderscore.default.isEmpty(facilityName.trim())) {
        if (_npmUnderscore.default.isString(courseName) && !_npmUnderscore.default.isEmpty(courseName.trim())) {
          return facilityName.trim() === courseName.trim();
        }
      }
      return true;
    }),

    shouldDisplayFacilityName: Ember.computed('displayName', function () {
      return !this.get('displayName');
    }),

    actualDisplayName: Ember.computed('facility', 'name', function () {
      var facilityName = this.get('facility').get('name');
      var courseName = this.get('name');

      if (_npmUnderscore.default.isString(facilityName) && !_npmUnderscore.default.isEmpty(facilityName.trim())) {
        if (_npmUnderscore.default.isString(courseName) && !_npmUnderscore.default.isEmpty(courseName.trim())) {
          if (facilityName.trim().toLowerCase() === courseName.trim().toLowerCase()) {

            return facilityName;
          }
        }
      }

      return facilityName + ': ' + courseName;
    }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser'),
    addresses: Ember.computed.alias('courseAddresses'),
    fairwayTurfs: Ember.computed.alias('courseFairwayTurfs'),
    kind: Ember.computed.alias('courseKind'),
    puttingTurfs: Ember.computed.alias('coursePuttingTurfs'),
    status: Ember.computed.alias('courseStatus'),
    tels: Ember.computed.alias('courseTels')
  });
});