define('golf-digest-panelists/models/review', ['exports', 'ember-data', 'ember-data-has-many-query', 'ember-cp-validations'], function (exports, _emberData, _emberDataHasManyQuery, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    course: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    rating: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    ngfRatings: {
      debounce: 500,
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('collection', true), (0, _emberCpValidations.validator)('has-many')]
    }
    // comments: {
    //   debounce: 500,
    //   validators: [
    //     validator('length', {
    //       allowBlank: true,
    //       max: 65536,
    //     }),
    //   ],
    // },
  });

  exports.default = _emberData.default.Model.extend(_emberDataHasManyQuery.default.ModelMixin, Validations, {
    user: _emberData.default.belongsTo('user', { inverse: null, readOnly: true }),
    ngfCourse: _emberData.default.belongsTo('ngf-course', { inverse: null }),
    reviewImages: _emberData.default.hasMany('review-image'),
    rating: _emberData.default.attr('number'),
    version: _emberData.default.attr('number', { readOnly: true }),
    comments: _emberData.default.attr('string'),
    averageRating: _emberData.default.attr('number'),
    adminName: _emberData.default.attr('string'),
    avatarMimeType: _emberData.default.attr('string'),
    submittedAt: _emberData.default.attr('utc', { readOnly: true }),
    oldReviewId: _emberData.default.attr('number'),
    createdByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),
    ngfRatings: _emberData.default.hasMany('ngf-rating'),
    ngfRatingstr: _emberData.default.attr('string'),
    isNew: false,
    isEditing: false,

    comment: function () {
      return this.get('comments').replace(/\n/g, "<br>");
    }.property('comments'),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser')
  });
});