define('golf-digest-panelists/components/latest-articles/article-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: 'article',
    classNames: ['clearfix'],

    didInsertElement: function didInsertElement() {
      var thumbnail = Ember.$(this.elementId + '-thumbnail');

      if (thumbnail && thumbnail.length > 0) {
        objectFit.polyfill({
          selector: '#' + this.elementId + '-thumbnail',
          fittype: 'cover',
          disableCrossDomain: true
        });
      }
    }
  });
});