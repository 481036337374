define('golf-digest-panelists/components/human-name/edit-mode/select-salutation/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    regions: [],

    internalValue: Ember.computed('value', function () {
      var _this = this;

      if (this.get('value') && this.get('salutations')) {
        return this.get('salutations').find(function (item) {
          return item.get('id') === _this.get('value').get('id');
        });
      }
    }),

    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      // Find All Salutation
      return this.get('store').findAll('salutation').then(function (salutations) {
        return _this2.set('salutations', salutations);
      }).then(function () {
        return _this2.notifyPropertyChange('value');
      });
    },


    actions: {
      /**
       * Function Change Salutation for param
       * @param {*} salutation 
       */
      changeValue: function changeValue(salutation) {
        var _this3 = this;

        if (salutation) {

          // Find and Record Salutation By ID
          return this.get('store').findRecord('salutation', salutation.get('id')).then(function (realSalutation) {
            return _this3.get('onChange')(realSalutation);
          });
        }

        this.get('onChange')(null);
      }
    }
  });
});