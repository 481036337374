define('golf-digest-panelists/surveys/survey/courses/route', ['exports', 'ember-cli-pagination/remote/route-mixin'], function (exports, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,
    session: Ember.inject.service(),

    model: function model() {
      var session = this.get('session');
      session.set('data.surveyId', this.paramsFor('surveys.survey').survey_id);
      return this.findPaged('survey', { surveyId: this.paramsFor('surveys.survey').survey_id, getCourse: true }); // Find surveys/getCourse:true
    }
  });
});