define('golf-digest-panelists/models/discussion-post', ['exports', 'ember-data', 'npm:xss', 'npm:underscore', 'moment', 'golf-digest-panelists/utils/filter-html'], function (exports, _emberData, _npmXss, _npmUnderscore, _moment, _filterHtml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    discussion: _emberData.default.belongsTo('discussion'),
    title: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    createdByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    createdAtLocal: Ember.computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).local();
    }),
    updatedByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),
    discussionPostImages: _emberData.default.hasMany('discussion-post-image'),

    author: Ember.computed.alias('createdByUser'),

    listDate: Ember.computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).local();
    }),
    thumbnailImage: Ember.computed.alias('discussionPostImages.firstObject'),

    lead: Ember.computed('body', function () {
      var body = this.get('body');
      var filtered = (0, _npmXss.default)(body, { stripIgnoreTag: true, whiteList: {} });
      var unescaped = _npmUnderscore.default.unescape(filtered);
      var topTwenty = unescaped.split(/\s/, 20);
      var ellipsis = topTwenty.length === 20 ? '...' : '';

      return _npmUnderscore.default.first(topTwenty, 20).join(' ') + ellipsis;
    }),

    safeBody: Ember.computed('body', function () {
      var body = this.get('body');

      return (0, _filterHtml.default)(body);
    }),

    topImage: Ember.computed.alias('thumbnailImage'),

    otherImages: Ember.computed('discussionPostImages', function () {
      return this.get('discussionPostImages').filter(function (i, x) {
        return x > 0;
      });
    }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser'),
    addresses: Ember.computed.alias('contactAddresses'),
    images: Ember.computed.alias('discussionPostImages')
  });
});