define('golf-digest-panelists/utils/forbidden-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var ForbiddenError = function (_Error) {
    _inherits(ForbiddenError, _Error);

    function ForbiddenError(message, details) {
      _classCallCheck(this, ForbiddenError);

      var _this = _possibleConstructorReturn(this, (ForbiddenError.__proto__ || Object.getPrototypeOf(ForbiddenError)).call(this, message));

      _this.name = _this.constructor.name;
      _this.message = message;
      _this.details = details;

      if (typeof Error.captureStackTrace === 'function') {
        Error.captureStackTrace(_this, _this.constructor);
      } else {
        _this.stack = new Error(message).stack;
      }
      return _this;
    }

    return ForbiddenError;
  }(Error);

  exports.default = ForbiddenError;
});