define('golf-digest-panelists/components/select-candidacies/pick-survey/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pick-survey', 'btn-group', 'dropup'],
    store: Ember.inject.service(),

    allSurveys: Ember.A(),

    getAllSurveys: function () {
      var _this = this;

      var store = this.get('store');

      return store.findAll('survey').then(function (surveys) {
        return _this.set('allSurveys', surveys);
      });
    }.on('init'),

    excludedSurveys: function () {
      var allSurveys = this.get('allSurveys');
      var includedSurveys = this.get('includedSurveys');

      if (!allSurveys || allSurveys.length < 1) {
        return Ember.A();
      }

      var currentSurveys = allSurveys.reject(function (s) {
        return s.get('tabulationDate') <= new Date();
      });

      if (!includedSurveys || includedSurveys.length < 1) {
        return currentSurveys;
      }

      return currentSurveys.reject(function (s) {
        return includedSurveys.isAny('survey.id', s.get('id'));
      });
    }.property('includedSurveys', 'allSurveys'),

    didRender: function didRender() {
      Ember.$('.disabled a').click(function () {
        return false;
      });
    },


    actions: {
      addCandidacy: function addCandidacy(survey) {
        this.sendAction('addCandidacy', survey);
      }
    }
  });
});