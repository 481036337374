define('golf-digest-panelists/models/contact', ['exports', 'ember-data', 'npm:underscore'], function (exports, _emberData, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    course: _emberData.default.belongsTo('course'),
    index: _emberData.default.attr('number'),
    salutation: _emberData.default.belongsTo('salutation'),
    givenName: _emberData.default.attr('string'),
    middleName: _emberData.default.attr('string'),
    surname: _emberData.default.attr('string'),
    suffix: _emberData.default.attr('string'),
    jobTitle: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),
    contactAddresses: _emberData.default.hasMany('contact-address'),
    contactTels: _emberData.default.hasMany('contact-tel'),

    contactContactKinds: _emberData.default.hasMany('contact-contact-kind'),
    surrogateContactKindsLoaded: _emberData.default.attr('boolean', { defaultValue: false }),
    surrogateContactKinds: _emberData.default.hasMany('contact-kind'),

    contactKinds: Ember.computed('contactContactKinds', 'surrogateContactKinds', function () {
      if (this.get('surrogateContactKindsLoaded') !== true) {
        var contactKinds = this.get('contactContactKinds').map(function (k) {
          return k.get('contactKind').content;
        });

        this.set('surrogateContactKinds', contactKinds);
        this.set('surrogateContactKindsLoaded', true);
      }

      return this.get('surrogateContactKinds');
    }),

    fullName: function () {
      return _npmUnderscore.default.flatten([this.get('salutation').get('name'), this.get('givenName'), this.get('middleName'), this.get('surname'), this.get('suffix')]).join(' ');
    }.property('salutation', 'givenName', 'middleName', 'surname'),

    displayName: function () {
      return _npmUnderscore.default.flatten([this.get('givenName'), this.get('surname')]).join(' ');
    }.property('givenName', 'surname'),

    displayKinds: Ember.computed('contactContactKinds', function () {
      return this.get('contactContactKinds').map(function (cck) {
        return cck.get('contactKind').get('name');
      }).join(', ');
    }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    addresses: Ember.computed.alias('contactAddresses'),
    kinds: Ember.computed.alias('contactKinds'),
    tels: Ember.computed.alias('contactTels')
  });
});