define('golf-digest-panelists/password-reset/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    passwordReset: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    busy: Ember.inject.service(),
    pollboy: Ember.inject.service(),

    username: '',
    requestId: null,
    isValid: null,
    isLocked: null,
    questions: Ember.A(),
    questionsMessage: null,

    questionsRequested: Ember.computed('requestId', function () {
      var requestId = this.get('requestId');

      return requestId !== null;
    }),

    responsesAreValid: null,
    responsesMessage: null,
    responsesSent: false,

    sendTokenTaskId: null,
    sendTokenPollInterval: 2500,
    sendTokenStatus: 'Unknown',
    sendTokenProgress: 0.00,
    sendTokenResult: null,
    sendTokenMessage: null,

    sendTokenStarting: Ember.computed('sendTokenStatus', function () {
      return this.get('sendTokenStatus') === 'Unknown';
    }),

    sendTokenRunning: Ember.computed('sendTokenStatus', function () {
      return this.get('sendTokenStatus') === 'Running';
    }),

    sendTokenFinished: Ember.computed('sendTokenStatus', function () {
      return this.get('sendTokenStatus') === 'Finished';
    }),

    /**
     * Function Update Send Token by Params
     * @param {*} task 
     */
    updateSendTokenStatus: function updateSendTokenStatus(task) {
      this.set('sendTokenTaskId', task.id);
      this.set('sendTokenStatus', task.status);
      this.set('sendTokenProgress', task.progress ? task.progress * 100 : 0);
      this.set('sendTokenResult', task.result);
      this.set('sendTokenMessage', task.message);
    },
    onSendTokenPoll: function onSendTokenPoll() {
      var that = this;
      var requestId = this.get('requestId');
      var taskId = this.get('sendTokenTaskId');
      var passwordReset = this.get('passwordReset');
      var busy = this.get('busy');
      var flash = this.get('flashMessages');

      /**
       * Function Get Send Token Status By ID
       */
      return passwordReset.getSendTokenStatus(requestId, taskId).then(function (task) {
        that.updateSendTokenStatus(task);

        if (task.progress === 1) {
          if (task.status === 'Error') {
            return that.stopSendTokenPolling().then(function () {
              return that.transitionToRoute('index');
            }).then(function () {
              return busy.hide();
            }).then(function () {
              return flash.danger(task.message, { timeout: 30000 });
            });
          } else {
            return that.stopSendTokenPolling().then(function () {
              return that.transitionToRoute('index');
            }).then(function () {
              return busy.hide();
            }).then(function () {
              return flash.success(task.message, { timeout: 30000 });
            });
          }
        }
      });
    },
    stopSendTokenPolling: function stopSendTokenPolling() {
      var poller = this.get('sendTokenPoller');
      var pollboy = this.get('pollboy');

      return new Ember.RSVP.Promise(function (resolve) {
        pollboy.remove(poller);

        return resolve();
      });
    },
    deactivate: function deactivate() {
      return this.stopSendTokenPolling();
    },


    actions: {
      submitUsername: function submitUsername() {
        var that = this;
        var busy = this.get('busy');
        var flash = this.get('flashMessages');
        var passwordReset = this.get('passwordReset');

        var username = this.get('username');

        busy.show();

        /**
         * Function Get Challenge By Username
         */
        return passwordReset.getChallenge(username).then(function (payload) {
          that.set('requestId', payload.id);
          that.set('isValid', payload.userEmailAddressIsValid);
          that.set('isLocked', payload.isLocked);
          that.set('questionsMessage', payload.message);

          var questions = that.get('questions');
          var inboundQuestions = payload.securityQuestionList || [];

          questions.clear();
          inboundQuestions.forEach(function (q) {
            questions.push({
              question: q,
              response: null
            });
          });

          busy.hide();

          if (payload.isLocked !== true && payload.userEmailAddressIsValid !== false && inboundQuestions.length === 0) {
            // Something else happened but what?

            return flash.info(payload.message, { timeout: 5000 });
          }
        }).catch(function (err) {
          busy.hide();

          return flash.warning(err.message, { timeout: 15000 });
        });
      },
      submitChallengeResponses: function submitChallengeResponses() {
        var that = this;
        var busy = this.get('busy');
        var passwordReset = this.get('passwordReset');

        var requestId = this.get('requestId');
        var questions = this.get('questions');

        busy.show({
          message: 'Sending responses to Golf Digest for verification...'
        });

        return passwordReset.sendChallengeResponses(requestId, questions.map(function (q) {
          return q.response;
        })).then(function (payload) {
          that.set('responsesSent', true);
          that.set('responsesAreValid', payload.responsesAreValid);
          that.set('responsesMessage', payload.message);

          if (payload.responsesAreValid !== true) {
            // If the responses aren't valid we will need to create a new
            // password reset request, so send the username again.
            busy.hide();

            return that.actions.submitUsername.call(that);
          }

          that.set('sendTokenTaskId', payload.taskId);

          var poller = that.get('sendTokenPoller');

          if (!poller) {
            var pollInterval = that.get('sendTokenPollInterval');
            poller = that.get('pollboy').add(that, that.onSendTokenPoll, pollInterval);
            that.set('sendTokenPoller', poller);
          }

          busy.show({ message: payload.message });
        });
      }
    }
  });
});