define('golf-digest-panelists/helpers/inc', ['exports', 'npm:is-numeric', 'npm:underscore'], function (exports, _npmIsNumeric, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inc = inc;
  function inc(params /*, hash*/) {
    var value = params[0];

    if (_npmUnderscore.default.isNumber(value)) {
      return value + 1;
    }

    if ((0, _npmIsNumeric.default)(value)) {
      return parseInt(value, 10) + 1;
    }

    return value;
  }

  exports.default = Ember.Helper.helper(inc);
});