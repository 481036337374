define('golf-digest-panelists/components/discussion-post-viewer/discussion-post-controls/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      triggerEdit: function triggerEdit() {
        return this.get('onEdit')();
      },
      triggerDelete: function triggerDelete() {
        return this.get('onDelete')();
      }
    }
  });
});