define('golf-digest-panelists/password-reset/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    panelLayout: true,

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('index.footer', { outlet: 'footer' });
    }
  });
});