define('golf-digest-panelists/components/block-module/component', ['exports', 'npm:handlebars', 'npm:handlebars.moment', 'npm:underscore', 'golf-digest-panelists/utils/inject-token', 'npm:url-parse'], function (exports, _npmHandlebars, _npmHandlebars2, _npmUnderscore, _injectToken, _npmUrlParse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['well', 'well-sm', 'block-module'],
    session: Ember.inject.service(),
    accessToken: Ember.computed.alias('session.data.authenticated.access_token'),
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      var accessToken = this.get('accessToken');
      var store = this.get('store');
      var adapter = store.adapterFor('document');

      _npmHandlebars2.default.registerHelpers(_npmHandlebars.default);

      _npmHandlebars.default.registerHelper('link-document', function (document, options) {
        if (_npmUnderscore.default.isObject(document) && document.hasOwnProperty('id') && document.hasOwnProperty('fileName') && options && _npmUnderscore.default.isFunction(options.fn)) {

          var url = adapter.buildURL('document', document.id);
          var parsed = (0, _npmUrlParse.default)(url, true);

          parsed.query['access_token'] = accessToken;
          parsed.pathname = parsed.pathname + '/' + document.fileName;

          return '<a href="' + parsed.toString() + '">' + options.fn(this) + '</a>';
        }

        var warning = 'Failed to render document-link for document:\n' + ('' + JSON.stringify(document));

        if (!_npmUnderscore.default.isObject(document)) {
          warning += '\n\tDocument argument is not an object';
        } else {
          if (!document.hasOwnProperty('id')) {
            warning += '\n\tDocument argument does not contain an \'id\'';
          }

          if (!document.hasOwnProperty('fileName')) {
            warning += '\n\tDocument argument does not contain a \'fileName\'';
          }
        }

        if (!options) {
          warning += '\n\tHandlebars options argument is not a valid object';
        } else {
          if (!options.hasOwnProperty('fn')) {
            warning += '\n\tHandlebars options argument does not contain a \'fn\'';
          } else if (!_npmUnderscore.default.isFunction(options.fn)) {
            warning += '\n\tHandlebars options argument\'s \'fn\' is not a function';
          }
        }

        Ember.Logger.warn(warning);

        return '<a href="#" onclick="(function(w){\n          w.alert([\n            \'We apologize, but this document is not available to download due\',\n            \'to an error. Please contact 100greatest@golfdigest.com for\',\n            \'assistance.\',\n          ].join(\' \'));\n        }(window))">' + options.fn(this) + '</a>';
      });
    },


    parsedBody: Ember.computed('item', 'item.body', 'item.safeBody', 'templateContext', function () {
      var block = this.get('item');

      if (block.get('safeBody') && !_npmUnderscore.default.isEmpty(block.get('safeBody'))) {
        try {
          var template = _npmHandlebars.default.compile(block.get('safeBody').toString());

          return Ember.String.htmlSafe((0, _injectToken.default)(template(this.get('templateContext') || {}), this.get('accessToken')));
        } catch (e) {
          Ember.Logger.error(e.message + '\n' + e.stack);

          return '';
        }
      }

      return '';
    })
  });
});