define('golf-digest-panelists/initializers/component-styles', ['exports', 'ember-component-css/pod-names', 'golf-digest-panelists/mixins/style-namespacing-extras'], function (exports, _podNames, _styleNamespacingExtras) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var Component = Ember.Component,
      ComponentLookup = Ember.ComponentLookup,
      computed = Ember.computed,
      alias = Ember.computed.alias,
      getOwner = Ember.getOwner;


  ComponentLookup.reopen({
    componentFor: function componentFor(name, owner) {
      owner = owner.hasRegistration ? owner : getOwner(this);

      if (_podNames.default[name] && !owner.hasRegistration('component:' + name)) {
        owner.register('component:' + name, Component);
      }
      return this._super.apply(this, arguments);
    }
  });

  Component.reopen(_styleNamespacingExtras.default, {
    styleNamespace: computed({
      get: function get() {
        return _podNames.default[this.get('_componentIdentifier')] || '';
      }
    }),

    // componentCssClassName: deprecatingAlias('styleNamespace', {
    //   id: 'ember-component-css.deprecate-componentCssClassName',
    //   until: '0.7.0',
    // }),

    componentCssClassName: alias('styleNamespace'),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('_shouldAddNamespacedClassName')) {
        this.classNames = this.classNames.concat(this.get('styleNamespace'));
      }
    }
  });

  function initialize() {}

  exports.default = {
    name: 'component-styles',
    initialize: initialize
  };
});