define('golf-digest-panelists/change-security-responses/controller', ['exports', 'golf-digest-panelists/config/environment', 'npm:bluebird'], function (exports, _environment, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var esaConf = _environment.default['ember-simple-auth'];

  exports.default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.computed(function () {
      return this.get('session').get('data.currentUserId');
    }),
    userSession: Ember.computed(function () {
      return this.get('session').get('data.authenticated.access_token');
    }),

    actions: {
      /**
       * Function Save Response Change Security 
       */
      saveResponse: function saveResponse() {
        var questions = this.get('model');
        var count = 0;
        var flash = this.get('flashMessages');
        var that = this;

        questions.forEach(function (question) {
          if (question.get('answer')) {
            count++;
          }
        });
        if (count > 2) {
          var userId = that.get('user');

          // Find and return Record By User ID
          return that.store.findRecord('user', userId).then(function (userObject) {
            return _npmBluebird.default.all(questions.map(function (question) {
              var answer = question.get('answer');
              var answerObject = that.store.createRecord('security-answer');

              if (answer) {
                answerObject.set('answerSecret', answer);
                answerObject.set('securityQuestion', question);
                answerObject.set('User', userObject);

                return answerObject.save().then(function () {
                  return that.store.unloadRecord(answerObject);
                }).then(function () {
                  return question.set('answer', '');
                });
              } else {
                // Delete that row in db so that the response is not required
                // for later password reset.

                var path = that.get('user') + '~' + question.get('id');
                var accessToken = that.get('userSession');

                return Ember.$.ajax({
                  url: _environment.default.jsonAdapterHost + '/api/v2/security-answers/' + path,
                  type: 'DELETE',
                  data: {
                    userId: userId,
                    securityQuestionId: question.get('id')
                  },
                  dataType: 'json',
                  contentType: 'application/x-www-form-urlencoded',
                  crossDomain: true,
                  headers: {
                    Authorization: 'Bearer ' + accessToken,
                    client_id: esaConf.client
                  }
                });
              }
            })).then(function () {
              return that.transitionToRoute('home').then(function () {
                return flash.success('Security question responses updated successfully.');
              });
            }).catch(function (err) {
              Ember.Logger.error(err);

              return flash.danger('A problem prevented your answers from ' + 'being saved. Please try again. Contact Golf Digest if you ' + 'continue to receive this message.');
            });
          });
        }

        flash.danger('A minimum of 3 responses must be submitted');
      }
    }
  });
});