define('golf-digest-panelists/components/table-roles/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    allRoles: Ember.A(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      return this.get('store').findAll('role').then(function (kinds) {
        _this.set('allRoles', kinds);
        var allRoles = _this.get('allRoles');
        var userRoles = _this.get('selectedItems');
        allRoles.forEach(function (roles) {
          if (userRoles && userRoles.indexOf(roles) > -1) {
            roles.set('isChecked', true);
          } else {
            roles.set('isChecked', false);
          }
        });
      });
    },


    sortProperties: ['name:asc'],
    sortedRoles: Ember.computed.sort('allRoles', 'sortProperties'),

    actions: {
      clicked: function clicked(value) {
        value.toggleProperty('isChecked');

        if (value.isChecked) {
          this.sendAction('getSelectedItems', value);
        } else {
          this.sendAction('deselect', value);
        }
      }
    }
  });
});