define('golf-digest-panelists/components/evaluation-editor/evaluation-criteria/evaluation-criterion/component', ['exports', 'npm:underscore', 'npm:is-numeric'], function (exports, _npmUnderscore, _npmIsNumeric) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    averagerValue: null,
    flashMessages: Ember.inject.service(),
    holes: Ember.A(),
    /**
     * Function Input get Score
     */
    input: function input() {
      var score = this.get('score.score');
      if (score > 10.0 || score < 1.0) {
        this.$('.help-block').css('display', 'block');
        this.$('.alert-danger').css('display', 'block');
      }
    },

    actions: {
      toggleAverager: function toggleAverager() {
        this.toggleProperty('isShowingAverager');
      },
      acceptAveragerValue: function acceptAveragerValue() {
        var flash = this.get('flashMessages');
        var avg = this.get('averagerValue');

        var holes = this.get('holes');
        var valid = true;

        var decimalPlaces = function () {
          function isInt(n) {
            return typeof n === 'number' && parseFloat(n) === parseInt(n, 10) && !isNaN(n);
          }

          return function (n) {
            var a = Math.abs(n);
            var c = a;
            var count = 1;

            while (!isInt(c) && isFinite(c)) {
              c = a * Math.pow(10, count++);
            }
            return count - 1;
          };
        }();
        holes.forEach(function (h) {
          if ((h.value > 10 || h.value < 1 || decimalPlaces(h.value) > 4) && (_npmUnderscore.default.isNumber(h.value) || (0, _npmIsNumeric.default)(h.value))) {
            valid = false;
          }
        });

        if (avg >= 1.0 && avg <= 10.0 && valid) {
          this.get('onChangeScore')(this.get('averagerValue'));
          this.send('toggleAverager');
        } else {
          flash.danger('Please review the scores. The scores should be between 1.0 to 10.0');
        }
      }
    }
  });
});