define('golf-digest-panelists/evaluations/index/route', ['exports', 'ember-cli-pagination/remote/route-mixin'], function (exports, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,

    model: function model(params) {
      return this.findPaged('evaluation', params);
    },

    actions: {
      pageClicked: function pageClicked(page) {
        this.set('page', page);
      }
    }
  });
});