define('golf-digest-panelists/components/review-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    scroller: Ember.inject.service(),

    existingReview: Ember.computed('review', function () {
      var review = this.get('review');

      return review && !review.get('isNew') && review.get('id') !== null;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var scrollTo = this.get('scrollTo');

      if (scrollTo === true || scrollTo === 'true') {
        var elem = this.$();
        var scroller = this.get('scroller');

        scroller.scrollVertical(elem);
      }
    },


    actions: {
      triggerCancel: function triggerCancel() {
        this.get('onCancel')(this.get('review'));
      },
      triggerSubmit: function triggerSubmit() {
        var review = this.get('review');
        var that = this;
        if (this.get('isNew')) {
          review.set('isNew', true);
        } else {
          review.set('isEditing', true);
        }
        review.set('ngfCourse', this.get('course'));
        this.get('store').findRecord('user', that.get('userId')).then(function (user) {
          return review.set('user', user);
        }).then(function () {
          return that.get('onSubmit')(review);
        });
      },
      triggerAddImage: function triggerAddImage(file) {
        this.get('onAddImage')(this.get('review'), file);
      },
      triggerDeleteImage: function triggerDeleteImage(file) {
        this.get('onDeleteImage')(this.get('review'), file);
      },
      onChangeRating: function onChangeRating(rating) {
        var review = this.get('review');
        review.set('rating', rating);
      }
    }
  });
});