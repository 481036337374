define('golf-digest-panelists/models/article', ['exports', 'ember-data', 'npm:xss', 'npm:underscore', 'golf-digest-panelists/utils/filter-html'], function (exports, _emberData, _npmXss, _npmUnderscore, _filterHtml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    isDraft: _emberData.default.attr('boolean'),
    publishDate: _emberData.default.attr('utc', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    unpublishDate: _emberData.default.attr('utc', { defaultValue: null }),
    createdByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),
    articleImages: _emberData.default.hasMany('article-image'),

    listDate: Ember.computed('publishDate', 'createdAt', 'updatedAt', function () {
      return this.get('publishDate') || this.get('createdAt') || this.get('updatedAt') || new Date();
    }),

    thumbnailImage: Ember.computed.alias('articleImages.firstObject'),

    lead: Ember.computed('body', function () {
      var body = this.get('body');
      var filtered = (0, _npmXss.default)(body, { stripIgnoreTag: true, whiteList: {} });
      var unescaped = _npmUnderscore.default.unescape(filtered);
      var topHundred = unescaped.split(/\s/, 100);
      var ellipsis = topHundred.length === 100 ? '...' : '';

      return _npmUnderscore.default.first(topHundred, 100).join(' ') + ellipsis;
    }),

    safeBody: Ember.computed('body', function () {
      var body = this.get('body');

      return (0, _filterHtml.default)(body);
    }),

    topImage: Ember.computed.alias('thumbnailImage'),

    otherImages: Ember.computed('articleImages', function () {
      return this.get('articleImages').filter(function (i, x) {
        return x > 0;
      });
    }),

    isDraftUnpublishedExpired: Ember.computed('publishDate', 'unpublishDate', 'isDraft', function () {
      var thisDate = Date.parse(new Date());

      return Date.parse(this.get('publishDate')) > thisDate || Date.parse(this.get('unpublishDate')) < thisDate || this.get('isDraft') !== undefined && this.get('isDraft');
    }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser'),
    images: Ember.computed.alias('articleImages')
  });
});