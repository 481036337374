define('golf-digest-panelists/models/ngf-tee', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    ngfCourse: _emberData.default.belongsTo('ngf-course', { inverse: null }),
    ngfFacilityId: _emberData.default.attr('number'),
    index: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    length: _emberData.default.attr('number'),
    par: _emberData.default.attr('number'),
    slope: _emberData.default.attr('number'),
    rating: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true })
  });
});