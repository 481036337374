define('golf-digest-panelists/components/panelist-review/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showModal: false,
    actions: {
      deleteReview: function deleteReview(review) {
        this.get('onDelete')(review);
      },
      togglePopUp: function togglePopUp() {
        this.toggleProperty('showModal');
      }
    }
  });
});