define('golf-digest-panelists/models/ignored-course', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data', 'npm:underscore'], function (exports, _model, _attr, _emberData, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    facilityName: (0, _attr.default)('string'),
    ngfFacilityId: (0, _attr.default)('number'),
    ngfCourseId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    websiteUrl: (0, _attr.default)('string'),
    holeCount: (0, _attr.default)('number'),
    courseKind: (0, _attr.default)('string'),
    courseStatus: (0, _attr.default)('string'),
    designerFullName: (0, _attr.default)('string'),
    dateOpened: (0, _attr.default)('utc', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    ignoredCourseAddresses: _emberData.default.hasMany('ignored-course-address'),
    ignoredCourseTels: _emberData.default.hasMany('ignored-course-tel'),
    ignoredTees: _emberData.default.hasMany('ignored-tee'),

    CourseKind: Ember.computed('courseKind', function () {
      var value = this.get('courseKind');
      return value ? value !== 'PR' ? value !== 'PE' ? value !== 'PN' ? value === 'DF' ? 'Daily Fee' : 'Municipal' : 'Private Non-Equity' : 'Private Equity' : 'Private' : null;
    }),

    CourseStatus: Ember.computed('courseStatus', function () {
      var value = this.get('courseStatus');
      return value === 'O' ? 'Opened' : 'Closed';
    }),

    mapAddress: Ember.computed('ignoredCourseAddresses', function () {
      return this.get('ignoredCourseAddresses').filter(function (a) {
        return a.get('kind') && a.get('kind').get('name') === 'Physical';
      }).get('firstObject');
    }),

    MailingAddress: Ember.computed('ignoredCourseAddresses', function () {
      return this.get('ignoredCourseAddresses').filter(function (a) {
        return a.get('kind') && a.get('kind').get('name') === 'Mailing';
      }).get('firstObject');
    }),

    // displayLocation: Ember.computed('ignoredCourseAddresses', function() {
    //   let mapAddress = this.get('mapAddress');

    //   if (mapAddress) {
    //     return _.compact([
    //       mapAddress.get('locality'),
    //       mapAddress.get('region') ? mapAddress.get('region').get('name') : null,
    //       mapAddress.get('region') ?
    //         mapAddress.get('region').get('country').get('name') : null,
    //     ]).join(' - ');
    //   }

    //   return '';
    // }),

    // getRegion: Ember.computed('ignoredCourseAddresses', function() {
    //   let mapAddress = this.get('mapAddress');

    //   if (mapAddress) {
    //     return mapAddress.get('region') ? mapAddress.get('region').get('name') :
    //       null;

    //   }
    //   return '';
    // }),

    // getCountry: Ember.computed('ignoredCourseAddresses', function() {
    //   let mapAddress = this.get('mapAddress');

    //   if (mapAddress) {
    //     return mapAddress.get('region') ?
    //       mapAddress.get('region').get('country').get('name') : null;

    //   }

    //   return '';
    // }),

    displayName: Ember.computed('facility', 'name', function () {
      var facilityName = this.get('facilityName');
      var courseName = this.get('name');

      if (_npmUnderscore.default.isString(facilityName) && !_npmUnderscore.default.isEmpty(facilityName.trim())) {
        if (_npmUnderscore.default.isString(courseName) && !_npmUnderscore.default.isEmpty(courseName.trim())) {
          return facilityName.trim() === courseName.trim();
        }
      }
      return true;
    })

    // shouldDisplayFacilityName: Ember.computed('displayName', function() {
    //   return !this.get('displayName');
    // }),

    // actualDisplayName: Ember.computed('facility', 'name', function() {
    //   let facilityName = this.get('facility').get('name');
    //   let courseName = this.get('name');

    //   if (_.isString(facilityName) && !_.isEmpty(facilityName.trim())) {
    //     if (_.isString(courseName) && !_.isEmpty(courseName.trim())) {
    //       if (facilityName.trim().toLowerCase() ===
    //         courseName.trim().toLowerCase()) {

    //         return facilityName;
    //       }
    //     }
    //   }

    //   return `${facilityName}: ${courseName}`;
    // }),

    // // ///////////////////////////////////////////////////////////////////////////
    // // Convenience aliases
    // tels: Ember.computed.alias('courseTels'),
  });
});