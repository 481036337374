define('golf-digest-panelists/app', ['exports', 'golf-digest-panelists/resolver', 'ember-load-initializers', 'golf-digest-panelists/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  // http://discuss.emberjs.com/t/i-need-to-render-one-template-to-another-layout/
  // 8758/4
  Ember.Route.reopen({
    session: Ember.inject.service(),
    brandTitle: _environment.default.brand || 'Golf Digest Panelists',
    panelLayout: false,
    basicLayout: false,
    formLayout: false,
    worldLayout: !!_environment.default.brand && _environment.default.brand === 'Golf Digest Panelists World',

    setupController: function setupController() {
      this._super.apply(this, arguments);

      var applicationController = this.controllerFor('application');

      applicationController.set('brandTitle', this.get('brandTitle'));
      applicationController.set('panelLayout', this.get('panelLayout'));
      applicationController.set('basicLayout', this.get('basicLayout'));
      applicationController.set('formLayout', this.get('formLayout'));
      applicationController.set('placesToPlayLayout', this.get('placesToPlayLayout'));
      applicationController.set('worldLayout', this.get('worldLayout'));
      if (this.get('session.currentUser.isDisabled') && !window.location.pathname.includes('terms')) {
        return this.transitionTo('pay-now');
      }
    }
  });

  exports.default = App;
});