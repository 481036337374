define('golf-digest-panelists/components/set-password/component', ['exports', 'password-strength', 'npm:underscore'], function (exports, _passwordStrength, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_MINIMUM_COMPLEXITY_SCORE = 1;
  var DEFAULT_RECCOMMENDED_COMPLEXITY_SCORE = 3;

  exports.default = Ember.Component.extend({
    password: '',
    confirmPassword: '',

    isEmpty: Ember.computed('password', function () {
      var password = this.get('password');

      return !_npmUnderscore.default.isString(password) || _npmUnderscore.default.isEmpty(password.trim());
    }),

    isConfirmEmpty: Ember.computed('confirmPassword', function () {
      var cPassword = this.get('confirmPassword');

      return !_npmUnderscore.default.isString(cPassword) || _npmUnderscore.default.isEmpty(cPassword.trim());
    }),

    passwordsDoNotMatch: Ember.computed('password', 'confirmPassword', function () {
      var password = this.get('password');
      var confirmPassword = this.get('confirmPassword');

      if (_npmUnderscore.default.isString(password) && _npmUnderscore.default.isString(confirmPassword)) {
        return password.trim() !== confirmPassword.trim();
      }

      return password !== confirmPassword;
    }),

    _minComplexity: Ember.computed('minimumComplexity', function () {
      var upstreamMinimum = this.get('minimumComplexity');

      if (_npmUnderscore.default.isNumber(upstreamMinimum)) {
        return upstreamMinimum;
      }

      return DEFAULT_MINIMUM_COMPLEXITY_SCORE;
    }),

    _recComplexity: Ember.computed('recommendedComplexity', function () {
      var upstreamRecommended = this.get('recommendedComplexity');

      if (_npmUnderscore.default.isNumber(upstreamRecommended)) {
        return upstreamRecommended;
      }

      return DEFAULT_RECCOMMENDED_COMPLEXITY_SCORE;
    }),

    complexity: Ember.computed('password', function () {
      var password = this.get('password');

      return (0, _passwordStrength.default)(password.trim());
    }),

    complexityScore: Ember.computed('complexity', function () {
      var complexity = this.get('complexity');

      if (complexity && complexity.hasOwnProperty('score') && _npmUnderscore.default.isNumber(complexity.score)) {

        return complexity.score;
      }

      return 0;
    }),

    contributedComplexityWarning: Ember.computed('complexity', function () {
      var complexity = this.get('complexity');

      if (complexity && complexity.hasOwnProperty('feedback') && complexity.feedback.hasOwnProperty('warning') && _npmUnderscore.default.isString(complexity.feedback.warning) && _npmUnderscore.default.isEmpty(complexity.feedback.warning.trim())) {

        return complexity.feedback.warning.trim();
      }

      return null;
    }),

    isNotMinimallyComplex: Ember.computed('_minComplexity', 'complexityScore', function () {
      var minComplexity = this.get('_minComplexity');
      var complexityScore = this.get('complexityScore');

      return complexityScore < minComplexity;
    }),

    isNotOfRecommendedComplexity: Ember.computed('_recComplexity', 'complexityScore', function () {
      var recComplexity = this.get('_recComplexity');
      var complexityScore = this.get('complexityScore');

      return complexityScore < recComplexity;
    }),

    lowComplexityWarning: Ember.computed('contributedComplexityWarning', 'isNotMinimallyComplex', 'isNotOfRecommendedComplexity', 'strengthWarning', function () {
      var upstreamWarning = this.get('strengthWarning');

      if (upstreamWarning && _npmUnderscore.default.isString(upstreamWarning) && !_npmUnderscore.default.isEmpty(upstreamWarning)) {

        return upstreamWarning;
      }

      var isNotMinimallyComplex = this.get('isNotMinimallyComplex');
      var isNotOfRecommendedComplexity = this.get('isNotOfRecommendedComplexity');
      var contribWarning = this.get('contributedComplexityWarning');

      if (contribWarning) {
        return contribWarning;
      }

      if (isNotMinimallyComplex) {
        return 'The password you have entered is too simple.';
      }

      if (isNotOfRecommendedComplexity) {
        return 'Your password is okay, but it could be even better.';
      }

      return null;
    }),

    suggestions: Ember.computed('complexity', 'strengthSuggestions', function () {
      var upstreamSuggestions = this.get('strengthSuggestions');

      if (upstreamSuggestions && _npmUnderscore.default.isArray(upstreamSuggestions) && !_npmUnderscore.default.isEmpty(upstreamSuggestions)) {

        return upstreamSuggestions;
      }

      var complexity = this.get('complexity');

      if (complexity && complexity.hasOwnProperty('feedback') && complexity.feedback.hasOwnProperty('suggestions') && _npmUnderscore.default.isArray(complexity.feedback.suggestions)) {

        return complexity.feedback.suggestions;
      }

      return [];
    }),

    actions: {
      submit: function submit() {
        var password = this.get('password');
        var confirmPassword = this.get('confirmPassword');

        return this.sendAction('submit', password, confirmPassword);
      },
      cancel: function cancel() {
        this.set('password', '');
        this.set('confirmPassword', '');

        return this.sendAction('cancel');
      }
    }
  });
});