define('golf-digest-panelists/users/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('user');
    },


    actions: {
      changeSalutation: function changeSalutation(salutation) {
        this.modelFor('users.new').set('salutation', salutation);
      }
    }
  });
});