define('golf-digest-panelists/components/list-contact/component', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'section',

    personalAddressKinds: Ember.A(),
    personalTelKinds: Ember.A(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      return _npmBluebird.default.all([
      // Get Store and Find All Personal Address Kind 
      this.get('store').findAll('personal-address-kind').then(function (kinds) {
        return _this.set('personalAddressKinds', kinds);
      }),
      // Get Store and Find All Personal Tel Kind 
      this.get('store').findAll('personal-tel-kind').then(function (kinds) {
        return _this.set('personalTelKinds', kinds);
      })]);
    },


    actions: {
      create: function create() {
        this.sendAction('create');
      },
      toggleEdit: function toggleEdit(item) {
        this.sendAction('toggleEdit', item);
      },
      save: function save(item) {
        var index = this.get('items').indexOf(item);

        item.set('index', index);

        this.sendAction('save', item);
      },
      delete: function _delete(item) {
        this.sendAction('delete', item);
      },
      changeSalutation: function changeSalutation(item, salutation) {
        this.sendAction('changeSalutation', item, salutation);
      },
      changeKinds: function changeKinds(item, kinds) {
        this.sendAction('changeKinds', item, kinds);
      },
      createTel: function createTel(item) {
        this.sendAction('createTel', item);
      },
      toggleEditTel: function toggleEditTel(item, tel) {
        this.sendAction('toggleEditTel', item, tel);
      },
      saveTel: function saveTel(item, tel) {
        this.sendAction('saveTel', item, tel);
      },
      deleteTel: function deleteTel(item, tel) {
        this.sendAction('deleteTel', item, tel);
      },
      changeTelKind: function changeTelKind(item, tel, kind) {
        this.sendAction('changeTelKind', item, tel, kind);
      },
      createAddress: function createAddress(item) {
        this.sendAction('createAddress', item);
      },
      toggleEditAddress: function toggleEditAddress(item, address) {
        this.sendAction('toggleEditAddress', item, address);
      },
      saveAddress: function saveAddress(item, address) {
        this.sendAction('saveAddress', item, address);
      },
      deleteAddress: function deleteAddress(item, address) {
        this.sendAction('deleteAddress', item, address);
      },
      changeAddressKind: function changeAddressKind(item, address, kind) {
        this.sendAction('changeAddressKind', item, address, kind);
      },
      changeAddressRegion: function changeAddressRegion(item, address, region) {
        this.sendAction('changeAddressRegion', item, address, region);
      }
    }
  });
});