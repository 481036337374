define('golf-digest-panelists/components/evaluation-editor/evaluation-instructions/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['well', 'well-lg']
  });
});