define('golf-digest-panelists/models/security-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    question: _emberData.default.attr('string'),
    //Just to make changes in user security question
    //responses
    answer: _emberData.default.attr('string')
  });
});