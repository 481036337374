define('golf-digest-panelists/components/lazy-image/component', ['exports', 'ember-lazy-image/components/lazy-image'], function (exports, _lazyImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _lazyImage.default.extend({
    viewportOptionsOverride: Ember.on('didInsertElement', function () {
      Ember.setProperties(this, {
        viewportUseRAF: true,
        viewportSpy: false,
        viewportRefreshRate: 150,
        viewportTolerance: {
          top: 50,
          bottom: 50,
          left: 20,
          right: 20
        }
      });
    })
  });
});