define('golf-digest-panelists/models/ngf-facility', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    websiteUrl: _emberData.default.attr('string'),
    ngfFacilityKind: _emberData.default.belongsTo('ngf-facility-kind'),
    fipsCountyCode: _emberData.default.attr('string'),
    metropolitanStatisticalArea: _emberData.default.attr('string'),
    designatedMarketArea: _emberData.default.attr('string'),
    createdByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedByUser: _emberData.default.belongsTo('user', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),
    courses: _emberData.default.hasMany('course', { inverse: 'ngfFacility' }),
    ngfFacilityAddresses: _emberData.default.hasMany('ngf-facility-address'),
    ngfFacilityTels: _emberData.default.hasMany('ngf-facility-tel'),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser'),
    addresses: Ember.computed.alias('ngfFacilityAddresses'),
    kind: Ember.computed.alias('ngfFacilityKind'),
    tels: Ember.computed.alias('ngfFacilityTels')
  });
});