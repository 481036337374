define('golf-digest-panelists/compare-survey-results/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    store: Ember.inject.service(),

    model: function model() {
      var store = this.get('store');

      return Ember.RSVP.hash({
        surveys: store.findAll('survey')
      });
    }
  });
});