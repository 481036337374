define('golf-digest-panelists/ptp/review/index/route', ['exports', 'npm:bluebird', 'npm:underscore'], function (exports, _npmBluebird, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    busy: Ember.inject.service(),
    queryParams: {
      jumpToEditMode: {
        refreshModel: false,
        replace: true
      }
    },

    placesToPlayLayout: true,
    jumpToEditMode: false,

    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),
    userId: Ember.computed.alias('session.data.currentUser.id'),

    duplicate: function duplicate(oldObject) {
      var newObject = oldObject.toJSON();
      for (var key in newObject) {
        if (newObject.hasOwnProperty(key)) {
          if (newObject[key] !== oldObject.get(key)) {
            newObject[key] = oldObject.get(key);
          }
        }
      }
      newObject.id = null;
      return newObject;
    },
    model: function model(params) {
      this.set('jumpToEditMode', params.jumpToEditMode === true || params.jumpToEditMode === 'true');

      var ngfCourseId = this.paramsFor('ptp.review').ngf_course_id || this.paramsFor('ptp.review').id;

      var that = this;
      var userId = this.get('userId');

      return that.get('store').query('review', { ngfCourseId: ngfCourseId }).then(function (reviews) {

        var featuredStaffReview = reviews.find(function (r) {
          var rUser = r.get('user');

          return rUser.get('isArchitecturalEditor');
        });

        var currentUserReview = reviews.find(function (r) {
          var rUser = r.get('user');
          var rUserId = parseInt(rUser.get('id'), 10);

          return rUserId === userId;
        });

        var review = that.store.createRecord('review');
        var ngfRatings = void 0;
        var ngfCriteria1 = void 0;
        var existingScores = [];
        if (currentUserReview) {
          ngfRatings = currentUserReview.get('ngfRatings').map(function (score) {
            return score;
          });
          ngfRatings.forEach(function (x) {
            existingScores.push({
              criteria: x.get('ngfCriterion.name'),
              rating: x.get('ngfrating')
            });
          });
          currentUserReview.get('ngfRatings').clear();
        }
        return that.store.query('ngfCriterion', { Disabled: 1 }).then(function (ngfCriteria) {
          ngfCriteria.forEach(function (ngfCriterion) {
            if (currentUserReview) {
              var ngfR = _npmUnderscore.default.find(ngfRatings, function (s) {
                return s.get('ngfCriterion.name') === ngfCriterion.get('name');
              });
              if (ngfR) {
                // Put the score back into the evaluation.
                currentUserReview.get('ngfRatings').pushObject(ngfR);
              } else {
                var newRating = that.store.createRecord('ngfRating');
                newRating.set('ngfCriterion', ngfCriterion);
                newRating.set('review', currentUserReview);
                currentUserReview.get('ngfRatings').pushObject(newRating);
              }
            } else {
              var _newRating = that.store.createRecord('ngfRating');
              _newRating.set('ngfCriterion', ngfCriterion);
              _newRating.set('review', review);
              review.get('ngfRatings').pushObject(_newRating);
            }
          });
          ngfCriteria1 = ngfCriteria;
        }).then(function () {
          return Ember.RSVP.hash({

            course: that.store.findRecord('ngf-course', ngfCourseId),
            featuredStaffReview: featuredStaffReview,
            staffReviews: reviews.filter(function (r) {
              if (r.get('avatarMimeType')) {
                r.get('user').set('avatarMimeType', r.get('avatarMimeType'));
              }

              if (r.get('adminName')) {
                r.get('user').set('displayName', r.get('adminName'));
              }

              var rUser = r.get('user');
              var rUserId = parseInt(rUser.get('id'), 10);

              return rUserId !== userId && rUser.get('isAdmin') && (!featuredStaffReview || r.get('id') !== featuredStaffReview.get('id'));
            }),
            userReviews: reviews.filter(function (r) {
              var rUser = r.get('user');
              var rUserId = parseInt(rUser.get('id'), 10);

              return rUserId !== userId && !rUser.get('isAdmin') && (!featuredStaffReview || r.get('id') !== featuredStaffReview.get('id'));
            }),
            currentUserReview: currentUserReview,
            newReview: review,
            ngfCriteria: ngfCriteria1,
            edit: currentUserReview !== undefined,
            existingScores: existingScores
          });
        });
      });
    },
    resetController: function resetController() {
      var newReview = this.currentModel.newReview;
      var currentUserReview = this.currentModel.currentUserReview;
      this.controller.set('jumpToEditMode', null);

      if (newReview) {
        newReview.rollbackAttributes();
        newReview.set('isEditing', false);
      }

      if (currentUserReview) {
        currentUserReview.rollbackAttributes();
        currentUserReview.set('isEditing', false);
      }
    },


    actions: {
      didTransition: function didTransition() {
        var shouldJump = this.get('jumpToEditMode');

        if (shouldJump === true) {
          this.actions.jumpToEditMode.call(this);
        } else {
          this.controller.set('jumpToEditMode', null);
        }
      },
      jumpToEditMode: function jumpToEditMode() {
        var model = this.currentModel;

        if (model.currentUserReview) {
          model.currentUserReview.set('isEditing', true);
        } else {
          model.newReview.set('isEditing', false);
        }
      },
      toggleEdit: function toggleEdit(review) {
        this.controller.set('jumpToEditMode', null);

        review.rollbackAttributes();
        review.toggleProperty('isEditing');
      },
      submit: function submit(review) {
        this.controller.set('jumpToEditMode', null);

        var flash = this.get('flashMessages');
        var busy = this.get('busy');
        var that = this;

        var comments = review.get('comments');
        var rating = review.get('rating');
        var ngfrating = review.get('ngfRatings');
        var isFeaturedStaff = this.get('cap').canCreateUnratedPtpReviews;

        if (!comments || comments.trim().length < 1) {
          flash.danger('Please enter a comment!');
          return;
        } else if ((!rating || rating < 1 || rating > 5) && !isFeaturedStaff) {
          flash.danger('Please select a rating between 1 and 5 stars!');
          return;
        } else if ((!ngfrating || ngfrating < 1 || ngfrating > 5) && !isFeaturedStaff) {
          flash.danger('Please select a ngfrating between 1 and 5 stars!');
          return;
        } else {
          busy.show({ message: 'Saving your course review...' });
          if (review.get('isNew')) {
            review.set('createdBy', this.get('session.data.currentUser'));

            // Temporarily yank out all images. We need to save just the review first
            var images = review.get('reviewImages').map(function (image) {
              return image;
            }); // Clone hack
            review.get('reviewImages').clear();

            var ngfRatings = review.get('ngfRatings').map(function (ngfRating) {
              return ngfRating;
            }); // Clone hack
            review.get('ngfRatings').clear();

            var ngfRatingString = JSON.stringify(ngfRatings);
            review.set('ngfRatingstr', ngfRatingString);

            return review.save().then(function () {
              return review.reload();
            }).then(function () {
              images.forEach(function (image) {
                // Now that we have the review ID via the reload call we can use it
                image.set('review', review);

                // Put the image back into the review.
                review.get('reviewImages').pushObject(image);
              });

              return _npmBluebird.default.all(review.get('reviewImages').map(function (image, index) {
                image.set('index', index);

                if (index > 9) {
                  review.get('reviewImages').removeObject(image);
                  flash.danger('You are allowed to upload a maximum of 10 photos!');
                  return;
                }

                return image.save().then(function () {
                  return image.reload();
                }).then(function () {
                  return image.set('fileData', undefined);
                }).catch(function (err) {
                  review.get('reviewImages').removeObject(image);
                  flash.danger(err);
                });
              }));
            }).then(function () {
              return review.reload();
            }).then(function () {
              //Ember.set(that.currentModel, 'newReview', null);
              review.set('isNew', false);
              busy.hide();
              that.refresh();
            }).catch(function (err) {
              Ember.Logger.info(err);
              review.set('isNew', false);
              flash.danger(err);
              busy.hide();
              that.refresh();
            });
          }

          if (review.get('isEditing')) {
            var reviewXXX = this.duplicate(review);

            // Temporarily yank out all images. We need to save just the review first
            var _images = review.get('reviewImages').map(function (image) {
              return image;
            }); // Clone hack
            review.get('reviewImages').clear();
            var delImg = void 0;
            if (review.get('deletedImages')) {

              delImg = review.get('deletedImages').map(function (image) {
                return image;
              }); // Clone hack
              review.get('deletedImages').clear();
            }

            var reviewYYY = this.store.createRecord('review', reviewXXX);
            var newScores = review.get('ngfRatings').map(function (score) {
              return score;
            });
            var scoreString = JSON.stringify(newScores);
            review.set('ngfRatingstr', scoreString);
            newScores.forEach(function (score) {
              var newScore = that.store.createRecord('ngfRating', {
                ngfrating: score.get('ngfrating'),
                ngfCriterion: score.get('ngfCriterion')
              });
              reviewYYY.get('ngfRatings').pushObject(newScore);
            });

            // Temporarily yank out all scores. We need to save just the evaluation
            // first
            var scores = reviewYYY.get('ngfRatings').map(function (score) {
              return score;
            }); // Clone hack
            scoreString = JSON.stringify(scores);

            reviewYYY.set('ngfRatingstr', scoreString);
            reviewYYY.get('ngfRatings').clear();

            reviewYYY.set('ngfCourse', this.currentModel.course);
            review.deleteRecord();
            reviewYYY.set('oldReviewId', review.get('id'));
            //Error: Attempted to handle event `reloadRecord` on <review-image:1238> while in state root.deleted.saved. Called with function (value) 
            //{ if (resolved) { return; } resolved = true; resolve(promise, value); }.
            return reviewYYY.save().then(function () {
              return reviewYYY.reload();
            }).then(function () {
              reviewYYY.set('oldReviewId', null);
              reviewYYY.get('reviewImages').clear();
              _images.forEach(function (image) {
                // Now that we have the review ID via the reload call we can use it
                image.set('review', reviewYYY);
                // Put the image back into the review.
                reviewYYY.get('reviewImages').pushObject(image);
              });

              if (delImg) {
                delImg.forEach(function (image) {
                  // Now that we have the review ID via the reload call we can use it
                  image.set('review', reviewYYY);
                  // Put the image back into the review.
                  if (!reviewYYY.get('deletedImages')) {
                    reviewYYY.set('deletedImages', []);
                  }
                  reviewYYY.get('deletedImages').pushObject(image);
                });
              }

              // Handle deleted images, if there are any.

              if (reviewYYY.get('deletedImages')) {
                return Ember.RSVP.all(reviewYYY.get('deletedImages').map(function (del) {
                  if (del.get('id') !== null) {
                    return del.save();
                  }
                }));
              }

              return Ember.RSVP.resolve();
            }).then(function () {
              if (reviewYYY.get('deletedImages')) {
                reviewYYY.get('deletedImages').clear();
              }
              // Handle new/existing images.
              return _npmBluebird.default.all(reviewYYY.get('reviewImages').map(function (image, index) {
                if (image.get('isDeleted')) {
                  return true;
                }

                image.set('index', index);

                if (index > 9) {
                  reviewYYY.get('reviewImages').removeObject(image);
                  flash.danger('You are allowed to upload a maximum of 10 photos!');
                  return;
                }

                return image.save().then(function () {
                  return image.reload();
                }).then(function () {
                  return image.set('fileData', undefined);
                }).catch(function (err) {
                  reviewYYY.get('reviewImages').removeObject(image);
                  flash.danger(err);
                });
              }));
            }).then(function () {
              reviewYYY.set('isEditing', false);
              busy.hide();
              that.refresh();
            }).catch(function (err) {
              Ember.Logger.info(err);
              reviewYYY.set('isEditing', false);
              flash.danger(err);
              busy.hide();
              that.refresh();
            });
          }
        }
      },
      addImage: function addImage(review, file) {
        var that = this;

        return new _npmBluebird.default(function (resolve, reject) {
          var reader = new FileReader();
          reader.onload = function (e) {
            return resolve(e.target.result);
          };
          reader.onerror = function (err) {
            return reject(err);
          };
          reader.onabort = function () {
            return reject(new Error('File read aborted'));
          };

          reader.readAsDataURL(file);
        }).then(function (data) {
          var image = that.store.createRecord('review-image');
          image.set('review', review);
          image.set('fileName', file.name);
          image.set('mimeType', file.type);
          image.set('fileData', data);

          review.get('reviewImages').pushObject(image);
        });
      },
      deleteImage: function deleteImage(review, image) {
        review.get('reviewImages').removeObject(image);

        if (!review.get('deletedImages')) {
          review.set('deletedImages', []);
        }

        review.get('deletedImages').pushObject(image);

        image.deleteRecord();
      },
      deleteReview: function deleteReview(review) {
        var that = this;
        return review.destroyRecord().then(function () {
          return that.refresh();
        });
      }
    }
  });
});