define('golf-digest-panelists/articles/index/route', ['exports', 'ember-cli-pagination/remote/route-mixin'], function (exports, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeMixin.default, {
    perPage: 25,

    model: function model(params) {
      // Access article by params
      return this.findPaged('article', params);
    },

    actions: {

      // Access Course By Id
      goToCourse: function goToCourse(course) {
        this.transitionTo('courses.course', course.get('id'));
      },
      // Redirect Course per Region By Id
      regionRedirect: function regionRedirect(regionId) {
        this.transitionTo('courses', { queryParams: { RegionId: regionId } });
      },
      // Redirect Course per Country By Id
      countryRedirect: function countryRedirect(countryId) {
        this.transitionTo('courses', { queryParams: { CountryId: countryId } });
      }
    }
  });
});