define('golf-digest-panelists/models/document', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    index: (0, _attr.default)('number'),
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    fileName: (0, _attr.default)('string'),
    mimeType: (0, _attr.default)('string', { readOnly: true }),
    isDraft: (0, _attr.default)('boolean'),
    publishDate: (0, _attr.default)('utc', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    unpublishDate: (0, _attr.default)('utc'),
    createdByUser: (0, _relationships.belongsTo)('user', { readOnly: true }),
    createdAt: (0, _attr.default)('utc', { readOnly: true }),
    updatedByUser: (0, _relationships.belongsTo)('user', { readOnly: true }),
    updatedAt: (0, _attr.default)('utc', { readOnly: true }),

    baseUrl: Ember.computed(function () {
      var adapter = this.store.adapterFor('document');

      return '' + adapter.buildURL('document', this.id);
    }),

    url: Ember.computed('fileName', 'baseUrl', function () {
      var baseUrl = this.get('baseUrl');
      var fileName = this.get('fileName');

      return baseUrl + '/' + fileName;
    }),

    fileData: (0, _attr.default)('raw'),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    createdBy: Ember.computed.alias('createdByUser'),
    updatedBy: Ember.computed.alias('updatedByUser')
  });
});