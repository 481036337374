define('golf-digest-panelists/evaluations/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'perPage', 'UserId'],
    session: Ember.inject.service(),
    totalPages: Ember.computed.alias('content.totalPages'),

    page: 1,
    perPage: 25,
    UserId: null,

    user: Ember.computed(function () {
      var id = this.get('session').get('data.currentUserId');
      this.set('UserId', id);
    })

  });
});