define('golf-digest-panelists/components/course-alpha-navigate/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    courseChar: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var query = null;

      if (this.get('courseParams')) {
        query = this.get('courseParams').query;
      }

      this.get('store').query('course-starts-with', { ngfCourses: this.get('ngfCourses'), query: query, ptp: this.get('ptp') }).then(function (courseChar) {
        _this.set('courseChar', courseChar.uniqBy('name'));
        var courseChars = _this.get('courseChar');
        courseChars.forEach(function (char) {
          char.set('isActive', false);
          if (char.get('name') === query.startsWith) {
            char.set('isActive', true);
          }
        });
      });
    },


    actions: {
      clear: function clear() {
        this.sendAction('search', '');
        var courseChars = this.get('courseChar');
        courseChars.forEach(function (courseChar) {
          courseChar.set('isActive', false);
        });
        this.set('refresh', true);
      },
      search: function search(char) {
        var courseChars = this.get('courseChar');
        courseChars.forEach(function (courseChar) {
          courseChar.set('isActive', false);
        });
        this.set('refresh', false);
        char.set('isActive', true);
        this.sendAction('search', char);
      }
    }
  });
});