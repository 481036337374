define('golf-digest-panelists/models/contact-contact-kind', ['exports', 'ember-data/model', 'ember-data'], function (exports, _model, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    contact: _emberData.default.belongsTo('contact'),
    contactKind: _emberData.default.belongsTo('contact-kind')
  });
});