define('golf-digest-panelists/components/document-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['table', 'document-table'],
    cap: Ember.computed.alias('session.data.cap'),
    session: Ember.inject.service()
  });
});