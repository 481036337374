define('golf-digest-panelists/courses/new/route', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    FacilityId: null,

    /**
     * Function before Model by ID
     * @param {*} params 
     */
    beforeModel: function beforeModel(params) {
      this.set('FacilityId', params.queryParams.FacilityId);
    },


    /**
     * Function Create Course Record
     */
    model: function model() {
      return this.store.createRecord('course');
    },


    busy: Ember.inject.service(),

    /**
     * Function Store Contact by params
     * @param {*} contact 
     * @param {*} course 
     */
    _storeContact: function _storeContact(contact, course) {
      var _this = this;

      var cck = contact.get('contactContactKinds');
      var surrogateCk = contact.get('surrogateContactKinds');

      contact.set('course', course);

      return contact.save().then(function () {
        return contact.reload();
      }).then(function () {
        return _npmBluebird.default.map(cck.toArray(), function (cckItem) {
          var cckContactKindId = cckItem.get('contactKind').get('id');

          if (!surrogateCk.find(function (sckItem) {
            return sckItem.get('id') === cckContactKindId;
          })) {
            return cckItem.destroyRecord();
          }

          return _npmBluebird.default.resolve();
        });
      }).then(function () {
        return contact.reload();
      }).then(function () {
        return _npmBluebird.default.map(surrogateCk.toArray(), function (sckItem) {
          var sckContactKindId = sckItem.get('id');

          if (!cck.find(function (cckItem) {
            return cckItem.get('contactKind').get('id') === sckContactKindId;
          })) {
            var newCck = _this.store.createRecord('contact-contact-kind');
            newCck.set('contact', contact);
            newCck.set('contactKind', sckItem);

            return newCck.save();
          }

          return _npmBluebird.default.resolve();
        });
      }).then(function () {
        return (
          // Go through all of the telephone numbers and addresses that are
          // either new or still in editing mode and save them.

          _npmBluebird.default.all([contact.get('addresses').map(function (a) {
            a.set('contact', contact);
            a.set('editing', false);
            return a.save();
          }), contact.get('tels').map(function (t) {
            t.set('contact', contact);
            t.set('editing', false);
            return t.save();
          })])
        );
      });
    },


    actions: {
      cancelReturn: function cancelReturn() {
        var newModel = this.modelFor('courses.new');

        newModel.destroyRecord();

        if (this.get('FacilityId')) {
          return this.transitionTo('facilities.facility', this.get('FacilityId'));
        }

        return this.transitionTo('facilities');
      },
      saveReturn: function saveReturn() {
        var busy = this.get('busy');
        busy.show({ message: 'Creating course...' });

        var that = this;
        var model = this.modelFor('courses.new');
        var facility = this.store.peekRecord('facility', this.get('FacilityId'));

        model.set('facility', facility);

        // First, temporarily hold aside new contacts, addresses, and
        // telephone numbers
        var contacts = [];
        model.get('contacts').map(function (c) {
          c.set('editing', false);
          contacts.push(c);
        });
        model.get('contacts').clear();
        var addresses = [];
        model.get('courseAddresses').map(function (a) {
          a.set('editing', false);
          addresses.push(a);
        });
        model.get('courseAddresses').clear();
        var tels = [];
        model.get('courseTels').map(function (t) {
          t.set('editing', false);
          tels.push(t);
        });
        model.get('courseTels').clear();

        // Next, store the model. Finally, put the contacts, addresses, and tels
        // back and save those.
        return model.save().then(function () {
          return model.reload();
        }).then(function () {
          return _npmBluebird.default.all([_npmBluebird.default.all(contacts.map(function (c) {
            model.get('contacts').addObject(c);

            return that._storeContact(c, model);
          })), _npmBluebird.default.all(addresses.map(function (a) {
            a.set('course', model);
            model.get('courseAddresses').addObject(a);

            return a.save();
          })), _npmBluebird.default.all(tels.map(function (t) {
            t.set('course', model);
            model.get('courseTels').addObject(t);

            return t.save();
          }))]);
        }).then(function () {
          return model.save();
        }).then(function () {
          return model.reload();
        }).then(function () {
          return that.transitionTo('courses.course', model.get('id'));
        }).then(function () {
          return busy.hide();
        });
      }
    }
  });
});