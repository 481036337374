define('golf-digest-panelists/articles/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'perPage'],

    totalPages: Ember.computed.alias('content.totalPages'),

    page: 1,
    perPage: 25,

    // Capture click event on page and set property
    actions: {
      pageClicked: function pageClicked(page) {
        this.setProperties({ page: page });
      }
    }
  });
});