define('golf-digest-panelists/compare-survey-results/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    surveyComparison: Ember.inject.service(),
    pollboy: Ember.inject.service(),

    selectedSurveys: Ember.A(),

    min: 2,
    max: 3,

    submitted: false,
    taskId: null,
    pollInterval: 2500,

    status: 'Unknown',
    progress: 0.00,
    result: null,
    message: null,

    starting: Ember.computed('status', function () {
      return this.get('status') === 'Unknown';
    }),

    running: Ember.computed('status', function () {
      return this.get('status') === 'Running';
    }),

    finished: Ember.computed('status', function () {
      return this.get('status') === 'Finished';
    }),

    /**
     * Function Update Status Compare Survey Result for param
     * @param {*} task 
     */
    updateStatus: function updateStatus(task) {
      this.set('taskId', task.id);
      this.set('status', task.status);
      this.set('progress', task.progress ? task.progress * 100 : 0);
      this.set('result', task.result);
      this.set('message', task.message);
    },
    onPoll: function onPoll() {
      var that = this;
      var taskId = this.get('taskId');
      var surveyComparison = this.get('surveyComparison');

      return surveyComparison.getStatus(taskId).then(function (task) {
        that.updateStatus(task);

        if (task.progress === 1) {
          return that.stopPolling();
        }
      });
    },
    stopPolling: function stopPolling() {
      var poller = this.get('poller');
      return this.get('pollboy').remove(poller);
    },
    deactivate: function deactivate() {
      return this.stopPolling();
    },


    actions: {
      beginTask: function beginTask() {
        var that = this;

        var selectedSurveys = this.get('selectedSurveys');
        var surveyIds = selectedSurveys.mapBy('id');
        var count = surveyIds.length;

        var min = this.get('min') || 0;
        var max = this.get('max') || 0;

        var go = true;

        if (min > 0 && count < min) {
          go = false;
          this.set('hasTooFew', true);
        } else {
          this.set('hasTooFew', false);
        }

        if (max > 0 && count > max) {
          go = false;
          this.set('hasTooMany', true);
        } else {
          this.set('hasTooMany', false);
        }

        if (go === true && !this.get('submitted')) {
          this.set('submitted', true);

          var surveyComparison = this.get('surveyComparison');
          var pollInterval = this.get('pollInterval');

          return surveyComparison.start(surveyIds).then(function (task) {
            that.updateStatus(task);

            var poller = that.get('poller');

            if (!poller) {
              poller = that.get('pollboy').add(that, that.onPoll, pollInterval);
              that.set('poller', poller);
            }
          });
        }
      },
      getSpreadsheet: function getSpreadsheet() {
        var taskId = this.get('taskId');
        var surveyComparison = this.get('surveyComparison');

        return surveyComparison.download(taskId);
      }
    }
  });
});