define('golf-digest-panelists/components/ngfcourses-report/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['table', 'user-details', 'table-striped', 'table-condensed'],

    actions: {
      addCourse: function addCourse(course) {
        this.sendAction('addCourse', course);
      },
      ignoreCourse: function ignoreCourse(course) {
        this.sendAction('ignoreCourse', course);
      },
      link: function link(course, ngfCourse) {
        this.sendAction('link', course, ngfCourse);
      },
      unlink: function unlink(ngfCourse) {
        this.sendAction('unlink', ngfCourse);
      }
    }
  });
});