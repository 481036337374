define('golf-digest-panelists/components/survey-date/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showError: false,
    todayDate: (0, _moment.default)(),

    actions: {
      change: function change(value) {
        if (value) {
          this.set('value', value.toDate());
          this.$('.date.help-block').empty();
          this.$('.date.help-block').css('display', 'none');
        } else {
          this.set('showError', 'true');
          this.$('.date.help-block').css('display', 'block');
          this.$('.date.help-block').append('<p>This field can\'t be blank</p>');
        }
      }
    }
  });
});