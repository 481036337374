define('golf-digest-panelists/utils/permissions', ['exports', 'npm:underscore'], function (exports, _npmUnderscore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    join: function join() {
      var args = _npmUnderscore.default.uniq(_npmUnderscore.default.compact(_npmUnderscore.default.flatten(Array.prototype.slice.call(arguments))));
      var isTrue = false;

      if (isTrue === true) {
        return true;
      }

      return args;
    },


    ARTICLES_CREATE: 'Create articles',
    ARTICLES_READ: ['Create articles', 'View articles'],
    ARTICLES_UPDATE: 'Create articles',
    ARTICLES_DELETE: 'Create articles',

    BLOCKS_CREATE: 'Manage blocks',
    BLOCKS_READ: ['Manage blocks', 'View blocks'],
    BLOCKS_UPDATE: 'Manage blocks',
    BLOCKS_DELETE: 'Manage blocks',

    CONTACT_KINDS_CREATE: 'Manage contact kinds',
    CONTACT_KINDS_READ: ['Manage contact kinds', 'Manage courses'],
    CONTACT_KINDS_UPDATE: 'Manage contact kinds',
    CONTACT_KINDS_DELETE: 'Manage contact kinds',

    COUNTRIES_CREATE: 'Manage countries',
    COUNTRIES_READ: true,
    COUNTRIES_UPDATE: 'Manage countries',
    COUNTRIES_DELETE: 'Manage countries',

    COURSES_CREATE: 'Manage courses',
    COURSES_READ_CURRENT_CANDIDATES: ['Manage courses', 'View courses', 'View all courses'],
    COURSES_READ_ALL: ['Manage courses', 'View all courses'],
    COURSES_UPDATE: 'Manage courses',
    COURSES_DELETE: 'Manage courses',

    COURSE_KINDS_CREATE: 'Manage course kinds',
    COURSE_KINDS_READ: ['Manage course kinds', 'Manage courses'],
    COURSE_KINDS_UPDATE: 'Manage course kinds',
    COURSE_KINDS_DELETE: 'Manage course kinds',

    COURSE_STATUSES_CREATE: 'Manage course statuses',
    COURSE_STATUSES_READ: ['Manage course statuses', 'Manage courses'],
    COURSE_STATUSES_UPDATE: 'Manage course statuses',
    COURSE_STATUSES_DELETE: 'Manage course statuses',

    CRITERIA_CREATE: 'Manage criteria',
    CRITERIA_READ: ['Manage criteria', 'Create evaluations', 'Edit all evaluations'],
    CRITERIA_UPDATE: 'Manage criteria',
    CRITERIA_DELETE: false,

    DISCUSSIONS_CREATE: 'Create discussions',
    DISCUSSIONS_READ_UNHIDDEN: ['Create discussions', 'View discussions', 'Hide discussions'],
    DISCUSSIONS_READ_HIDDEN: 'Hide discussions',
    DISCUSSIONS_UPDATE_OWN: ['Create discussions', 'Edit all discussions'],
    DISCUSSIONS_UPDATE_ALL: 'Edit all discussions',
    DISCUSSIONS_DELETE_OWN: ['Create discussions', 'Edit all discussions'],
    DISCUSSIONS_DELETE_ALL: 'Edit all discussions',
    DISCUSSIONS_HIDE: 'Hide discussions',
    DISCUSSIONS_LOCK: 'Lock discussions',
    DISCUSSIONS_REPLIES_CREATE: ['Reply to discussions', 'Edit all discussion replies'],
    DISCUSSIONS_REPLIES_READ_UNHIDDEN: ['Create discussions', 'View discussions', 'Hide discussions'],
    DISCUSSIONS_REPLIES_READ_HIDDEN: 'Hide discussions',
    DISCUSSIONS_REPLIES_UPDATE_OWN: ['Reply to discussions', 'Edit all discussion replies'],
    DISCUSSIONS_REPLIES_UPDATE_ALL: 'Edit all discussion replies',
    DISCUSSIONS_REPLIES_DELETE_OWN: ['Reply to discussions', 'Edit all discussion replies'],
    DISCUSSIONS_REPLIES_DELETE_ALL: 'Edit all discussion replies',

    DOCUMENTS_CREATE: 'Create downloads',
    DOCUMENTS_READ: ['Create downloads', 'View downloads', 'Manage downloads'],
    DOCUMENTS_UPDATE: 'Manage downloads',
    DOCUMENTS_DELETE: 'Manage downloads',

    EMAILS_SEND_MASS: 'Mass email',

    EVALUATIONS_CREATE: 'Create evaluations',
    EVALUATIONS_READ_OWN: 'Create evaluations',
    EVALUATIONS_READ_ALL: 'View all evaluations',
    EVALUATIONS_UPDATE_OWN: 'Create evaluations',
    EVALUATIONS_UPDATE_ALL: false,
    EVALUATIONS_DELETE_OWN: 'Create evaluations',
    EVALUATIONS_DELETE_ALL: 'Edit all evaluations',

    FACILITIES_CREATE: 'Manage facilities',
    FACILITIES_READ: ['Manage facilities', 'View facilities'],
    FACILITIES_UPDATE: 'Manage facilities',
    FACILITIES_DELETE: 'Manage facilities',

    FACILITY_KINDS_CREATE: 'Manage facility kinds',
    FACILITY_KINDS_READ: ['Manage facility kinds', 'Manage facilities'],
    FACILITY_KINDS_UPDATE: 'Manage facility kinds',
    FACILITY_KINDS_DELETE: 'Manage facility kinds',

    LOGS_READ: 'View application logs',

    NGF_FACILITIES_CREATE: 'Manage NGF facilities',
    NGF_FACILITIES_READ: ['Manage NGF facilities', 'View NGF facilities'],
    NGF_FACILITIES_UPDATE: 'Manage NGF facilities',
    NGF_FACILITIES_DELETE: 'Manage NGF facilities',

    NGF_FACILITY_KINDS_CREATE: 'Manage NGF facility kinds',
    NGF_FACILITY_KINDS_READ: ['Manage NGF facility kinds', 'Manage NGF facilities'],
    NGF_FACILITY_KINDS_UPDATE: 'Manage NGF facility kinds',
    NGF_FACILITY_KINDS_DELETE: 'Manage NGF facility kinds',

    ORG_ADDRESS_KINDS_CREATE: 'Manage organizational address kinds',
    ORG_ADDRESS_KINDS_READ: ['Manage organizational address kinds', 'Manage courses', 'Manage facilities', 'Manage NGF facilities'],
    ORG_ADDRESS_KINDS_UPDATE: 'Manage organizational address kinds',
    ORG_ADDRESS_KINDS_DELETE: 'Manage organizational address kinds',

    ORG_TEL_KINDS_CREATE: 'Manage organizational telephone number kinds',
    ORG_TEL_KINDS_READ: ['Manage organizational telephone number kinds', 'Manage courses', 'Manage facilities', 'Manage NGF facilities'],
    ORG_TEL_KINDS_UPDATE: 'Manage organizational telephone number kinds',
    ORG_TEL_KINDS_DELETE: 'Manage organizational telephone number kinds',

    PAGES_CREATE: 'Manage pages',
    PAGES_READ: ['Manage pages', 'View pages'],
    PAGES_UPDATE: 'Manage pages',
    PAGES_DELETE: 'Manage pages',

    PERMISSIONS_CREATE: 'Manage permissions',
    PERMISSIONS_READ: ['Manage permissions', 'Manage roles', 'Manage user roles'],
    PERMISSIONS_UPDATE: 'Manage permissions',
    PERMISSIONS_DELETE: 'Manage permissions',

    PERSONAL_ADDRESS_KINDS_CREATE: 'Manage personal address kinds',
    PERSONAL_ADDRESS_KINDS_READ: ['Manage personal address kinds', 'Update profile', 'Edit all profiles', 'Manage courses', 'Manage users'],
    PERSONAL_ADDRESS_KINDS_UPDATE: 'Manage personal address kinds',
    PERSONAL_ADDRESS_KINDS_DELETE: 'Manage personal address kinds',

    PERSONAL_TEL_KINDS_CREATE: 'Manage personal telephone number kinds',
    PERSONAL_TEL_KINDS_READ: ['Manage personal telephone number kinds', 'Update profile', 'Edit all profiles', 'Manage courses', 'Manage users'],
    PERSONAL_TEL_KINDS_UPDATE: 'Manage personal telephone number kinds',
    PERSONAL_TEL_KINDS_DELETE: 'Manage personal telephone number kinds',

    PROFILES_READ: ['View profiles', 'Update profile', 'Edit all profiles', 'Manage users'],
    PROFILES_AVATAR_UPDATE_OWN: 'Update avatar',
    PROFILES_AVATAR_UPDATE_ALL: ['Manage users', 'Edit all avatars'],
    PROFILES_UPDATE_OWN: 'Update profile',
    PROFILES_UPDATE_ALL: ['Manage users', 'Edit all profiles'],

    PROFILE_FIELDS_CREATE: 'Manage profile fields',
    PROFILE_FIELDS_READ: ['Manage profile fields', 'Update profile', 'Edit all profiles', 'Manage users'],
    PROFILE_FIELDS_UPDATE: 'Manage profile fields',
    PROFILE_FIELDS_DELETE: 'Manage profile fields',

    PTP_REVIEWS_CREATE: 'Create Places to Play reviews',
    PTP_REVIEWS_READ_OWN: 'Create Places to Play reviews',
    PTP_REVIEWS_READ_ALL: 'View all Places to Play reviews',
    PTP_REVIEWS_UPDATE_OWN: 'Create Places to Play reviews',
    PTP_REVIEWS_UPDATE_ALL: false,
    PTP_REVIEWS_DELETE_OWN: 'Create Places to Play reviews',
    PTP_REVIEWS_DELETE_ALL: 'Edit all Places to Play reviews',
    PTP_REVIEWS_CREATE_UNRATED: 'Create unrated Places to Play reviews',

    REGIONS_CREATE: 'Manage countries',
    REGIONS_READ: true,
    REGIONS_UPDATE: 'Manage countries',
    REGIONS_DELETE: 'Manage countries',

    ROLES_CREATE: 'Manage roles',
    ROLES_READ: true,
    ROLES_UPDATE: 'Manage roles',
    ROLES_DELETE: 'Manage roles',

    SALUTATIONS_CREATE: 'Manage salutations',
    SALUTATIONS_READ: ['Manage salutations', 'Manage courses', 'Update profile', 'Edit all profiles', 'Manage users'],
    SALUTATIONS_UPDATE: 'Manage salutations',
    SALUTATIONS_DELETE: 'Manage salutations',

    SECURITY_QUESTIONS_CREATE: 'Manage security questions',
    SECURITY_QUESTIONS_READ: ['Manage security questions', 'Change security answers'],
    SECURITY_QUESTIONS_UPDATE: 'Manage security questions',
    SECURITY_QUESTIONS_DELETE: 'Manage security questions',

    SEC_SECURITY_ANSWERS_UPDATE: 'Change security answers',
    SEC_LOG_IN: 'Log in',
    SEC_LOG_OUT: 'Log out',
    SEC_SECRET_UPDATE: 'Change password',
    SEC_SECRET_RESET: 'User password reset',
    SEC_SECRET_RESET_MASS: 'Mass password reset',

    SURVEYS_CREATE: 'Manage surveys',
    SURVEYS_READ: ['Manage surveys', 'Manage courses'],
    SURVEYS_UPDATE: 'Manage surveys',
    SURVEYS_DELETE: 'Manage surveys',

    TURFS_CREATE: 'Manage turf kinds',
    TURFS_READ: ['Manage turf kinds', 'Manage courses'],
    TURFS_UPDATE: 'Manage turf kinds',
    TURFS_DELETE: 'Manage turf kinds',

    USERS_CREATE: 'Manage users',
    USERS_READ_SENSITIVE: 'Manage users',
    USERS_UPDATE: 'Manage users',
    USERS_ROLES_UPDATE: 'Manage user roles',
    USERS_DELETE: 'Manage users'
  };
});