define('golf-digest-panelists/components/article-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      triggerCancel: function triggerCancel() {
        this.get('onCancel')();
      },
      triggerSubmit: function triggerSubmit() {
        this.get('onSubmit')();
      },
      triggerAddImage: function triggerAddImage(file) {
        this.get('onAddImage')(file);
      },
      triggerDeleteImage: function triggerDeleteImage(file) {
        this.get('onDeleteImage')(file);
      }
    }
  });
});