define('golf-digest-panelists/mixins/busy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      inject = Ember.inject;
  exports.default = Mixin.create({
    // Inject the Busy Service
    busy: inject.service(),

    // Basic Action defaults to trigger show / hide
    actions: {
      busy: function busy(options) {
        this.get('busy').show(options);
      },
      free: function free() {
        this.get('busy').hide();
      }
    }
  });
});