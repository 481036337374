define('golf-digest-panelists/courses/course/evaluations/controller', ['exports', 'golf-digest-panelists/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'perPage', 'CourseId', 'active'],
    session: Ember.inject.service(),
    totalPages: Ember.computed.alias('content.totalPages'),
    cap: Ember.computed.alias('session.data.cap'),

    page: 1,
    perPage: 25,
    exportEvalsUrl: _environment.default.jsonAdapterHost + '/api/v2/export-evaluations',

    actions: {
      pageClicked: function pageClicked(page) {
        this.set('page', page);
      }
    }
  });
});