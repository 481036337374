define('golf-digest-panelists/services/survey-comparison', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    start: function start(surveyIds) {
      var ajax = this.get('ajax');
      var url = '/survey-comparison';

      return ajax.request(url, {
        method: 'GET',
        data: {
          SurveyId: surveyIds
        }
      }).then(function (data) {
        return Ember.RSVP.resolve(data.task);
      }).catch(function (err) {
        Ember.Logger.warn(err.message);
      });
    },
    getStatus: function getStatus(taskId) {
      var ajax = this.get('ajax');
      var url = '/survey-comparison/tasks/' + taskId;

      return ajax.request(url, {
        method: 'GET'
      }).then(function (data) {
        return Ember.RSVP.resolve(data.task);
      });
    },
    download: function download(taskId) {
      var ajax = this.get('ajax');
      var url = ajax.urlFor('/survey-comparison/tasks/' + taskId + '/download');

      window.open(url);
    }
  });
});