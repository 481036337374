define('golf-digest-panelists/home/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        // Find All Articles and returns the first 5
        articles: this.store.findAll('article').then(function (a) {
          return a.slice(0, 5);
        }),
        discussionPartitions: this.store.query('discussion', {}).then(function (discussions) {
          var partitions = Ember.A();

          var discussionsSubset = discussions.slice(0, 15);

          for (var i = 0; i < discussionsSubset.length; i += 3) {
            partitions.push(discussionsSubset.slice(i, i + 3));
          }

          return partitions;
        })
      });
    },

    actions: {
      goToCourse: function goToCourse(course) {
        this.transitionTo('courses.course', course.get('id'));
      },
      regionRedirect: function regionRedirect(regionId) {
        this.transitionTo('courses', { queryParams: { RegionId: regionId } });
      },
      countryRedirect: function countryRedirect(countryId) {
        this.transitionTo('courses', { queryParams: { CountryId: countryId } });
      }
    }
  });
});