define('golf-digest-panelists/components/faq-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    cap: Ember.computed.alias('session.data.cap'),

    isAllowedToEdit: Ember.computed('cap', function () {
      if (this) {
        try {
          var cap = this.get('cap');

          return cap && (cap.canUpdateArticles || cap.canDeleteArticles);
        } catch (err) {
          Ember.Logger.warn('Unable to retrieve capabilities: ' + err.message + '\n' + ('' + (err && err.stack ? err.stack : '')));

          return false;
        }
      }

      return false;
    }),

    actions: {
      triggerCancel: function triggerCancel() {
        this.get('onCancel')();
      },
      triggerSubmit: function triggerSubmit() {
        this.get('onSubmit')();
      }
    }
  });
});