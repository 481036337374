define('golf-digest-panelists/ignored-courses/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['perPage', 'RegionId', 'query', 'startsWith'],

    totalPages: Ember.computed.alias('content.totalPages'),

    session: Ember.inject.service(),
    page: 1,
    perPage: 25,
    RegionId: null,
    query: null,

    cap: Ember.computed.alias('session.data.cap'),
    userId: Ember.computed.alias('session.data.currentUser.id'),

    actions: {
      pageClicked: function pageClicked(page) {
        this.set('page', page);
      },
      showNGFCourses: function showNGFCourses() {
        return this.transitionToRoute('ngf-courses');
      },
      addCourse: function addCourse(course) {
        return this.transitionToRoute('ignored-courses.course', course.id);
      },
      unIgnoreCourse: function unIgnoreCourse(course) {
        var flash = this.get('flashMessages');
        var courseAddrs = course.get('ignoredCourseAddresses');
        var courseTels = course.get('ignoredCourseTels');
        var courseTees = course.get('ignoredTees');

        var controller = this;

        var ngfCourse = controller.get('store').createRecord('ngf-course', {
          facilityName: course.get('facilityName'),
          ngfFacilityId: course.get('ngfFacilityId'),
          ngfCourseId: course.get('ngfCourseId'),
          id: course.get('ngfCourseId'),
          name: course.get('name'),
          websiteUrl: course.get('websiteUrl'),
          holeCount: course.get('holeCount'),
          courseKind: course.get('courseKind'),
          courseStatus: course.get('courseStatus'),
          designerFullName: course.get('designerFullName'),
          dateOpened: course.get('dateOpened')
        });
        ngfCourse.save().then(function (ngfCourse) {

          var promises = Ember.A();

          courseTels.forEach(function (tel) {
            if (tel.get('tel')) {
              var newTel = controller.get('store').createRecord('ngf-course-tel', {
                ngfCourse: ngfCourse,
                ngfFacilityId: course.get('ngfFacilityId'),
                index: 0,
                organizationalTelKind: tel.get('organizationalTelKind'),
                tel: tel.get('tel')
              });
              promises.push(newTel.save());
            }
          });

          courseAddrs.forEach(function (addr) {
            var newAddress = controller.get('store').createRecord('ngf-course-address', {
              ngfCourse: ngfCourse,
              ngfFacilityId: course.get('ngfFacilityId'),
              index: 0,
              organizationalAddressKind: addr.get('organizationalAddressKind'),
              street1: addr.get('street1'),
              street2: addr.get('street2'),
              street3: addr.get('street3'),
              locality: addr.get('locality'),
              county: addr.get('county'),
              region: addr.get('region'),
              postalCode: addr.get('postalCode')
            });
            promises.push(newAddress.save());
          });

          courseTees.forEach(function (tee) {
            var newTee = controller.get('store').createRecord('ngf-tee', {
              ngfCourse: ngfCourse,
              ngfFacilityId: course.get('ngfFacilityId'),
              index: 0,
              name: tee.get('name'),
              length: tee.get('length'),
              par: tee.get('par'),
              slope: tee.get('slope'),
              rating: tee.get('rating'),
              updatedAt: null
            });
            promises.push(newTee.save());
          });

          return Ember.RSVP.Promise.all(promises).then(function () {
            course.destroyRecord().then(function () {
              flash.success('Course back to NGF!');
            });
          });
        });
      }
    }
  });
});