define('golf-digest-panelists/models/ngf-rating', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    review: (0, _emberCpValidations.validator)('presence', true),
    ngfCriterion: (0, _emberCpValidations.validator)('presence', true),
    ngfrating: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowBlank: false,
      allowString: true,
      integer: false,
      positive: true,
      lte: 10,
      gte: 1
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    review: _emberData.default.belongsTo('review'),
    ngfCriterion: _emberData.default.belongsTo('ngf-criterion'),
    ngfrating: _emberData.default.attr('number')
  });
});