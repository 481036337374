define('golf-digest-panelists/courses/index/route', ['exports', 'ember-infinity/mixins/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_route.default, {
    perPage: 25,

    /**
     * Function Infinity Model by params
     * @param {*} params
     */
    model: function model(params) {
      return this.infinityModel('course', params);
    },

    actions: {
      /**
       * Function go to Couser by ID
       * @param {*} course
       */
      goToCourse: function goToCourse(course) {
        return this.transitionTo('courses.course', course.get('id'));
      },


      /**
       * Function Select Region by ID
       * @param {*} regionId
       */
      selectRegion: function selectRegion(regionId) {
        var that = this;

        return that.transitionTo({ queryParams: { RegionId: regionId } }).then(function () {
          return that.refresh();
        });
      },


      /**
       * Function Select Country by ID
       * @param {*} countryId
       */
      selectCountry: function selectCountry(countryId) {
        var that = this;

        return that.transitionTo({ queryParams: { CountryId: countryId } }).then(function () {
          return that.refresh();
        });
      },


      /**
       * Function Region Redirect by ID
       * @param {*} regionId
       */
      regionRedirect: function regionRedirect(regionId) {
        this.controller.set('loading', false);
        var that = this;
        return that.transitionTo({ queryParams: { RegionId: regionId } }).then(function () {
          return that.refresh();
        }).then(function () {
          return that.controller.set('loading', true);
        });
      },


      /**
       * Function Country Redirect by ID
       * @param {*} countryId
       */
      countryRedirect: function countryRedirect(countryId) {
        this.controller.set('loading', false);
        var that = this;
        return that.transitionTo({ queryParams: { CountryId: countryId } }).then(function () {
          return that.refresh();
        }).then(function () {
          return that.controller.set('loading', true);
        });
      },


      /**
       * Function Search by param
       * @param {*} nameChar
       */
      search: function search(nameChar) {
        var char = null;
        if (nameChar) {
          char = nameChar.get('name');
        } else {
          char = '';
        }
        var that = this;
        return that.transitionTo({ queryParams: { startsWith: char } }).then(function () {
          return that.refresh();
        });
      }
    }
  });
});