define("golf-digest-panelists/components/evaluation-editor/course-select/country-select/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service("store"),
    country: [],

    internalValue: Ember.computed("value", function () {
      var that = this;

      if (that.get("value")) {
        var countries = that.get("country");
        var targetId = that.get("value").get("id");

        // Retrieves country selected
        return countries.find(function (country) {
          return country.get('id') === targetId;
        });
      }

      return undefined;
    }),

    init: function init() {
      var that = this;

      that._super.apply(that, arguments);

      return that.get("store").query("country", {}).then(function (country) {
        return that.set("country", country);
      }).then(function () {
        return that.notifyPropertyChange("value");
      });
    },


    actions: {
      /**
       * Function Change Value Country for param
       * @param {*} country
       */
      changeValue: function changeValue(country) {
        var _this = this;

        if (country) {
          // Find and return Record
          return this.get("store").findRecord("country", country.get("id")).then(function (realRegion) {
            return _this.get("onChange")(realRegion);
          });
        } else {
          this.get("onChange")(null);
        }
      }
    }
  });
});