define('golf-digest-panelists/components/uploaded-image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['showInline'],
    session: Ember.inject.service()
  });
});