define('golf-digest-panelists/transforms/utc', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value ? value.toJSON() : null;
    },
    deserialize: function deserialize(value) {
      return _moment.default.utc(value);
    }
  });
});