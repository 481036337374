define('golf-digest-panelists/articles/article/route', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),

    // Find and return Record Article By ID
    model: function model(params) {
      return this.store.findRecord('article', params.article_id);
    },


    actions: {

      // Function Toggle Edit Article Current Model
      toggleEdit: function toggleEdit() {
        this.currentModel.rollbackAttributes();
        this.currentModel.toggleProperty('isEditing');
      },


      // Submit an Article
      submit: function submit() {
        var article = this.currentModel;

        if (article.get('isEditing') === true) {
          return article.save().then(function () {
            // Handle deleted images, if there are any.

            if (article.get('deletedImages')) {
              return Ember.RSVP.all(article.get('deletedImages').map(function (del) {
                return del.save();
              }));
            }

            return Ember.RSVP.resolve();
          }).then(function () {
            // Handle new/existing images.

            return _npmBluebird.default.all(article.get('images').map(function (image, index) {
              image.set('index', index);

              return image.save().then(function () {
                return image.reload();
              }).then(function () {
                return image.set('fileData', undefined);
              });
            }));
          }).then(function () {
            return article.set('isEditing', false);
          }).catch(function (err) {
            Ember.Logger.info(err);
            article.set('isEditing', true);
          });
        }
      },


      // Adding image in article
      addImage: function addImage(file) {
        var that = this;
        var article = that.currentModel;

        return new _npmBluebird.default(function (resolve, reject) {
          var reader = new FileReader();
          reader.onload = function (e) {
            return resolve(e.target.result);
          };
          reader.onerror = function (err) {
            return reject(err);
          };
          reader.onabort = function () {
            return reject(new Error('File read aborted'));
          };

          reader.readAsDataURL(file);
        }).then(function (data) {
          var image = that.store.createRecord('article-image');

          image.set('article', article);
          image.set('title', file.name);
          image.set('fileName', file.name);
          image.set('mimeType', file.type);
          image.set('fileData', data);

          article.get('images').pushObject(image);
        });
      },


      // Delete image of Article
      deleteImage: function deleteImage(image) {
        var article = this.currentModel;

        article.get('images').removeObject(image);

        if (!article.get('deletedImages')) {
          article.set('deletedImages', []);
        }

        article.get('deletedImages').pushObject(image);

        image.deleteRecord();
      },


      // Delete article of Article
      deleteArticle: function deleteArticle() {
        var _this = this;

        var flash = this.get('flashMessages');

        return this.currentModel.destroyRecord().then(function () {
          return _this.transitionTo('home');
        }).then(function () {
          return flash.success('Article deleted!');
        });
      }
    }
  });
});