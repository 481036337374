define('golf-digest-panelists/components/users-list/user-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-item', 'col-xs-4', 'col-sm-6', 'col-md-4', 'col-lg-4'],
    tagName: 'div',
    session: Ember.inject.service()
  });
});