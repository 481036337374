define('golf-digest-panelists/users/user/edit/controller', ['exports', 'npm:bluebird', 'npm:underscore', 'golf-digest-panelists/config/environment', 'moment'], function (exports, _npmBluebird, _npmUnderscore, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ACCEPTED = ['image/jpeg', 'image/png'];
  exports.default = Ember.Controller.extend({
    busy: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    passwordReset: Ember.inject.service(),
    pollboy: Ember.inject.service(),
    ajax: Ember.inject.service(),

    cap: Ember.computed.alias('session.data.cap'),
    userId: Ember.computed.alias('session.data.userId'),
    currentUser: Ember.computed.alias('session.data.currentUser'),

    index: 0,
    refresh: true,

    addressKinds: Ember.A(),
    telKinds: Ember.A(),
    profileKinds: Ember.A(),
    selectedItems: Ember.A(),
    deselectedItems: Ember.A(),

    subscriptionInformation: null,

    resetTaskId: null,
    resetPollInterval: 2500,
    resetStatus: 'Unknown',
    resetProgress: 0.00,
    resetResult: null,
    resetMessage: null,

    resetStarting: Ember.computed('resetStatus', function () {
      return this.get('resetStatus') === 'Unknown';
    }),

    resetRunning: Ember.computed('resetStatus', function () {
      return this.get('resetStatus') === 'Running';
    }),

    resetFinished: Ember.computed('resetStatus', function () {
      return this.get('resetStatus') === 'Finished';
    }),

    isPanelist: Ember.computed(function () {
      var session = this.get('session');
      if (session.currentUser.Roles.length === 1 && session.currentUser.Roles.some(function (role) {
        return role.name.includes('Panelist');
      })) {
        return true;
      }
    }),

    getSubscriptionInformation: Ember.computed('ajax', function () {
      var _this = this;

      var ajax = this.get('ajax');
      ajax.request('user-entitlement').then(function (userEntitlement) {
        var subscriptionId = userEntitlement.subscriptionId,
            subscriptionEndDate = userEntitlement.subscriptionEndDate,
            subscriptionStartDate = userEntitlement.subscriptionStartDate,
            pricePaid = userEntitlement.pricePaid,
            status = userEntitlement.status;

        var subscriptinInformation = {
          subscriptionId: subscriptionId,
          pricePaid: pricePaid,
          status: status,
          subscriptionEndDate: (0, _moment.default)(subscriptionEndDate).format('MM-DD-YYYY'),
          subscriptionStartDate: (0, _moment.default)(subscriptionStartDate).format('MM-DD-YYYY')
        };
        _this.set('subscriptionInformation', subscriptinInformation);
      });
    }),

    updateResetStatus: function updateResetStatus(task) {
      this.set('resetTaskId', task.id);
      this.set('resetStatus', task.status);
      this.set('resetProgress', task.progress ? task.progress * 100 : 0);
      this.set('resetResult', task.result);
      this.set('resetMessage', task.message);
    },
    onResetPoll: function onResetPoll() {
      var that = this;
      var taskId = this.get('resetTaskId');
      var passwordReset = this.get('passwordReset');
      var busy = this.get('busy');
      var flash = this.get('flashMessages');

      // Function Get Unlock and Reset Status By ID
      return passwordReset.getUnlockAndResetStatus(this.get('model.id'), taskId).then(function (task) {
        that.updateResetStatus(task);

        if (task.progress === 1) {
          return that.stopResetPolling().then(function () {
            return busy.hide();
          }).then(function () {
            return flash.success(task.message, {
              timeout: 15000
            });
          });
        }
      });
    },
    stopResetPolling: function stopResetPolling() {
      var that = this;

      var poller = this.get('resetPoller');
      var pollboy = this.get('pollboy');

      return new Ember.RSVP.Promise(function (resolve) {
        pollboy.remove(poller);

        that.set('resetPoller', null);

        return resolve();
      });
    },
    deactivate: function deactivate() {
      return this.stopResetPolling();
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.set('resetPoller', null);

      return _npmBluebird.default.all([

      // Function Get Store and Find All Personal Address Kind, then set Address Kinds
      this.get('store').findAll('personal-address-kind').then(function (kinds) {
        return _this2.set('addressKinds', kinds);
      }),

      // Function Get Store and Find All Personal Tel Kind, then Set Tel Kinds
      this.get('store').findAll('personal-tel-kind').then(function (kinds) {
        return _this2.set('telKinds', kinds);
      }),

      // Function Get Store and Find All Profile Field, then Set Profile Kinds
      this.get('store').findAll('profile-field').then(function (kinds) {
        return _this2.set('profileKinds', kinds);
      })]);
    },


    isPrimaryDis: Ember.computed(function () {
      var model = this.get('model');
      var cnt = model.get('emails.length');
      return cnt === 1;
    }),

    saveModel: function saveModel(model) {
      var flash = this.get('flashMessages');
      var controller = this;

      return model.save().then(function () {
        var promises = Ember.A();

        model.get('emails').forEach(function (item) {
          promises.push(item.save());
        });

        return Ember.RSVP.Promise.all(promises).then(function () {
          flash.success('User Updated!');
          controller.get('target.router').refresh();
        });
      }).catch(function (err) {
        var brandTitle = _environment.default.brand || 'Golf Digest Panelists';

        Ember.Logger.log(err);
        flash.danger(brandTitle + ' has encountered an error: ' + err);
      });
    },
    _save: function _save() {
      var model = this.get('model');
      var that = this;

      // TODO: Why is this needed? A comment to explain is necessary. ~jwt
      model.set('newPassword', null);

      return this.saveModel(model).then(function () {
        return (
          // Go through all of the telephone numbers and addresses that
          // are either new or still in editing mode and save them.

          _npmBluebird.default.all([model.get('addresses').map(function (a) {
            if (a.get('editing') === true || a.get('isNew') === true) {
              return that.actions.saveAddress(a);
            }

            return _npmBluebird.default.resolve();
          }), model.get('tels').map(function (t) {
            if (t.get('editing') === true || t.get('isNew') === true) {
              return that.actions.saveTel(t);
            }

            return _npmBluebird.default.resolve();
          })])
        );
      });
    },
    saveRole: function saveRole(userId, roleIds) {
      var store = this.get('store');
      var model = this.get('model');
      var roles = model.get('roles');

      if (_npmUnderscore.default.isArray(roleIds)) {
        return _npmBluebird.default.map(roleIds, function (roleId) {
          store.findRecord('role', roleId).then(function (role) {
            var newRole = store.createRecord('user-role', {
              UserId: model,
              RoleId: role,
              index: 0
            });

            return newRole.save().then(function () {
              return roles.pushObject(role);
            });
          });
        });
      }

      return _npmBluebird.default.resolve();
    },
    deleteRole: function deleteRole(userId, roleId) {
      var store = this.get('store');
      var model = this.get('model');
      var roles = model.get('roles');

      // => DELETE to /user-role/ids
      return store.findRecord('role', roleId).then(function (role) {
        return store.queryRecord('user-role', {
          userId: userId,
          roleId: roleId
        }).then(function (userRole) {
          return userRole.destroyRecord();
        }).then(function () {
          return roles.removeObject(role);
        });
      });
    },


    actions: {
      removeAvatar: function removeAvatar() {
        var _this3 = this;

        var model = this.get('model');
        model.set('avatarMimeType', null);

        this.set('refresh', false);

        return model.save().then(function () {
          model.set('avatarFileName', null);
          _this3.set('refresh', true);
        });
      },
      selectFiles: function selectFiles(files) {
        var file = files[0];
        var model = this.get('model');
        var flash = this.get('flashMessages');
        var that = this;

        if (_npmUnderscore.default.contains(ACCEPTED, file.type)) {
          this.set('refresh', false);

          return new _npmBluebird.default(function (resolve, reject) {
            var reader = new FileReader();

            reader.onload = function (e) {
              return resolve(e.target.result);
            };
            reader.onerror = reject;
            reader.onabort = function () {
              return reject(new Error('File read aborted'));
            };

            reader.readAsDataURL(file);
          }).then(function (data) {
            model.set('avatarFileData', data);

            return model.save().then(function () {
              model.set('avatarFileName', 'image');
              that.set('refresh', true);

              setTimeout(function () {
                var imgContainer = window.document.querySelector('.user-profile-edit');
                var img = window.document.querySelector('img');
                img.src = data;
              }, 10);
            });
          });
        }

        flash.danger('Avatar images in JPG or PNG formats only.');
      },
      changeSalutation: function changeSalutation(salutation) {
        var model = this.get('model');
        model.set('salutation', salutation);
      },
      resetPassword: function resetPassword() {
        var that = this;
        var busy = this.get('busy');
        var flash = this.get('flashMessages');
        var passwordReset = this.get('passwordReset');

        var userId = this.get('model.id');

        busy.show();

        // Function Unlock and Reset User By ID
        return passwordReset.unlockAndResetUser(userId).then(function (payload) {
          that.set('resetUserIsValid', payload.userIdIsValid);

          if (payload.userIdIsValid !== true || !_npmUnderscore.default.isString(payload.taskId)) {
            busy.hide();

            return flash.info(payload.message, { timeout: 5000 });
          }

          that.set('resetTaskId', payload.taskId);

          var poller = that.get('resetPoller');

          if (!poller) {
            var pollInterval = that.get('resetPollInterval');
            poller = that.get('pollboy').add(that, that.onResetPoll, pollInterval);
            that.set('resetPoller', poller);
          }

          busy.show({ message: payload.message });
        });
      },
      createEmail: function createEmail() {
        var _this4 = this;

        var newEmail = this.get('newEmail');
        var flash = this.get('flashMessages');

        if (!newEmail.trim()) {
          return _npmBluebird.default.resolve();
        }

        var controller = this;
        var model = this.get('model');
        var index = this.get('index');

        return model.get('emails').then(function (emails) {
          var emailExists = false;

          if (emails) {
            emails.forEach(function (email) {
              if (email.get('email') === newEmail) {
                emailExists = true;
              }

              var cIndex = parseInt(email.get('index'));

              if (!isNaN(cIndex) && cIndex > index) {
                controller.set('index', cIndex);
              }
            });
          }

          if (emailExists) {
            flash.danger('This ' + newEmail + ' email has already been provided');
            return _npmBluebird.default.resolve();
          }

          var store = _this4.get('store');

          return store.queryRecord('user', {
            email: newEmail
          }).then(function (user) {
            if (user && user.get('id')) {
              flash.danger('This email is in use already');
            } else {
              var newUserEmail = model.get('emails').createRecord({
                email: newEmail,
                user: controller.get('currentUser'),
                index: controller.get('index') + 1
              });
              newUserEmail.save().then(function () {
                controller.set('newEmail', '');
              }).catch(function (err) {
                controller.set('newEmail', '');
                Ember.Logger.log(err);
                controller.store.unloadRecord(newUserEmail);
                controller.get('target.router').refresh();
                flash.danger('' + err.errors[0].detail, {
                  timeout: 5000
                });
              });
            }
          });
        });
      },
      createTel: function createTel() {
        var _this5 = this;

        var controller = this;
        var model = this.get('model');
        var index = -1;

        return model.get('userTels').then(function (tels) {
          if (tels) {
            tels.forEach(function (telephone) {
              var cIndex = parseInt(telephone.get('index'));
              if (!isNaN(cIndex) && cIndex > index) {
                index = cIndex;
              }
            });
          }

          // Function Get Store Personal Tel Kind By ID
          return _this5.get('store').find('personal-tel-kind', 1).then(function (personalTelKind) {
            var newTel = controller.get('store').createRecord('user-tel', {
              user: model,
              index: index + 1,
              personalTelKind: personalTelKind
            });

            newTel.set('editing', true);
            model.get('userTels').pushObject(newTel);
          });
        });
      },
      toggleEditTel: function toggleEditTel(tel) {
        if (tel.get('isNew')) {
          tel.destroy();
        } else {
          if (tel.get('editing') === true) {
            tel.set('editing', false);
            tel.rollbackAttributes();
          } else {
            tel.set('editing', true);
          }
        }
      },
      saveTel: function saveTel(tel) {
        return tel.save().then(function () {
          return tel.set('editing', false);
        }).then(function () {
          return tel.reload();
        });
      },
      deleteTel: function deleteTel(tel) {
        return tel.destroyRecord();
      },
      changeTelKind: function changeTelKind(tel, kind) {
        tel.set('kind', kind);
      },
      createProfile: function createProfile() {
        var controller = this;
        var model = this.get('model');

        return this.get('store').find('profile-field', 1).then(function (profileKind) {
          var newProfValue = controller.get('store').createRecord('profile-value', {
            user: model,
            profileField: profileKind
          });

          newProfValue.set('editing', true);
          model.get('profileValues').pushObject(newProfValue);
        });
      },
      toggleEditProfile: function toggleEditProfile(value) {
        if (value.get('isNew')) {
          value.destroy();
        } else {
          if (value.get('editing') === true) {
            value.set('editing', false);
            // Hack for reverting Profile Field. To be addressed after
            // https://github.com/emberjs/rfcs/pull/21
            value.rollbackAttributes();
          } else {
            value.set('editing', true);
          }
        }
      },
      saveProfile: function saveProfile(value) {
        var model = this.get('model');
        var profileValues = model.get('profileValues');
        var count = 0;
        var flash = this.get('flashMessages');

        profileValues.forEach(function (row) {
          if (row.get('profileField.id') === value.get('profileField.id')) {
            count += 1;
          }
        });

        if (count > 1) {
          flash.danger('Profile field already exits and cannot be duplicated.');
          return _npmBluebird.default.resolve();
        }

        return value.save().then(function () {
          return value.set('editing', false);
        }).then(function () {
          return value.reload();
        });
      },
      deleteProfile: function deleteProfile(value) {
        return value.destroyRecord();
      },
      changeProfileKind: function changeProfileKind(value, kind) {
        value.set('profileField', kind);
      },
      createAddress: function createAddress() {
        var _this6 = this;

        var model = this.get('model');
        var index = 0;
        var addresses = model.get('userAddresses');
        var store = this.get('store');

        if (addresses && addresses.length > 0) {
          addresses.forEach(function (address) {
            var idx = address.get('index');

            if (idx && idx > index) {
              index = idx;
            }
          });
        }

        // Function Find Region By ID then Get Store Personal Address Kind
        return store.find('region', 1).then(function (region) {
          return _this6.get('store').find('personal-address-kind', 1).then(function (personalAddressKind) {
            var newAddress = store.createRecord('user-address', {
              user: model,
              index: index + 1,
              personalAddressKind: personalAddressKind,
              region: region
            });

            newAddress.set('editing', true);
            addresses.pushObject(newAddress);
          });
        });
      },
      toggleEditAddress: function toggleEditAddress(address) {
        if (address.get('isNew')) {
          address.destroy();
        } else {
          if (address.get('editing') === true) {
            address.set('editing', false);
            address.rollbackAttributes();
          } else {
            address.set('editing', true);
          }
        }
      },
      saveAddress: function saveAddress(address) {
        return address.save().then(function () {
          return address.set('editing', false);
        }).then(function () {
          return address.reload();
        });
      },
      deleteAddress: function deleteAddress(address) {
        return address.destroyRecord();
      },
      changeAddressKind: function changeAddressKind(address, kind) {
        address.set('kind', kind);
      },
      changeAddressRegion: function changeAddressRegion(address, region) {
        address.set('region', region);
      },
      getSelectedItems: function getSelectedItems(values) {
        var selectedItems = this.get('selectedItems');
        selectedItems.push(values.id);
      },
      deselect: function deselect(value) {
        var deselectedItems = this.get('deselectedItems');
        deselectedItems.push(value.id);

        var selectedItems = this.get('selectedItems');

        if (selectedItems.indexOf(value.id) > -1) {
          var index = selectedItems.indexOf(value.id);
          selectedItems.splice(index, 1);
        }
      },
      save: function save() {
        var controller = this;
        var store = this.get('store');
        var model = this.get('model');
        var newEmail = this.get('newEmail');
        var flash = this.get('flashMessages');
        var userId = model.get('id');
        var currentRoleIds = [];

        var userRoles = model.get('roles');
        userRoles.forEach(function (role) {
          return currentRoleIds.push(role.id);
        });

        var selectedRoles = this.get('selectedItems');
        this.set('selectedItems', []);

        var deselectedRoles = this.get('deselectedItems');
        this.set('deselectedItems', []);

        if (currentRoleIds.length > 0) {
          currentRoleIds.forEach(function (currentRoleId) {
            if (selectedRoles.indexOf(currentRoleId) > -1) {
              var index = selectedRoles.indexOf(currentRoleId);
              selectedRoles.splice(index, 1);
            } else if (deselectedRoles.indexOf(currentRoleId) > -1) {
              controller.deleteRole(userId, currentRoleId);
            }
          });
        }

        controller.saveRole(userId, selectedRoles);

        if (!newEmail) {
          return controller._save();
        }

        return store.queryRecord('user', {
          email: newEmail
        }).then(function (collidingEmailUsers) {
          if (collidingEmailUsers && collidingEmailUsers.get('id')) {
            flash.danger('This email address is in use already');
          } else {
            // Add newEmail to the model
            controller.set(newEmail, '');

            model.get('emails').createRecord({
              email: newEmail,
              user: model,
              index: controller.get('index') + 1
            });

            return controller._save();
          }
        });
      }
    }
  });
});