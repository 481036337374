define('golf-digest-panelists/application/controller', ['exports', 'golf-digest-panelists/config/environment', 'ember-data'], function (exports, _environment, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    appName: _environment.default.brand || 'Golf Digest Panelists',
    session: Ember.inject.service(),

    isFaqPresent: Ember.computed(function () {
      return _emberData.default.PromiseObject.create({
        // Get Store and Find All  Faq by ID
        promise: this.get('store').findAll('faq').then(function (faqs) {
          var newFaqArray = faqs.filter(function (faq) {
            return faq.get('id') !== null;
          });
          if (newFaqArray.length > 0) {
            return true;
          } else {
            return false;
          }
        })
      });
    })
  });
});