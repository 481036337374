define('golf-digest-panelists/components/users-report/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['table', 'user-details', 'table-striped', 'table-condensed'],
    sortedUsers: Ember.computed.sort('model', 'sortDefinition'),
    sortBy: 'surname',

    actions: {
      sortBy: function sortBy(property) {
        this.set('sortBy', property);
        var sortBy = this.get('sortBy');
        this.sendAction('sortColumn', sortBy);
      }
    }
  });
});