define('golf-digest-panelists/models/user-address', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    user: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    personalAddressKind: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    street1: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    region: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    }
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    user: _emberData.default.belongsTo('user'),
    index: _emberData.default.attr('number'),
    personalAddressKind: _emberData.default.belongsTo('personal-address-kind'),
    street1: _emberData.default.attr('string'),
    street2: _emberData.default.attr('string'),
    street3: _emberData.default.attr('string'),
    locality: _emberData.default.attr('string'),
    county: _emberData.default.attr('string'),
    region: _emberData.default.belongsTo('region'),
    postalCode: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('utc', { readOnly: true }),
    updatedAt: _emberData.default.attr('utc', { readOnly: true }),

    // ///////////////////////////////////////////////////////////////////////////
    // Convenience aliases
    kind: Ember.computed.alias('personalAddressKind'),

    isHome: Ember.computed('personalAddressKind', function () {
      var kind = this.get('personalAddressKind').get('name');

      return kind === 'Home';
    })
  });
});