define('golf-digest-panelists/components/combined-region-select/component', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var that = this;
      var store = this.get('store');

      // Find All Country and Region Record
      return _npmBluebird.default.join(store.findAll('country'), store.findAll('region'), function (countries, regions) {
        that.set('countries', countries);
        that.set('allRegions', regions);
      });
    },


    regions: Ember.computed('allRegions', 'selectedCountry', function () {
      var selectedCountry = this.get('selectedCountry');
      var allRegions = this.get('allRegions');
      var filteredRegions = Ember.A();

      if (allRegions) {
        if (selectedCountry && selectedCountry.get('id')) {
          filteredRegions = allRegions.filterBy('country.id', selectedCountry.get('id'));
        } else {
          filteredRegions = Ember.A(allRegions);
        }
      }

      return filteredRegions.sortBy('name');
    }),

    selectedRegion: Ember.computed('regions', 'value', function () {
      var value = this.get('value');
      var regions = this.get('regions');

      if (value && regions && value.get('id')) {
        return regions.findBy('id', value.get('id'));
      }

      return null;
    }),

    selectedCountry: Ember.computed('countries', 'value', 'filterCountry', function () {
      var value = this.get('value');
      var countries = this.get('countries');

      if (countries) {
        if (value && value.get('country.id')) {
          return countries.findBy('id', value.get('country.id'));
        } else {
          var filterCountry = this.get('filterCountry');

          if (filterCountry && filterCountry.get('id')) {
            return countries.findBy('id', filterCountry.get('id'));
          }
        }
      }

      return null;
    }),

    _doChange: function _doChange() {
      return this.get('onChange')(this.get('value'));
    },


    actions: {
      /**
       * Function Change Country for param
       * @param {*} country 
       */
      changeCountry: function changeCountry(country) {
        this.set('filterCountry', country);
        this.set('value', null);

        this._doChange();
      },


      /**
       * Function Change Region for param
       * @param {*} region 
       */
      changeRegion: function changeRegion(region) {
        this.set('value', region);

        this._doChange();
      }
    }
  });
});