define('golf-digest-panelists/evaluations/new/route', ['exports', 'npm:underscore', 'moment'], function (exports, _npmUnderscore, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.computed(function () {
      return this.get('session').get('data.currentUserId');
    }),
    busy: Ember.inject.service(),

    model: function model() {
      var that = this;
      var now = (0, _moment.default)();
      var evaluation = that.store.createRecord('evaluation');

      var criteriaPromise = that.store.query('criterion', { Disabled: 1 });
      var scores = evaluation.get('scores');
      var selectedCourse = that.get('session').get('course');

      evaluation.set('dateExperienced', now);

      if (selectedCourse) {
        evaluation.set('course', selectedCourse);
        this.get('session').set('course', null);
      }

      return criteriaPromise.then(function (criteria) {
        return criteria.forEach(function (criterion) {
          return scores.pushObject(that.store.createRecord('score', { evaluation: evaluation, criterion: criterion }));
        });
      }).then(function () {
        return evaluation;
      });
    },
    _save: function _save() {
      var busy = this.get('busy');
      busy.show({ message: 'Saving your course evaluation...' });

      var that = this;
      var evaluation = this.modelFor('evaluations.new');
      var flash = this.get('flashMessages');

      evaluation.set('dateExperienced', _moment.default.utc(evaluation.get('dateExperienced').format('YYYY-MM-DD')));

      // Serialize scores
      var scores = evaluation.get('scores').map(function (score) {
        return score;
      }); // Clone hack
      evaluation.get('scores').clear();

      var scoreString = JSON.stringify(scores);
      evaluation.set('scorestr', scoreString);

      return evaluation.save().then(function () {
        return evaluation.reload();
      }).then(function () {
        busy.hide();
        return that.transitionTo('evaluations.evaluation', evaluation.id).then(function () {
          return flash.success('Your evaluation of ' + (evaluation.get('course').get('actualDisplayName') + ' has been ') + 'saved!');
        });
      }).catch(function (err) {
        Ember.Logger.error('Failed to save evaluation: ' + (err.message + '\n' + (err && err.stack ? err.stack : '')));

        // Put the removed scores back in case of error.
        evaluation.get('scores').pushObjects(scores);

        var errMsg = err.message;

        if (err.errors && err.errors.length > 0) {
          errMsg = _npmUnderscore.default.pluck(err.errors, 'detail').join('\n');
        }

        alert('We ran into an error while saving your evaluation: ' + errMsg);

        busy.hide();
      });
    },


    actions: {
      abort: function abort() {
        this.modelFor('evaluations.new').destroyRecord();
        return this.transitionTo('home');
      },
      courseEval: function courseEval(course) {
        if (!course) {
          return Ember.RSVP.resolve();
        }

        var evaluation = this.modelFor('evaluations.new');
        var that = this;
        var courseId = course.get('id');
        var userId = this.get('user');
        return this.store.queryRecord('evaluation', { CourseId: courseId, UserId: userId, isDeactivated: 'false' }).then(function (oldEvaluation) {
          if (oldEvaluation) {
            evaluation.get('scores').clear();
            var scores = oldEvaluation.get('scores').map(function (score) {
              return score;
            });
            // Find All Criterion
            return that.store.findAll('criterion').then(function (criteria) {
              criteria.forEach(function (criterion) {
                var name = criterion.get('name');
                if (!criterion.get('disableDate')) {
                  var scoreObject = _npmUnderscore.default.find(scores, function (s) {
                    return s.get('criterion.name') === name;
                  });
                  if (scoreObject) {
                    // Put the score back into the evaluation.
                    var oldScore = that.store.createRecord('score');
                    oldScore.set('criterion', scoreObject.get('criterion'));
                    oldScore.set('score', scoreObject.get('score'));
                    evaluation.get('scores').pushObject(oldScore);
                  } else {
                    var newScore = that.store.createRecord('score');
                    newScore.set('criterion', criterion);
                    evaluation.get('scores').pushObject(newScore);
                  }
                }
              });
              var notes = oldEvaluation.get('notes');
              var dateExperienced = oldEvaluation.get('dateExperienced');
              evaluation.set('notes', notes);
              evaluation.set('dateExperienced', dateExperienced);
              evaluation.set('course', course);
            });
          }
        }).catch(function (err) {
          Ember.Logger.info(err);
          evaluation.set('notes', '');
          var scores = evaluation.get('scores').map(function (score) {
            return score;
          });
          scores.forEach(function (score) {
            score.set('score', '');
          });
          evaluation.set('course', course);
        });
      },
      submit: function submit() {
        var _this = this;

        var that = this;
        var evaluation = this.modelFor('evaluations.new');

        // eslint-disable-next-line no-unused-vars
        return evaluation.validate().then(function (_ref) {
          var m = _ref.m,
              validations = _ref.validations;

          if (validations.get('isValid')) {
            return that._save();
          }

          var flash = _this.get('flashMessages');

          return flash.danger('Please review the following errors with your evaluation: ' + validations.get('errors').map(function (e) {
            return e.attribute + ': ' + e.message;
          }).join(', '), { sticky: true });
        });
      }
    }
  });
});