define('golf-digest-panelists/faqs/faq/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord('faq', params.faq_id);
    },


    actions: {
      toggleEdit: function toggleEdit() {
        this.currentModel.rollbackAttributes();
        this.currentModel.toggleProperty('isEditing');
      },
      submit: function submit() {
        var faq = this.currentModel;

        if (faq.get('isEditing') === true) {
          return faq.save().then(function () {
            return Ember.RSVP.resolve();
          }).then(function () {}).then(function () {
            return faq.set('isEditing', false);
          }).catch(function (err) {
            Ember.Logger.info(err);
            faq.set('isEditing', true);
          });
        }
      },
      deleteFaq: function deleteFaq() {
        var _this = this;

        var flash = this.get('flashMessages');

        return this.currentModel.destroyRecord().then(function () {
          return _this.transitionTo('home');
        }).then(function () {
          return flash.success('FAQ deleted!');
        });
      },

      // Redirect Course per Region By Id
      regionRedirect: function regionRedirect(regionId) {
        this.transitionTo('courses', { queryParams: { RegionId: regionId } });
      },
      // Redirect Course per Country By Id
      countryRedirect: function countryRedirect(countryId) {
        this.transitionTo('courses', { queryParams: { CountryId: countryId } });
      }
    }
  });
});