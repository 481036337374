define("golf-digest-panelists/router", ["exports", "golf-digest-panelists/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    brandTitle: _environment.default.brand || "Golf Digest Panelists"
  });

  Router.map(function () {
    this.route("index", { path: "/" });
    this.route("home");

    this.route("articles", { path: "/announcements" }, function () {
      this.route("index", { path: "/" });
      this.route("article", { path: "/:article_id" });
      this.route("new");
    });

    this.route("pay-now", { path: "/pay-now" }, function () {
      this.route("index", { path: "/" });
    });

    this.route("terms", { path: "/terms" }, function () {
      this.route("index", { path: "/" });
      this.route("payment-form", { path: "/payment-form" });
    });

    this.route("blocks");

    this.route("compare-survey-results");

    this.route("courses", function () {
      this.route("index", { path: "/" });
      this.route("course", { path: "/:course_id" }, function () {
        this.route("index", { path: "/" });
        this.route("evaluations");
      });

      this.route("new");
    });

    this.route("change-password");
    this.route("change-security-responses");

    this.route("discussions", function () {
      this.route("index", { path: "/" });
      this.route("discussion", { path: "/:discussion_id" });
      this.route("new");
    });

    this.route("evaluations", function () {
      this.route("index", { path: "/" });
      this.route("evaluation", { path: "/:evaluation_id" }, function () {
        this.route("index", { path: "/" });
        this.route("edit");
      });

      this.route("new");
    });

    this.route("facilities", function () {
      this.route("index", { path: "/" });
      this.route("facility", { path: "/:facility_id" });
      this.route("new");
    });

    this.route("faqs", function () {
      this.route("index", { path: "/" });
      this.route("faq", { path: "/:faq_id" });
      this.route("new");
    });

    this.route("ignored-courses", function () {
      this.route("index", { path: "/" });
    });

    this.route("ngf-courses", function () {
      this.route("index", { path: "/" });
    });

    this.route("ngf-facilities", function () {
      this.route("index", { path: "/" });
      this.route("ngf-facility", { path: "/:ngf_facility_id" });
      this.route("new");
    });

    this.route("password-reset", function () {
      this.route("index", { path: "/" });
      this.route("step-two", { path: "/:reset_token" });
    });

    this.route("profile");

    this.route("ptp", function () {
      this.route("index", { path: "/" });
      this.route("review", { path: "/:ngf_course_id" }, function () {
        this.route("index", { path: "/" });
      });
    });

    this.route("user-report");

    this.route("users", function () {
      this.route("index", { path: "/" });
      this.route("user", { path: "/:user_id" }, function () {
        this.route("index", { path: "/" });
        this.route("edit");
      });

      this.route("new");
    });

    this.route("surveys", function () {
      this.route("index", { path: "/" });
      this.route("survey", { path: "/:survey_id" }, function () {
        this.route("courses", function () {
          this.route("course", function () {
            this.route("evaluation");
          });
        });
      });

      this.route("new");
    });

    this.route("documents", function () {
      this.route("index", { path: "/" });
      this.route('document', { path: '/:document_id' }, function () {
        this.route('index', { path: '/' });
        this.route('edit');
      });

      this.route('new');
    });

    this.route("application-error");
    this.route("not-found", { path: "*path" });
    this.route("forbidden-error");
  });

  exports.default = Router;
});