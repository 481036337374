define('golf-digest-panelists/discussions/discussion/route', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model(params) {
      var that = this;

      // Function Find and Record Discussion By ID
      return that.store.findRecord('discussion', params.discussion_id).then(function (discussion) {
        return Ember.RSVP.hash({
          discussion: discussion,
          posts: discussion.get('posts'),
          topPost: discussion.get('topPost'),
          otherPosts: discussion.get('otherPosts'),
          newPost: that.store.createRecord('discussion-post', {
            title: 'Re: ' + discussion.get('title')
          }),
          searchTerm: params.searchTerm
        });
      });
    },


    queryParams: {
      searchTerm: {
        refreshModel: true
      }
    },

    actions: {

      /**
       * Function toggle Edit By param
       * @param {*} post 
       */
      toggleEdit: function toggleEdit(post) {
        var discussion = post.get('discussion');

        post.rollbackAttributes();
        post.toggleProperty('isEditing');

        discussion.toggleProperty('isEditing');
      },


      /**
       * Function to Submit Discussion
       * @param {*} post 
       */
      submit: function submit(post) {
        if (post.get('isNew')) {
          var that = this;
          var discussion = that.currentModel.discussion;

          post.set('discussion', discussion);
          post.set('createdBy', this.get('session.data.currentUser'));

          // Temporarily yank out all images. We need to save just the post first
          var images = post.get('images').map(function (image) {
            return image;
          }); // Clone hack
          post.get('images').clear();

          return post.save().then(function () {
            return post.reload();
          }).then(function () {
            images.forEach(function (image) {
              // Now that we have the post ID via the reload call we can use it
              image.set('discussionPost', post);

              // Put the image back into the post.
              post.get('images').pushObject(image);
            });

            return _npmBluebird.default.all(post.get('images').map(function (image, index) {
              image.set('index', index);
              return image.save().then(function () {
                return image.reload();
              }).then(function () {
                return image.set('fileData', undefined);
              });
            }));
          }).then(function () {
            return post.reload();
          }).then(function () {
            Ember.set(that.currentModel, 'newPost', null);
          });
        }

        if (post.get('isEditing') === true) {
          var _discussion = post.get('discussion');

          return post.save().then(function () {
            // Handle deleted images, if there are any.

            if (post.get('deletedImages')) {
              return Ember.RSVP.all(post.get('deletedImages').map(function (del) {
                return del.save();
              }));
            }

            return Ember.RSVP.resolve();
          }).then(function () {
            // Handle new/existing images.

            return _npmBluebird.default.all(post.get('images').map(function (image, index) {
              image.set('index', index);

              return image.save().then(function () {
                return image.reload();
              }).then(function () {
                return image.set('fileData', undefined);
              });
            }));
          }).then(function () {
            post.set('isEditing', false);
            _discussion.set('isEditing', false);
          }).catch(function (err) {
            Ember.Logger.info(err);
            post.set('isEditing', true);
            _discussion.set('isEditing', true);
          });
        }
      },


      /**
       * Function add Image by params
       * @param {*} post 
       * @param {*} file 
       */
      addImage: function addImage(post, file) {
        var that = this;

        return new _npmBluebird.default(function (resolve, reject) {
          var reader = new FileReader();
          reader.onload = function (e) {
            return resolve(e.target.result);
          };
          reader.onerror = function (err) {
            return reject(err);
          };
          reader.onabort = function () {
            return reject(new Error('File read aborted'));
          };

          reader.readAsDataURL(file);
        }).then(function (data) {
          var image = that.store.createRecord('discussion-post-image');

          image.set('discussionPost', post);
          image.set('title', file.name);
          image.set('fileName', file.name);
          image.set('mimeType', file.type);
          image.set('fileData', data);

          post.get('images').pushObject(image);
        });
      },


      /**
       * Function Delete Image by params
       * @param {*} post 
       * @param {*} image 
       */
      deleteImage: function deleteImage(post, image) {
        post.get('images').removeObject(image);

        if (!post.get('deletedImages')) {
          post.set('deletedImages', []);
        }

        post.get('deletedImages').pushObject(image);

        image.deleteRecord();
      },


      /**
       * Function Delete Post for param
       * @param {*} post 
       */
      deletePost: function deletePost(post) {
        var _this = this;

        var flash = this.get('flashMessages');
        var author = post.get('author').get('displayName');
        return post.destroyRecord().then(function () {
          return flash.success('Post by ' + author + ' was deleted!');
        }).then(function () {
          return _this.transitionTo('discussions');
        });
      },


      /**
       * Function Delete Post Reply for param
       * @param {*} post 
       */
      deletePostReply: function deletePostReply(post) {
        var flash = this.get('flashMessages');
        var author = post.get('author').get('displayName');
        var discussion = this.currentModel.discussion;

        return post.destroyRecord().then(function () {
          post.set('isDeactivated', true);

          return flash.success('Reply by ' + author + ' was deleted!');
        }).then(function () {
          discussion.get('otherPosts').forEach(function (post) {
            if (post.get('isDeactivated')) {
              discussion.get('otherPosts').removeObject(post);
            }
          });
        });
      },
      willTransition: function willTransition() {
        // Crush the new post record
        var newPost = this.currentModel.newPost;

        if (newPost.get('isNew')) {
          newPost.destroy();
        }

        return true;
      },

      goToCourse: function goToCourse(course) {
        this.transitionTo('courses.course', course.get('id'));
      },
      regionRedirect: function regionRedirect(regionId) {
        this.transitionTo('courses', { queryParams: { RegionId: regionId } });
      }
    }
  });
});