define('golf-digest-panelists/components/ngfcourses-report/ngfcourse-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-item', 'table-row'],
    tagName: 'tr',
    store: Ember.inject.service(),
    showModal: false,
    flashMessages: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var that = this;
      var ngfCourse = this.get('ngfCourse');

      /**
       * Function get Store Course by ID
       */
      this.get('store').query('course', { ngfCourseId: ngfCourse.get('id'), ngfFacilityId: ngfCourse.get('ngfFacilityId') }).then(function (result) {
        result.forEach(function (crs) {
          that.set('course', crs);
        });
      });
    },


    actions: {
      addCourse: function addCourse(course) {
        this.sendAction('addCourse', course);
      },
      ignoreCourse: function ignoreCourse(course) {
        this.sendAction('ignoreCourse', course);
      },
      search: function search(query) {
        return this.get('store').query('course', {
          query: query
        });
      },
      changeValue: function changeValue(course) {
        this.set('internalValue', course);
      },
      toggleAverager: function toggleAverager() {
        this.toggleProperty('showModal');
      },
      link: function link(ngfCourse) {
        var that = this;
        var flash = this.get('flashMessages');

        if (this.get('internalValue')) {
          that.sendAction('link', this.get('internalValue'), ngfCourse);
          that.toggleProperty('showModal');
        } else {
          flash.danger('Please select a Course');
        }
      },
      unlink: function unlink(ngfCourse) {
        this.sendAction('unlink', ngfCourse);
      }
    }
  });
});