define('golf-digest-panelists/faqs/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('faq');
    },


    actions: {
      abort: function abort() {
        this.modelFor('faqs.new').destroyRecord();
        this.transitionTo('home');
      },
      submit: function submit() {
        var that = this;
        var faq = this.modelFor('faqs.new');

        return faq.save().then(function () {
          return faq.reload();
        }).then(function () {
          return that.transitionTo('faqs.faq', faq);
        });
      },

      // Redirect Course per Region By Id
      regionRedirect: function regionRedirect(regionId) {
        this.transitionTo('courses', { queryParams: { RegionId: regionId } });
      },
      // Redirect Course per Country By Id
      countryRedirect: function countryRedirect(countryId) {
        this.transitionTo('courses', { queryParams: { CountryId: countryId } });
      }
    }
  });
});