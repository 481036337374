define('golf-digest-panelists/services/scroller', ['exports', 'ember-scroll-to/services/scroller'], function (exports, _scroller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scroller.default.extend({
    scrollable: Ember.computed(function () {
      return Ember.$('html, body, #page-container');
    })
  });
});