define('golf-digest-panelists/helpers/ptp-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ptpInput = ptpInput;
  function ptpInput(param /*, hash*/) {
    var obj = void 0;
    param[1].forEach(function (s) {
      if (param[0] === s.get('ngfCriterion').get('name') && s.get('ngfCriterion').get('description')) {
        //if(param[0] === s.get('ngfCriterion.name'){
        obj = {
          c: param[0],
          cd: param[0],
          r: s
        };
      }
    });
    if (obj) {
      return obj;
    }
    return {
      c: param[0],
      cd: param[0],
      r: null
    };
  }

  exports.default = Ember.Helper.helper(ptpInput);
});