define('golf-digest-panelists/components/users-report/user-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-item', 'table-row'],
    tagName: 'tr',
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    actions: {
      /**
       * Function User Enable for param
       * @param {*} user 
       */
      userEnable: function userEnable(user) {
        var flash = this.get('flashMessages');

        return this.get('store').find('user', user.id).then(function (updateUser) {
          updateUser.set('isLocked', false);
          updateUser.set('isDeactivated', false);
          // updateUser.set('isDisabled', false);
          updateUser.set('isSecurityAnswersLocked', false);
          return updateUser.save();
        }).then(function () {
          return flash.success('User enabled');
        });
      },


      /**
       * Function User Deactivate for param
       * @param {*} user 
       */
      userDeactivate: function userDeactivate(user) {
        var flash = this.get('flashMessages');

        // Function Get Store User and find and return By ID
        return this.get('store').find('user', user.id).then(function (updateUser) {
          updateUser.set('isDeactivated', true);
          return updateUser.save();
        }).then(function () {
          return flash.success('User deactivated');
        });
      },


      /**
       * Function User Unlock for param
       * @param {*} user 
       */
      userUnlock: function userUnlock(user) {
        var flash = this.get('flashMessages');

        // Function Get Store User and find and return By ID
        return this.get('store').find('user', user.id).then(function (updateUser) {
          updateUser.set('isLocked', false);
          updateUser.set('isSecurityAnswersLocked', false);
          return updateUser.save();
        }).then(function () {
          return flash.success('User unlocked');
        });
      },


      /**
       * Function User Disable for param
       * @param {*} user 
       */
      userDisable: function userDisable(user) {
        var flash = this.get('flashMessages');

        return this.get('store').find('user', user.id).then(function (updateUser) {
          updateUser.set('isDisabled', true);
          return updateUser.save();
        }).then(function () {
          return flash.success('User disabled');
        });
      }
    }
  });
});