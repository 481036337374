define('golf-digest-panelists/components/ember-inline-edit/component', ['exports', 'ember-inline-edit/components/ember-inline-edit', 'npm:validator'], function (exports, _emberInlineEdit, _npmValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberInlineEdit.default.extend({
    showUrlErrorTooltip: false,

    actions: {
      save: function save(e) {
        if (this.get('field') === 'url' && this.get('value') !== null && this.get('value').length !== 0 && !_npmValidator.default.isURL(this.get('value'))) {

          this.set('showUrlErrorTooltip', true);

          return false;
        }

        this.set('showUrlErrorTooltip', false);
        this._super(e);
      },
      close: function close(e) {
        this._super(e);
      },
      hide: function hide(e) {
        this._super(e);
      },
      onClose: function onClose(e) {
        // Allow whatever is using this component to know when editing has ended
        // to, for example, restore reset details.
        this.sendAction('onClose');
        this._super(e);
      },
      startEditing: function startEditing(e) {
        // Allow whatever is using this component to know when editing has begun
        // to, for example, store reset details.
        this.sendAction('startEditing');
        this._super(e);
      }
    }
  });
});