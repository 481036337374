define('golf-digest-panelists/components/ignored-courses-report/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['table', 'user-details', 'table-striped', 'table-condensed'],

    actions: {
      addCourse: function addCourse(course) {
        this.sendAction('addCourse', course);
      },
      unIgnoreCourse: function unIgnoreCourse(course) {
        this.sendAction('unIgnoreCourse', course);
      }
    }
  });
});