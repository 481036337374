define('golf-digest-panelists/config/ember-spinner/lazy-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    color: '#2b2b2b',
    top: '12px',
    left: '12px',
    lines: 15,
    length: 2,
    width: 3,
    radius: 7,
    corners: 0,
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 28,
    fps: 20,
    shadow: false,
    hwaccel: true
  };
});