define('golf-digest-panelists/discussions/index/controller', ['exports', 'ember-cli-pagination/remote/route-mixin'], function (exports, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeMixin.default, {
    queryParams: ['page', 'perPage'],

    totalPages: Ember.computed.alias('content.totalPages'),

    page: 1,
    perPage: 25,

    actions: {
      pageClicked: function pageClicked(page) {
        this.setProperties({ page: page });
      },
      searchDiscussion: function searchDiscussion(value) {
        this.transitionToRoute('discussions', { queryParams: { query: value, page: 1 } });
      },
      clear: function clear() {
        this.set('discussion_query', null);
      }
    }
  });
});