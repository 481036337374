define('golf-digest-panelists/components/list-contact/list-item/edit-mode/select-kinds/component', ['exports', 'npm:bluebird'], function (exports, _npmBluebird) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    kinds: [],

    internalValue: Ember.computed('values', function () {
      if (this.get('values') && this.get('kinds')) {
        var kinds = this.get('kinds');

        return this.get('values').map(function (v) {
          return kinds.find(function (i) {
            return i.get('id') === v.get('id');
          });
        });
      }
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // Find All Contact Kind
      return this.get('store').findAll('contact-kind').then(function (kinds) {
        return _this.set('kinds', kinds);
      }).then(function () {
        return _this.notifyPropertyChange('values');
      });
    },


    actions: {
      changeValue: function changeValue(kinds) {
        var _this2 = this;

        if (kinds) {
          var store = this.get('store');
          var ids = kinds.map(function (k) {
            return k.get('id');
          });

          return _npmBluebird.default.map(ids, function (id) {
            return store.findRecord('contact-kind', id);
          }).then(function (values) {
            _this2.get('onChange')(values);
          });
        }

        this.get('onChange')(null);
      }
    }
  });
});